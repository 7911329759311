import { useState } from "react";
import { BsDownload } from "react-icons/bs";
import { MdOutlineContentCopy } from "react-icons/md";
import copyImageToClipboard from "../../../utils/copyImage";

const GalleryModalImage = ({ image, selectImage }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleDownloadImage = async () => {
    if (!selectImage) return;

    try {
      const response = await fetch(selectImage.url);
      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = "generated-image.png";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  return (
    <div className="flex flex-[2]">
      <div className="flex relative flex-1 justify-center rounded-2xl">
        <img
          src={image.url}
          alt={"Generated by Banaina"}
          className={` rounded-2xl  drop-shadow-lg transition-all duration-300 ${
            isImageLoaded ? "opacity-100" : "opacity-0"
          } `}
          onLoad={() => setIsImageLoaded(true)}
        />
        <div className="absolute bottom-4 w-full  flex space-x-4 mt-4 justify-center">
          <button
            onClick={() => copyImageToClipboard(image.url)}
            className="flex justify-center items-center rounded text-gray-300 font-semibold cursor-pointer shadow-xl hover:bg-[rgba(0,0,0,0.9)]  transition-colors w-12 h-12 bg-[rgba(0,0,0,0.6)] border-2 border-white"
          >
            <MdOutlineContentCopy className="h-6 w-6" />
          </button>
          <button
            size="icon"
            onClick={handleDownloadImage}
            className="flex justify-center items-center rounded text-gray-300 font-semibold cursor-pointer shadow-xl hover:bg-[rgba(0,0,0,0.9)]  transition-colors w-12 h-12 bg-[rgba(0,0,0,0.6)] border-2 border-white"
          >
            <BsDownload className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GalleryModalImage;
