const ButtonOptions = ({ Icon, isSelected, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={
        "p-3 rounded-full border-[1px] text-primary " +
        (isSelected
          ? "bg-secondary "
          : "bg-transparent opacity-70 hover:opacity-100 border-dashed border-primary")
      }
    >
      <Icon size={24} />
    </button>
  );
};

export default ButtonOptions;
