import Modal from "react-modal";
import {
  FaExclamationTriangle,
  FaTimesCircle,
  FaCheckCircle,
} from "react-icons/fa";
import { Button } from "@radix-ui/themes/dist/cjs/index.js";
import { t } from "i18next";

const ReusableModal = ({
  isOpen,
  hideButtons,
  title,
  message,
  onConfirm,
  onCancel,
  statusImg,
  children,
  error,
  isLoading = false,
}) => {
  const getIcon = () => {
    switch (statusImg) {
      case "warning":
        return (
          <FaExclamationTriangle
            style={{ color: "orange", fontSize: "50px" }}
          />
        );
      case "error":
        return <FaTimesCircle style={{ color: "red", fontSize: "50px" }} />;
      case "success":
        return <FaCheckCircle style={{ color: "green", fontSize: "50px" }} />;
      default:
        return null;
    }
  };

  const icon = getIcon();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className="flex items-center justify-center min-h-screen "
      overlayClassName="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg  text-center w-96">
        {icon && (
          <div className="text-3xl mb-4 flex justify-center">{icon}</div>
        )}
        {title && <h2 className="text-lg font-bold mb-4">{title}</h2>}
        {message && <p className="mb-4 text-sm">{message}</p>}
        {children}
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        {!hideButtons && (
          <div className="flex justify-around gap-5">
            <button
              disabled={isLoading}
              onClick={onConfirm}
              className={` py-2 px-4 rounded flex items-center font-semibold ${
                isLoading
                  ? "opacity-70 bg-gray-300 text-gray-500"
                  : "bg-secondary text-primary"
              }`}
            >
              {t("Confirmar")}
            </button>
            <button
              disabled={isLoading}
              onClick={onCancel}
              className={` py-2 px-4 rounded flex items-center font-semibold bg-red-500 text-white `}
            >
              {t("Cancelar")}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ReusableModal;
