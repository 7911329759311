import { DropdownMenu } from "@radix-ui/themes";
import authService from "../../services/authService";

const Dropdown = ({
  triggerIcon,
  triggerLabel,
  menuItems,
  variant = "soft",
  color = "indigo",
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        {triggerLabel !== "User" ? (
          <button className="flex gap-1 items-center text-gray-500 text-base">
            {triggerIcon}
            <span>{triggerLabel}</span>
          </button>
        ) : (
          <button className="bg-secondary w-8 h-8 text-primary rounded-full uppercase font-semibold">
            {authService.getUserInfo().username.split("")[0]}
          </button>
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="text-gray-500"
        variant={variant}
        color={color}
      >
        {menuItems.map((item, index) => (
          <DropdownMenu.Item
            key={index}
            onClick={item.onClick}
            className="cursor-pointer"
          >
            {item.label}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default Dropdown;
