import { useEffect } from "react";
import SearchInput from "../../components/dashboard/chatPage/SearchInput.js";
import ChatCard from "../../components/dashboard/chatPage/ChatCard.js";
import ChatFooter from "../../components/dashboard/chatPage/ChatFooter.js";
import ChatArea from "../../components/dashboard/chatPage/ChatArea.js";
import useChatStore from "../../store/messageStore.js";
import useTemplateStore from "../../store/templateStore";
import { t } from "i18next";
import Title from "./Title.js";
import "highlight.js/styles/base16/colors.css";
import { Skeleton } from "@radix-ui/themes";

function ChatsPage({ chatType = "normal" }) {
  const {
    fetchChats,
    setChatId,
    filteredChats,
    setMessages,
    setSelectedChatId,
    isGettingHistory,
    setIsGettingHistory,
  } = useChatStore();
  const { setSelectedAgent } = useTemplateStore();

  const gettingHistory = async () => {
    setIsGettingHistory(true);
    await fetchChats();
    setIsGettingHistory(false);
  };
  useEffect(() => {
    if (filteredChats.length === 0) {
      gettingHistory();
    }
  }, []);

  const handleButtonClick = () => {
    setSelectedAgent(undefined);
    setChatId(null);
    setMessages([]);
    setSelectedChatId(null);
  };

  /* const handleSelectAgent = (agent) => {
    setSelectedAgent(agent);
  };*/

  return (
    <div className="flex flex-col h-full">
      <Title title={t("Asistente IA")} />

      <div className="flex-1 relative flex h-full max-h-full overflow-hidden rounded-2xl shadow-md">
        {/* chatgpt nav izquierda */}
        <div className="flex flex-col shrink-0 bg-white shadow-md rounded-tl-2xl rounded-bl-2xl p-6 gap-4 max-h-full w-[329px] overflow-auto">
          <SearchInput />
          <button
            onClick={handleButtonClick}
            className={`  py-2 px-4 rounded flex items-center font-semibold bg-secondary text-primary justify-center`}
          >
            {t("Nueva conversación")}
          </button>
          <div className="max-h-full overflow-auto flex flex-col gap-2">
            {isGettingHistory ? (
              <div>
                {/* Skeleton loader que simula la lista de chats */}
                {[...Array(5)].map((_, index) => (
                  <Skeleton
                    key={index}
                    className="w-full h-10 mb-2 rounded-md bg-gray-300"
                  />
                ))}
              </div>
            ) : (
              <ChatCard />
            )}
          </div>
        </div>
        {/* chatgpt contededor principal derecha */}
        <div className="flex-grow flex flex-col  bg-white shadow-md rounded-tr-2xl rounded-br-2xl min-w-0">
          <div className="flex-1 overflow-auto">
            <ChatArea />
          </div>

          <div
            className="p-3"
            style={{ borderTop: "1px solid var(--Stroke, #DEDEDE)" }}
          >
            <ChatFooter />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatsPage;
