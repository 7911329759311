import { BsSoundwave } from "react-icons/bs";
import { CiMusicNote1, CiText, CiTextAlignCenter } from "react-icons/ci";
import { SiGooglepodcasts } from "react-icons/si";
import {
  FaCode,
  FaLaptopCode,
  FaMicrophoneAlt,
  FaRegImage,
  FaSearchLocation,
} from "react-icons/fa";
import { WiStars } from "react-icons/wi";
import { AiOutlineFileSearch, AiOutlinePieChart } from "react-icons/ai";
import { RiSeoLine, RiSpeakLine } from "react-icons/ri";
import {
  IoBarChartOutline,
  IoChatbubbleEllipsesOutline,
  IoChatbubblesOutline,
  IoHomeSharp,
} from "react-icons/io5";
import {
  MdOutlinePlagiarism,
  MdOutlineShortText,
  MdOutlineSubtitles,
  MdOutlineTranslate,
  MdQueueMusic,
} from "react-icons/md";
import { TfiVideoClapper } from "react-icons/tfi";

const menuConfig = [
  {
    title: "Inicio",
    icon: IoHomeSharp,
    route: "/dashboard",
  },
  {
    title: "Analítica",
    icon: IoBarChartOutline,
    submenu: [
      {
        title: "General",
        route: "analitic",
        icon: AiOutlinePieChart,
        description:
          "Proporciona una visión general de las métricas clave y tendencias a través de gráficos interactivos y reportes detallados para facilitar el análisis del rendimiento y la toma de decisiones estratégicas",
      },
    ],
  },
  {
    title: "Audio",
    icon: BsSoundwave,
    submenu: [
      {
        title: "Audio a texto",
        route: "transcribe-text",
        icon: FaMicrophoneAlt,
        description:
          "Transcribe audios a texto automáticamente, facilitando la accesibilidad y el análisis de contenido hablado",
      },
    ],
  },
  {
    title: "Chats",
    icon: IoChatbubbleEllipsesOutline,
    submenu: [
      {
        title: "Asistente",
        route: "assistant-chat",
        icon: IoChatbubblesOutline,
        description:
          "Asistente de IA para ayudarte en cualquier cosa que necesites",
      },
    ],
  },
  {
    title: "Código",
    icon: FaLaptopCode,
    submenu: [
      {
        title: "Generador de código",
        route: "generator-code",
        icon: FaCode,
        description:
          "Genera código automáticamente en varios lenguajes de programación, acelerando el desarrollo y optimizando tareas repetitivas",
      },
    ],
  },
  {
    title: "Música",
    icon: CiMusicNote1,
    submenu: [
      {
        title: "Generador de música",
        route: "generator-music",
        icon: MdQueueMusic,
        description:
          "Crea pistas musicales originales a partir de entradas personalizadas, perfectas para proyectos creativos y multimedia",
      },
    ],
  },
  {
    title: "Imagen",
    icon: FaRegImage,
    submenu: [
      {
        title: "Texto a imagen",
        route: "generate-image",
        icon: CiTextAlignCenter,
        description: "Genera imágenes solo con mediante el uso de texto",
      },
    ],
  },
  {
    title: "Podcasts",

    icon: RiSpeakLine,
    submenu: [
      {
        title: "Podcasts",
        route: "podcasts",
        icon: SiGooglepodcasts,
        description:
          "Convierte ideas en episodios de podcasts, generando contenido de audio atractivo y de alta calidad",
      },
    ],
  },
  {
    title: "SEO",
    icon: RiSeoLine,
    submenu: [
      {
        title: "SEO",
        route: "seo",
        icon: FaSearchLocation,
        description:
          "Optimiza el contenido para motores de búsqueda, mejorando la visibilidad y el posicionamiento en línea",
      },
    ],
  },

  {
    title: "Texto",
    icon: CiText,
    submenu: [
      {
        title: "Corrector",
        route: "proofreader-text",
        icon: AiOutlineFileSearch,
        description:
          "Mejora la gramática y estilo de tus textos con un corrector ortográfico impulsado por IA",
      },
      {
        title: "Detector de IA",
        route: "iadetector-text",
        icon: WiStars,
        description:
          "Identifica textos generados por inteligencia artificial para garantizar la autenticidad del contenido",
      },
      {
        title: "Parafraseador",
        route: "paraphraser-text",
        icon: IoChatbubbleEllipsesOutline,
        description:
          "Reformula textos manteniendo el significado original, ideal para evitar plagio y mejorar redacción",
      },
      {
        title: "Plagio",
        route: "plagiarism-text",
        icon: MdOutlinePlagiarism,
        description:
          "Verifica la originalidad de tus textos detectando posibles casos de plagio en múltiples fuentes",
      },
      {
        title: "Resumidor",
        route: "summarizer-text",
        icon: MdOutlineShortText,
        description:
          "Condensa textos largos en resúmenes claros y concisos sin perder la información clave",
      },
      {
        title: "Traductor",
        route: "translate-text",
        icon: MdOutlineTranslate,
        description:
          "Traduce textos entre múltiples idiomas de manera precisa utilizando inteligencia artificial avanzada",
      },
    ],
  },
  {
    title: "Video",
    icon: TfiVideoClapper,
    submenu: [
      {
        title: "Subtítulos",
        route: "subtitle-video",
        icon: MdOutlineSubtitles,
        description:
          "Extrae texto de videos automáticamente, ideal para subtitulado y análisis de contenido audiovisual",
      },
    ],
  },
];

export default menuConfig;
