import hljs from "highlight.js";
import { t } from "i18next";
import copyClipboard from "./copyClipboard";

// Función para resaltar y agregar el botón de copiado
const highlightAndAddCopyButton = (nodes, language = null) => {
  nodes.forEach((node) => {
    if (!node.parentNode.querySelector(".code-header")) {
      hljs.highlightElement(node);

      // Obtener el lenguaje del código
      const classList = node.className.split(" ");
      const langClass = classList.find((cls) => cls.startsWith("language-"));
      const codeLang = langClass
        ? langClass.replace("language-", "")
        : t("Desconocido");

      // Crear el contenedor de la barra de código
      const codeHeader = document.createElement("div");
      codeHeader.className =
        "code-header flex justify-between items-center bg-[#2f2f2f] text-white px-4 py-2 border-t border-gray-400 rounded-t-lg";

      // Crear el título que muestra el lenguaje del código
      const codeLangTitle = document.createElement("span");
      codeLangTitle.className = "text-sm font-bold text-gray-300";
      codeLangTitle.innerText = language ? language : codeLang; // Mostrar el lenguaje detectado o "Desconocido"
      codeHeader.appendChild(codeLangTitle);

      // Crear el botón de copiado
      const copyButton = document.createElement("button");
      copyButton.className =
        "text-xs bg-gray-600 hover:bg-gray-500 px-3 py-1 rounded flex items-center gap-2";

      // Función de copiado al hacer clic
      copyButton.onclick = () => copyClipboard(node.innerText);

      // Añadir el ícono SVG de copiado
      const icon = document.createElement("span");
      icon.className = "text-white";
      icon.innerHTML = `<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002-2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"></path></svg>`;
      copyButton.appendChild(icon);

      // Añadir texto al botón
      const copyText = document.createElement("span");
      copyText.innerText = t("Copiar");
      copyButton.appendChild(copyText);

      // Agregar el botón de copiado al header
      codeHeader.appendChild(copyButton);

      // Insertar el header antes del bloque de código
      node.parentNode.prepend(codeHeader);
    }
  });
};

export default highlightAndAddCopyButton;
