import { toast } from "react-toastify";
import authService from "./authService";
import { t } from "i18next";
import BASE_URL from "../config/api";

class ImageService {
  constructor() {
    this.apiurl = BASE_URL;
  }

  async generateImage(text, config) {
    const apiUrl = this.apiurl + "/v1/novastudio/image-model-inference";
    const apiKey = authService.getToken();

    const requestBody = {
      prompt: `${text} y por ultimo, el tema de la imagen será: ${config.mode}`,
      model: "dall-e-3",
      n: 1,
      size: config.ratio,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 401) {
        toast.error(t("El token ha expirado"));
        authService.handleUnauthorized();
        return null;
      }
      if (!response.ok) {
        toast.error(t("Modifica el prompt"));
      }

      const data = await response.json();

      return data.data || t("¡Opps! Algo ha salido mal");
    } catch (error) {
      toast.error(t("¡Opps! Algo ha salido mal"));
      return t("¡Opps! Algo ha salido mal");
    }
  }
}

const imageService = new ImageService();

export default imageService;
