import { useForm } from "react-hook-form";
import { createChat } from "../../../services/chatsServices";
import useChatStore from "../../../store/messageStore";
import useTemplateStore from "../../../store/templateStore";
import { useRef } from "react";
import { t } from "i18next";

const ChatFooter = () => {
  const { register, handleSubmit, reset } = useForm();
  const {
    chatType,
    isSending,
    setIsSending,
    setNewMessage,
    chatId,
    setChatId,
    setMessages,
    fetchChats,
  } = useChatStore();
  const { selectedAgent } = useTemplateStore();
  const formRef = useRef(null);

  const onSubmit = (data) => {
    if (data.message === "") return;
    const userChat = { role: "user", content: data.message };
    let botChat = { role: "bot", content: "" };

    setMessages((prevMessages) => [...prevMessages, userChat]);
    setIsSending(true);
    const postChat = async () => {
      try {
        const response = await createChat(
          chatId,
          data.message,
          selectedAgent,
          "normal",
        );

        botChat = { role: "bot", content: response.answer };

        if (response.new) {
          setNewMessage(true);
          setChatId(response.chat_id);
        }
        setMessages((prevMessages) => [...prevMessages, botChat]);
        fetchChats();
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsSending(false);
      }
    };

    postChat();

    const form = formRef.current;
    const textarea = form.querySelector("textarea");
    if (textarea) {
      textarea.style.height = "68px";
    }
    reset();
  };

  return (
    <div className="chat-footer">
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full gap-2"
      >
        <textarea
          className="flex-1 border-none resize-none focus:ring-0 p-2 text-base text-gray-700 w-full outline-none max-h-48 overflow-auto"
          placeholder={t("Escribe tu mensaje aquí...")}
          rows={1}
          disabled={isSending}
          {...register("message")}
          onInput={(e) => {
            e.currentTarget.style.height = "auto";
            e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSubmit(onSubmit)();
              e.currentTarget.style.height = "68px";
            }
          }}
        />
        <div className="flex items-center">
          <button
            disabled={isSending}
            type="submit"
            className={` shrink-0 py-2 px-4 rounded flex items-center font-semibold bg-secondary text-primary`}
          >
            {t("Enviar")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatFooter;
