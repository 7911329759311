import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlineArrowRightAlt,
} from "react-icons/md";
import { IoCheckmarkDoneSharp, IoCheckmarkOutline } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
import ActionProofreader from "./ActionProofreader";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import countWords from "../../../utils/countWord";
import stripHtmlTags from "../../../utils/stripHtmlTags";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { Tooltip } from "@radix-ui/themes";

const TextAreaProofreader = ({
  handleAcceptAllChange,
  handleIgnoreAll,
  handleTextChange,
  handleProofreader,
  handleAcceptChange,
  handleRemoveCandidate,
  handlePaste,
  fromText,
  content,
  maxCount,
  isProofreading,
  proofread,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [noContent, setNoContent] = useState(true);

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handlePrevIndex = () => {
    setExpandedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };
  const handleNextIndex = () => {
    setExpandedIndex((prevIndex) => {
      const lastIndex = proofread?.result?.revised_words?.length - 1;
      return prevIndex < lastIndex ? prevIndex + 1 : prevIndex;
    });
  };

  const handleAcceptCurrentChange = () => {
    if (
      expandedIndex !== null &&
      proofread?.result?.revised_words?.[expandedIndex]
    ) {
      const revisedWord = proofread.result.revised_words[expandedIndex];
      const changed = handleAcceptChange(revisedWord.word, revisedWord.revised);
      if (changed) {
        handleRemoveCandidate(expandedIndex);
        setExpandedIndex(null);
      }
    }
  };

  useEffect(() => {
    if (proofread) {
      setNoContent(false);
    }
  }, [proofread]);
  return (
    <div className="relative flex flex-1 h-full w-full">
      <div className="flex flex-col flex-[2] shrink-0">
        <ContentEditable
          className="w-full max-w-full !break-words h-full outline-none p-6 text-gray-500 overflow-y-auto"
          style={{ wordBreak: "break-word" }}
          onChange={handleTextChange}
          onPaste={handlePaste}
          html={DOMPurify.sanitize(marked.parse(fromText))}
        />

        <div className="flex items-center justify-end gap-2 p-6">
          <ActionProofreader
            textToCopy={fromText}
            count={countWords(content)}
            maxCount={maxCount}
            handleProofreader={handleProofreader}
            isLoading={isProofreading}
            content={content}
          />
        </div>
      </div>

      <div className="flex flex-col flex-1 border-l-[1px] shrink-0">
        <div className="flex-1 overflow-y-auto relative p-2">
          {noContent && (
            <div className="flex flex-col text-sm text-center h-full items-center justify-center pt-2">
              <span>
                <strong>{t("No se detecta ningún texto")}</strong>
              </span>
              <p>
                {t(
                  'Ingrese su texto y haga clic en "Corrector" para ver la revisión',
                )}
              </p>
              <img
                className="max-h-60 object-contain"
                src="/assets/nocontent.png"
                alt="User not content"
              />
            </div>
          )}
          {proofread &&
            proofread?.result &&
            proofread?.result?.revised_words?.length > 0 &&
            proofread.result.revised_words.map((revised_word, index) => (
              <div
                key={revised_word.word + index}
                onClick={() => handleExpand(index)}
                className="group px-2 py-4 border-[1px] shadow-md rounded items-center  text-gray-500 text-sm cursor-pointer transition-transform "
              >
                <div className="group-hover:scale-[1.02] transition-transform flex gap-2  items-center justify-center">
                  <span className="truncate w-24 line-through text-red-500">
                    {revised_word.word}
                  </span>
                  <MdOutlineArrowRightAlt />
                  <span className="truncate w-24 text-green-500">
                    {stripHtmlTags(revised_word.revised)}
                  </span>
                  <span className="text-nowrap text-xs">
                    {t(revised_word.err_type_conv)}
                  </span>
                </div>

                {expandedIndex === index && (
                  <div className="mt-2 p-2 ">
                    <p className="my-2">{t(revised_word.reasoning)}</p>
                    <hr />
                    <div className="mt-2 flex justify-end gap-2">
                      <button
                        onClick={() => {
                          const changed = handleAcceptChange(
                            revised_word.word,
                            revised_word.revised,
                          );
                          handleExpand(null);
                          if (changed) {
                            handleRemoveCandidate(index);
                          }
                        }}
                        className="flex items-center gap-1 bg-transparent text-green-500 border-[1px] border-green-500 p-2 rounded font-semibold"
                      >
                        <IoCheckmarkOutline />
                        {t("Aceptar")}
                      </button>
                      <button
                        onClick={() => {
                          handleRemoveCandidate(index);
                        }}
                        className="flex items-center gap-1 bg-transparent text-red-500 border-[1px] border-red-500 p-2 rounded font-semibold"
                      >
                        <FaRegTrashAlt />
                        {t("Ingorar")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          {proofread?.result?.revised_words?.length === 0 && !noContent && (
            <div className="flex flex-col text-sm text-center h-full items-center pt-2 justify-center">
              <span>
                <strong>
                  {t("Tu texto es claro y está libre de errores")}
                </strong>
              </span>

              <img
                className="max-h-full object-contain overflow-hidden"
                src="/assets/cleartext.png"
                alt="User not error detected"
              />
            </div>
          )}
        </div>
        {proofread?.result?.revised_words?.length > 0 && (
          <div className="flex items-center justify-end gap-2 p-6">
            <div className="flex w-full items-center justify-center gap-4 px-6">
              <Tooltip content={t("Anterior cambio")}>
                <button>
                  <MdChevronLeft
                    onClick={handlePrevIndex}
                    className="cursor-pointer"
                  />
                </button>
              </Tooltip>

              <Tooltip content={t("Aceptar cambio actual")}>
                <button>
                  <IoCheckmarkOutline
                    onClick={handleAcceptCurrentChange}
                    className="cursor-pointer"
                  />
                </button>
              </Tooltip>

              <Tooltip content={t("Aceptar todos los cambios")}>
                <button>
                  <IoCheckmarkDoneSharp
                    onClick={handleAcceptAllChange}
                    className="cursor-pointer text-green-500"
                  />
                </button>
              </Tooltip>

              <Tooltip content={t("Ignorar todos los cambios")}>
                <button>
                  <FaRegTrashAlt
                    onClick={handleIgnoreAll}
                    className="cursor-pointer text-red-500"
                  />
                </button>
              </Tooltip>

              <Tooltip content={t("Siguiente cambio")}>
                <button>
                  <MdChevronRight
                    onClick={handleNextIndex}
                    className="cursor-pointer"
                  />
                </button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextAreaProofreader;
