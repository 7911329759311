import { t } from "i18next";
import { toast } from "react-toastify";

const copyImageToClipboard = async (imageUrl) => {
  if (!imageUrl) return;

  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const clipboardItem = new ClipboardItem({ "image/png": blob });

    await navigator.clipboard.write([clipboardItem]);

    toast.info(t("¡Imagen copiada al portapapeles!"));
  } catch (error) {
    console.error("Error copying image to clipboard:", error);
    toast.error(t("Error "));
  }
};

export default copyImageToClipboard;
