import { t } from "i18next";
import { FaRegCirclePause } from "react-icons/fa6";
import { MdFiberManualRecord } from "react-icons/md";

const RecordState = ({ isRecording, isPaused }) => {
  return (
    <div>
      {isRecording && !isPaused ? (
        <div className="flex items-center gap-1 ">
          <MdFiberManualRecord className="text-red-500 animate-ping" />
          {t("Grabando")}
        </div>
      ) : (
        <div className="flex items-center gap-1 ">
          <FaRegCirclePause />
          {t("Pausa")}
        </div>
      )}
    </div>
  );
};

export default RecordState;
