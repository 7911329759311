import { create } from "zustand";

const useMusicStore = create((set, get) => ({
  inputType: "generator",

  selectedOptions: ["genero"], // "genero" está seleccionado por defecto

  setInputType: (newValue) => set({ inputType: newValue }),

  // Función para agregar o quitar una opción seleccionada
  toggleOption: (option) =>
    set((state) => {
      // "genero" no puede ser deseleccionado
      console.log(option);
      if (option === "genero")
        return { selectedOptions: get().selectedOptions };

      const isSelected = get().selectedOptions.includes(option);
      if (isSelected) {
        return {
          selectedOptions: get().selectedOptions.filter(
            (opt) => opt !== option,
          ),
        };
      } else {
        return { selectedOptions: [...get().selectedOptions, option] };
      }
    }),
}));

export default useMusicStore;
