import hljs from "highlight.js";
import { useEffect } from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import authService from "../../../services/authService";
import highlightAndAddCopyButton from "../../../utils/highlightAndAddCopyButton";
import "../../../assets/css/tableStyle.css";

const Message = ({ message, index }) => {
  useEffect(() => {
    const nodes = document.querySelectorAll("pre code");
    highlightAndAddCopyButton(nodes);
  }, [message.content, message.role]);

  return (
    <div key={index} className={`grid grid-cols-12 gap-y-2 items-center `}>
      {message.role === "assistant" || message.role === "bot" ? (
        <div className="col-start-1 col-end-11">
          <div className="flex gap-4">
            <img
              src="/assets/assistant.png"
              alt="assistant macaque"
              className="col-start-1 shrink-0 self-baseline h-11 w-11 rounded-full"
            />
            <p
              className={`relative break-anywhere text-sm rounded-lg p-2 min-w-0 text-primary bg-gray-200 before:absolute before:top-2 before:left-0 before:-translate-x-full before:border-8 before:border-transparent before:border-r-gray-200 space-y-4`}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(marked.parse(message.content)),
              }}
            ></p>
          </div>
        </div>
      ) : (
        <div className="col-start-2 col-end-12">
          <div className="flex gap-4 flex-row-reverse">
            <button
              disabled
              className="col-start-12 col-end-11 bg-secondary w-11 h-11 text-primary rounded-full uppercase font-semibold shrink-0 self-baseline"
            >
              {authService.getUserInfo().username.split("")[0]}
            </button>
            <p
              className={`relative break-anywhere text-sm rounded-lg p-2 min-w-0 bg-secondary-hover text-primary after:content-[''] after:absolute after:top-2 after:right-0 after:translate-x-full after:border-8 after:border-transparent after:border-l-secondary-hover  `}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(marked.parse(message.content)),
              }}
            ></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
