// ActionButtons.jsx
import React from "react";
import { GoPencil } from "react-icons/go";
import { MdUpload } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

const ActionButtons = ({ onClose }) => {
  return (
    <div className="flex justify-between">
      <div className="flex gap-2">
        <GoPencil
          className="cursor-pointer hover:bg-secondary hover:font-semibold p-1 rounded-md"
          size={28}
        />
        <MdUpload
          className="cursor-pointer hover:bg-secondary hover:font-semibold p-1 rounded-md"
          size={28}
        />
      </div>
      <IoMdClose
        onClick={onClose}
        className="cursor-pointer hover:bg-secondary hover:font-semibold p-1 rounded-md"
        size={28}
      />
    </div>
  );
};

export default ActionButtons;
