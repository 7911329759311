import { t } from "i18next";
import { toast } from "react-toastify";

const copyClipboard = (value) => {
  if (!value) return;
  let txt = value;
  const plainText = txt
    .replace(/<\/p>/g, "\n") // Reemplaza el cierre de párrafos por saltos de línea
    .replace(/<br\s*\/?>/g, "\n") // Reemplaza las etiquetas <br> por saltos de línea
    .replace(/<[^>]+>/g, "");
  navigator.clipboard.writeText(plainText);
  toast.info(t("¡Copiado!"));
};

export default copyClipboard;
