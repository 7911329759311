import Title from "./Title";

const LayoutDashboard = ({ children, title }) => {
  return (
    <div className="flex flex-col h-full">
      <Title title={title} />
      <div className="flex flex-col w-full h-full mx-auto bg-white rounded-2xl shadow-md min-h-0 min-w-0">
        {children}
      </div>
    </div>
  );
};

export default LayoutDashboard;
