import { useState } from "react";
import TranslateService from "../../../services/translateService";
import modesParaphrasers from "../../../config/modesParaphraser";
import { t } from "i18next";
import SelectStandard from "../../ui/SelectStandard";

const HeaderParaphraser = ({
  handleChangeLanguage,
  currentLang,
  handleModeChange,
  currentMode,
}) => {
  const [translateService] = useState(new TranslateService());

  return (
    <div className="flex flex-wrap w-full items-center gap-4 p-4 bg-white rounded-tl-2xl rounded-tr-2xl border-b-[1px]">
      <div className="flex items-center ">
        <SelectStandard
          action={(val) => handleChangeLanguage(val, true)}
          options={translateService.getAvailableLanguages()}
          defaultValue={currentLang}
        />
      </div>
      <div className="text-gray-500">|</div>

      <div className="flex space-x-4 text-gray-500 text-sm">
        <span className="text-primary">{t("Modos")}:</span>
        {modesParaphrasers.map((mode) => (
          <span
            key={mode}
            onClick={() => handleModeChange(mode)}
            className={`cursor-pointer ${
              currentMode === mode ? "text-secondary font-semibold" : ""
            }`}
          >
            {t(mode)}
          </span>
        ))}
      </div>
    </div>
  );
};

export default HeaderParaphraser;
