import { t } from "i18next";
import ButtonPrimary from "../../ui/ButtonPrimary";
import OptionsAvailables from "./OptionsAvailables";
import OptionsSideContainer from "./OptionsSideContainer";

const GeneratorMusic = () => {
  return (
    <div className="grid grid-area-music h-full min-h-0">
      <OptionsSideContainer />
      <OptionsAvailables />
      <div className={"button flex w-full justify-center items-center px-5"}>
        <ButtonPrimary
          className={"w-full flex justify-center"}
          disabled={false}
          title={t("Generar")}
        />
      </div>
    </div>
  );
};

export default GeneratorMusic;
