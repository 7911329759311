import { t } from "i18next";
import React from "react";
import { FaArrowsSpin } from "react-icons/fa6";
import { FiEdit2 } from "react-icons/fi";
import ButtonPrimary from "../ui/ButtonPrimary";

const CharacterCounter = ({ count, maxCount, onTranslate, isLoading }) => {
  return (
    <div className="flex w-full items-center justify-between px-6">
      <span
        className={`text-sm flex items-center ${
          count > maxCount ? "text-red-500 font-semibold" : "text-gray-500"
        }`}
      >
        {count} / {maxCount} {t("Caracteres")}
        <FiEdit2 className="ml-2" />
      </span>
      <ButtonPrimary
        count={count}
        isLoading={isLoading}
        maxCount={maxCount}
        onSubmit={onTranslate}
        title={t("Traducir")}
      />
    </div>
  );
};

export default CharacterCounter;
