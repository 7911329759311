import { t } from "i18next";
import { useState, useEffect, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import InputSearch from "./InputSearch";

const SelectStandard = ({
  classSelector,
  classContainerOptions,
  options,
  action,
  defaultValue = null,
  searchInput = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const handleSelect = (value) => {
    setSelectedValue(value);
    action(value);
    setIsOpen(false);
    setSearchTerm("");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchInput) {
      const results = options.filter((option) =>
        t(option.name).toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredOptions(results);
    } else {
      setFilteredOptions(options);
    }
  }, [searchTerm, options, defaultValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setSearchTerm("");
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative w-full" ref={containerRef}>
      <div
        className={
          "flex items-center justify-between w-24 p-2 rounded cursor-pointer text-gray-500 text-sm min-w-36 " +
          classSelector
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{t(selectedValue) || "Selecciona algo"}</span>
        <span className="text-gray-500">
          {isOpen ? (
            <FaChevronUp className="transform transition-transform duration-300" />
          ) : (
            <FaChevronDown className="transform transition-transform duration-300" />
          )}
        </span>
      </div>
      {isOpen && (
        <div
          className={
            "absolute left-0 w-32 z-20 mt-2 border border-gray-300 bg-white shadow-lg rounded text-gray-500 " +
            classContainerOptions
          }
        >
          {searchInput && (
            <InputSearch
              handleSearchChange={handleSearchChange}
              searchTerm={searchTerm}
            />
          )}
          <ul className="max-h-60 w-full overflow-y-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((el) => (
                <li
                  key={el.code}
                  className="p-2 hover:text-secondary cursor-pointer rounded"
                  onClick={() => handleSelect(el.name)}
                >
                  {t(el.name)}
                </li>
              ))
            ) : (
              <li className="p-2 text-gray-500">No hay opciones</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectStandard;
