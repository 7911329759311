import { useState } from "react";
import TranslateService from "../../../services/translateService";
import { t } from "i18next";
import SelectStandard from "../../ui/SelectStandard";
import documentType from "../../../config/documentType";
import SelectPlainText from "../../ui/SelectPlainText";
import modesSummarizer from "../../../config/modesSummarizer";
import { Slider } from "@radix-ui/themes";

const HeaderSummarizer = ({
  currentLang,
  currentMode,
  currentDocType,
  currentLength,
  handleChangeLanguage,
  handleLength,
  handleModeChange,
  handleDocChange,
}) => {
  const [translateService] = useState(new TranslateService());

  return (
    <div>
      <div className="flex flex-wrap w-full items-center gap-4 p-4 bg-white rounded-tl-2xl rounded-tr-2xl border-b-[1px]">
        <div className="flex items-center text-sm">
          <SelectStandard
            action={(val) => handleChangeLanguage(val, true)}
            options={translateService.getAvailableLanguages()}
            defaultValue={currentLang}
          />
        </div>
        <div className="text-gray-500">|</div>

        <div className="flex space-x-4 items-center text-gray-500 text-sm">
          <span className="text-primary">{t("Modos")}:</span>
          <SelectPlainText
            options={modesSummarizer}
            action={handleModeChange}
            defaultValue={currentMode}
          />
        </div>

        <div className="text-gray-500">|</div>
        <div className="flex items-center space-x-4 text-gray-500 text-sm">
          <span className="text-primary text-nowrap">
            {t("Tipo de documento")}:
          </span>
          <SelectPlainText
            action={handleDocChange}
            defaultValue={currentDocType}
            options={documentType}
          />
        </div>
        <div className="text-gray-500">|</div>
        <div className="min-w-60 flex gap-2 items-center text-sm">
          <span>Longitud: </span>
          <div className="flex w-full gap-2 items-center">
            <span className="text-xs">{t("Corto")}</span>
            <Slider
              onValueChange={handleLength}
              className="w-full"
              size="1"
              min={1}
              max={3}
              defaultValue={[currentLength]}
              color="yellow"
            />
            <span className="text-xs">{t("Largo")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSummarizer;
