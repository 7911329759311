import { useState } from "react";
import TranslateService from "../../../services/translateService";
import modesParaphrasers from "../../../config/modesParaphraser";
import { t } from "i18next";
import SelectStandard from "../../ui/SelectStandard";
import documentType from "../../../config/documentType";
import SelectPlainText from "../../ui/SelectPlainText";
import modesProofreader from "../../../config/modesProofreader";
import { HoverCard, Text } from "@radix-ui/themes";

const HeaderProofreader = ({
  currentLang,
  currentMode,
  currentDocType,
  handleChangeLanguage,
  handleModeChange,
  handleDocChange,
}) => {
  const [translateService] = useState(new TranslateService());

  return (
    <div className="flex flex-wrap w-full items-center gap-4 p-4 bg-white rounded-tl-2xl rounded-tr-2xl border-b-[1px]">
      <div className="flex items-center text-sm">
        <SelectStandard
          action={(val) => handleChangeLanguage(val, true)}
          options={translateService.getAvailableLanguages()}
          defaultValue={currentLang}
        />
      </div>
      <div className="text-gray-500">|</div>

      <div className="flex space-x-4 items-center text-gray-500 text-sm">
        <span className="text-primary">{t("Modos")}:</span>
        {Object.keys(modesProofreader).map((mode) => (
          <HoverCard.Root key={mode}>
            <HoverCard.Trigger onClick={() => handleModeChange(mode)}>
              <span
                className={`cursor-pointer ${
                  currentMode === mode ? "text-secondary font-semibold" : ""
                }`}
              >
                {t(mode)}
              </span>
            </HoverCard.Trigger>
            <HoverCard.Content size="1" maxWidth="240px">
              <Text as="div" size="1" trim="both">
                {t(modesProofreader[mode])}
              </Text>
            </HoverCard.Content>
          </HoverCard.Root>
        ))}
      </div>
      <div className="text-gray-500">|</div>
      <div className="flex items-center space-x-4 text-gray-500 text-sm">
        <span className="text-primary text-nowrap">
          {t("Tipo de documento")}:
        </span>
        <SelectPlainText
          action={handleDocChange}
          defaultValue={currentDocType}
          options={documentType}
        />
      </div>
    </div>
  );
};

export default HeaderProofreader;
