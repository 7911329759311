import { toast } from "react-toastify";
import useTranslateStore from "../store/translateStore";
import authService from "./authService";
import { t } from "i18next";
import BASE_URL from "../config/api";

class ParaphraseService {
  constructor() {
    this.store = useTranslateStore.getState();
    this.apiUrl = BASE_URL;
  }

  async paraphrase(text, lang, mode) {
    const apiUrl = this.apiUrl + "/v1/novastudio/text-model-inference";
    const apiKey = authService.getToken();

    const requestBody = {
      input_text: `Input: ${text} Idioma: ${lang} Modo: ${mode}"`,
      template: "paraphraser",
      temperature: 0.2,
      model: "gpt-4o-mini",
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 401) {
        toast.error(t("El token ha expirado"));
        authService.handleUnauthorized();
        return null;
      }

      const data = await response.json();

      return data.data.response || t("¡Opps! Algo ha salido mal");
    } catch (error) {
      toast.error(t("¡Opps! Algo ha salido mal"));
      return t("¡Opps! Algo ha salido mal");
    }
  }
}

const paraphraseService = new ParaphraseService();
export default paraphraseService;
