import VideoUpload from "../../components/videoProcessing/VideoUpload";
import VideoStatusTable from "../../components/videoProcessing/VideoStatusTable";
import { useProductStore } from "../../store/generatorStore";
import Title from "./Title";
import { t } from "i18next";
import TranslateService from "../../services/translateService";
import { useState } from "react";
import SelectStandard from "../../components/ui/SelectStandard";

const VideoProcessing = () => {
  const [translateService] = useState(new TranslateService());
  const {
    selectedOneLanguages,
    selectedTwoLanguages,
    setSelectedOneLanguages,
    setSelectedTwoLanguages,
    taskConverter,
  } = useProductStore();

  const handleOneLanguagesSelect = (selectedOption) => {
    setSelectedOneLanguages(selectedOption);
  };
  const handleTwoLanguagesSelect = (selectedOption) => {
    setSelectedTwoLanguages(selectedOption);
  };

  return (
    <div className="flex flex-col h-full">
      <Title title={t("Generador de subtitulos")} />
      <div className="flex w-full h-full mx-auto bg-white rounded-2xl shadow-md">
        {/* First column content */}
        <div className="flex flex-col w-[450px] overflow-y-auto p-6 gap-6 bg-white  rounded-tl-2xl rounded-bl-2xl">
          <p>{t("Lenguaje original")}</p>
          <SelectStandard
            classSelector={"w-full shadow-md"}
            classContainerOptions={"w-full shadow-md"}
            action={handleOneLanguagesSelect}
            options={translateService.getAvailableLanguages()}
            defaultValue={t(selectedOneLanguages)}
          />

          <p>{t("Lenguaje a traducir")}</p>
          <SelectStandard
            classSelector={"w-full shadow-md"}
            classContainerOptions={"w-full shadow-md"}
            action={handleTwoLanguagesSelect}
            options={translateService.getAvailableLanguages()}
            defaultValue={t(selectedTwoLanguages)}
          />

          {/* crear componente subir archivos */}
          <VideoUpload />
        </div>
        {/* Second column content */}
        <div className="w-full h-[70vh] flex flex-col bg-white ml-[13px] items-center rounded-tr-2xl">
          {taskConverter.status !== "Idle" && <VideoStatusTable />}
          {taskConverter.status === "Idle" && (
            <div className="p-6">{t("No se ha generado ningún video")}.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoProcessing;
