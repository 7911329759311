import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaEye,
  FaEyeSlash,
  FaRegArrowAltCircleRight,
  FaRegUser,
} from "react-icons/fa";
import authService from "../services/authService";
import { FaArrowsSpin } from "react-icons/fa6";
import { Validator } from "../services/validator";
import { MdLockOutline } from "react-icons/md";
import { t } from "i18next";

const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bgImageIndex, setBgImageIndex] = useState(getRandomNumber());
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { valid } = new Validator().validateLogin(formData);

    if (valid) {
      const success = await authService.login(
        formData.username,
        formData.password,
      );
      if (success) {
        navigate("/dashboard");
      }
    }
    setIsLoading(false);
  };
  function getRandomNumber() {
    return Math.floor(Math.random() * 12) + 1;
  }

  useEffect(() => {
    const img = new Image();
    const imagePath = `/assets/banana_bgs/img${bgImageIndex}.webp`;
    console.log(imagePath);
    img.src = imagePath;
    img.onload = () => {
      setImageLoaded(true);
      setTimeout(() => {
        setShowContent(true);
      }, 500);
    };
  }, []);

  if (!showContent) {
    return (
      <div
        className={`absolute inset-0 flex items-center justify-center bg-white transition-opacity duration-1000 ${
          imageLoaded ? "opacity-0" : "opacity-100"
        }`}
      >
        <img
          src="/assets/logo-banaina/logo-vertical.png"
          alt="Logo BanAIna"
          className="h-24"
        />
      </div>
    );
  }
  return (
    <div
      className="min-h-full flex flex-1 flex-col items-center justify-center bg-white"
      style={{
        backgroundImage: `url(/assets/banana_bgs/img${bgImageIndex}.webp)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className={`flex flex-col bg-white shadow-md px-4 max-[450px]:h-screen max-[450px]:rounded-none sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md transition-opacity duration-700 ${
          showContent ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="flex items-center justify-center min-h-24">
          <img
            className="h-24 object-contain"
            loading="lazy"
            src={"/assets/banana_logo.png"}
            alt="Logo BanAIna"
          />
        </div>

        <div className="mt-10">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col mb-6">
              <label
                htmlFor="username"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-500"
              >
                {t("Nombre de usuario")}:
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-500">
                  <FaRegUser size={24} />
                </div>

                <input
                  value={formData.username}
                  onChange={(e) =>
                    setFormData({ ...formData, username: e.target.value })
                  }
                  autoComplete="username"
                  id="username"
                  type="text"
                  name="username"
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded border border-gray-400 w-full py-2 focus:outline-none focus:border-secondary"
                  placeholder={t("Usuario")}
                />
              </div>
            </div>
            <div className="flex flex-col mb-6">
              <label
                htmlFor="password"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-500"
              >
                {t("Contraseña")}:
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-500">
                  <span>
                    <MdLockOutline size={28} />
                  </span>
                </div>

                <input
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded border border-gray-400 w-full py-2 focus:outline-none focus:border-secondary"
                  placeholder="********"
                />
                {showPassword ? (
                  <FaEyeSlash
                    onClick={() => setShowPassword(false)}
                    size={24}
                    className="absolute top-2 right-4 cursor-pointer text-gray-500 hover:text-[#521716] transition-all "
                  />
                ) : (
                  <FaEye
                    onClick={() => setShowPassword(true)}
                    size={24}
                    className="absolute top-2 right-4 cursor-pointer text-gray-500 hover:text-[#521716] transition-all "
                  />
                )}
              </div>
            </div>
            <div className="my-7 flex">
              <label
                htmlFor="remember_me"
                className="inline-flex items-center w-full cursor-pointer"
              >
                <input
                  id="remember_me"
                  type="checkbox"
                  className="rounded border-gray-300 text-gray-500 accent-primary"
                  name="remember"
                />
                <span className="ml-2 text-sm text-gray-600">
                  {t("Recuerdame")}
                </span>
              </label>

              <div className="w-full text-right">
                <Link
                  href="#"
                  className="underline text-sm text-gray-500 transition duration-200 hover:text-secondary font-bold"
                >
                  {t("Olvidó su contraseña")}
                </Link>
              </div>
            </div>

            <div className="flex w-full">
              <button
                type="submit"
                disabled={isLoading}
                className="flex items-center justify-center focus:outline-none text-primary text-sm sm:text-base bg-secondary hover:bg-primary hover:text-secondary rounded py-2 w-full transition duration-150 ease-in"
              >
                <span className="mr-2 uppercase font-bold">{t("Login")}</span>
                <span>
                  {isLoading ? (
                    <FaArrowsSpin size={20} className="animate-spin" />
                  ) : (
                    <FaRegArrowAltCircleRight size={20} />
                  )}
                </span>
              </button>
            </div>
            <div className="mt-7">
              <div className="flex justify-center items-center">
                <label className="mr-2"> {t("Eres nuevo")} </label>
                <Link
                  href="#"
                  className=" text-secondary font-bold transition duration-300 ease-in-out  transform hover:-translate-x hover:scale-105"
                >
                  {t("Crea una cuenta")}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
