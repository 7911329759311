import { Dialog, ScrollArea, Skeleton } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import LazyImage from "./LazyImage";
import { FaArrowLeftLong } from "react-icons/fa6";
import CardInfoModal from "./CardInfoModal";
import GalleryModalImage from "./GalleryModalImage";
import authService from "../../../services/authService";

const Gallery = ({ images }) => {
  const [imageSizes, setImageSizes] = useState([]);
  const [selectImage, setSelectImage] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const [isGettingSizes, setIsGettingSizes] = useState(true);

  const handleSelectImage = (index) => {
    setIsImageLoaded(false);
    setSelectImage(imageSizes[index]);
  };

  const handleClosePrompt = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const loadImageSizes = async () => {
      const promises = images.map(async (image) => {
        try {
          const img = new Image();
          img.src = image.url;
          await img.decode(); // Si hay error en la carga, caerá en el catch
          return {
            url: image.url,
            width: img.width,
            height: img.height,
            prompt: image.prompt,
            magicPrompt: image.magicPrompt,
            date: image.date,
          };
        } catch (error) {
          if (error.message.includes("403")) {
            authService.handleUnauthorized();
          }
          console.error(`Error al cargar la imagen: ${image.url}`, error);
          return null; // Maneja la imagen como nula si hubo error
        }
      });

      const sizes = await Promise.all(promises);
      setImageSizes(sizes.filter((size) => size !== null)); // Filtra imágenes nulas
      setIsGettingSizes(false);
    };

    loadImageSizes();
  }, [images]);

  return (
    <ScrollArea className="pt-4 flex-1" style={{ height: "100vh" }}>
      <Skeleton loading={isGettingSizes} className="w-full h-full rounded-xl">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-[minmax(100px, 350px)]">
          {imageSizes.map((image, index) => {
            const rowSpan = Math.ceil((image.height / image.width) * 10);

            return (
              <Dialog.Root key={index}>
                <Dialog.Trigger
                  onClick={() => handleSelectImage(index)}
                  className="cursor-pointer  transition-transform"
                >
                  <div
                    className={`overflow-hidden rounded-lg shadow-md relative`}
                    style={{ gridRowEnd: `span ${rowSpan}` }}
                  >
                    <LazyImage
                      src={image.url}
                      alt={`Generada ${index + 1}`}
                      className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
                    />
                  </div>
                </Dialog.Trigger>
                <Dialog.Content
                  onClick={handleClosePrompt}
                  size="4"
                  maxWidth={
                    image.height === 1792
                      ? "911px"
                      : image.width === 1792
                        ? "1625px"
                        : "1170px"
                  }
                  maxHeight={"88vh"}
                  className="flex gap-6 md:h-[560px] lg:h-[680px] selection:bg-secondary selection:text-primary py-10 px-12"
                >
                  <Dialog.Close className="absolute top-2 left-2 z-20">
                    <FaArrowLeftLong
                      size={36}
                      className="hover:bg-secondary-hover p-2 rounded transition-colors cursor-pointer"
                    />
                  </Dialog.Close>
                  <Dialog.Title className="hidden">
                    Image generate by Banaina
                  </Dialog.Title>
                  <GalleryModalImage image={image} selectImage={selectImage} />
                  <CardInfoModal image={image} />
                </Dialog.Content>
              </Dialog.Root>
            );
          })}
        </div>
      </Skeleton>
    </ScrollArea>
  );
};

export default Gallery;
