import { t } from "i18next";
import { useState, useEffect, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

const SelectPlainText = ({
  options,
  action,
  defaultValue = null,
  classNameSelector,
  classNameBoxOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const containerRef = useRef(null);

  const handleSelect = (value) => {
    setSelectedValue(value);
    action(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative w-full" ref={containerRef}>
      <div
        className={
          "flex items-center justify-between w-24 p-2 rounded cursor-pointer text-gray-500 min-w-36 " +
          classNameSelector
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-nowrap">
          {t(selectedValue) || "Selecciona algo"}
        </span>
        <span className="text-gray-500">
          {isOpen ? (
            <FaChevronUp className="transform transition-transform duration-300" />
          ) : (
            <FaChevronDown className="transform transition-transform duration-300" />
          )}
        </span>
      </div>
      {isOpen && (
        <div
          className={
            "absolute left-0 w-32 z-20 mt-2 border border-gray-300 bg-white shadow-lg rounded text-gray-500 " +
            classNameBoxOptions
          }
        >
          <ul className="max-h-60 w-full overflow-y-auto">
            {options.length > 0 ? (
              options.map((el) => (
                <li
                  key={el}
                  className="p-2 hover:text-secondary cursor-pointer rounded"
                  onClick={() => handleSelect(el)}
                >
                  {t(el)}
                </li>
              ))
            ) : (
              <li className="p-2 text-gray-500">No hay opciones</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectPlainText;
