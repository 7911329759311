import React, { useState } from "react";
import cutParagraph from "../../utils/cutParagraph";
import Title from "./Title";
import { t } from "i18next";
import { maxCountIADetector } from "../../config/maxCountWords";
import ActionPlagiarism from "../../components/dashboard/plagiarism/ActionPlagiarism";
import iadetectorService from "../../services/iadetectorService";

const IADetector = () => {
  const [fromText, setFromText] = useState("");
  const [scanedText, setScanedText] = useState({});
  const [showMessageInfo, setShowMessageInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countChar, setCountChar] = useState(0);

  const handleTextChange = (e) => {
    setFromText(cutParagraph(e.target.value, maxCountIADetector));
    setCountChar(cutParagraph(e.target.value, maxCountIADetector).length);
    setScanedText({});
    setShowMessageInfo(false);
  };

  const handleSummarizer = async () => {
    setIsLoading(true);
    setScanedText({});
    setShowMessageInfo(false);
    try {
      const response = await iadetectorService.scanForIA(fromText);

      const responseParsed = JSON.parse(response);

      setScanedText(responseParsed);
      setShowMessageInfo(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <Title title={t("Detector de IA")} />
      <div className="flex flex-col w-full h-full mx-auto bg-white rounded-2xl shadow-md">
        <div className="flex flex-1">
          <div className="flex flex-col flex-[2] bg-white rounded-tl-2xl rounded-bl-2xl">
            <textarea
              value={fromText}
              onChange={handleTextChange}
              className="flex-1 w-full outline-none resize-none p-6 text-gray-500 rounded-tl-2xl"
            />
            <div className="flex items-center justify-end gap-2 p-6">
              <ActionPlagiarism
                count={countChar}
                handleSummarizer={handleSummarizer}
                isLoading={isLoading}
                maxCount={maxCountIADetector}
              />
            </div>
          </div>

          <div className="flex flex-col flex-1 bg-white rounded-tr-2xl rounded-br-2xl border-l-2 p-2">
            {showMessageInfo && (
              <div className="p-6 h-full">
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="text-7xl text-gray-500 flex">
                    {scanedText.iaScore} <span className="text-3xl">%</span>
                  </div>
                  <div className="text-gray-500 text-center">
                    {t("del texto es probablemente generado por IA")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IADetector;
