import { IoMdMusicalNote, IoMdOptions } from "react-icons/io";

const musicOptions = [
  {
    icon: IoMdMusicalNote,
    label: "Genero",
    value: "genero",
  },
  {
    icon: IoMdOptions,
    label: "Duración",
    value: "duracion",
  },
  {
    icon: IoMdOptions,
    label: "Instrumentos",
    value: "instrumentos",
  },
  {
    icon: IoMdOptions,
    label: "Energia",
    value: "energia",
  },
  {
    icon: IoMdOptions,
    label: "Estructura",
    value: "estructura",
  },
  {
    icon: IoMdOptions,
    label: "Tempo",
    value: "tempo",
  },
  {
    icon: IoMdOptions,
    label: "Llave",
    value: "llave",
  },
];

export default musicOptions;
