import { useForm } from "react-hook-form";
import useChatStore from "../../../store/messageStore";
import { IoIosSearch } from "react-icons/io";
import { t } from "i18next";

const SearchInput = () => {
  const { categorizedChats, setFilteredChats } = useChatStore();

  const handleInputChange = (e) => {
    const searchText = e.target.value.toLowerCase();

    const filteredCategories = {
      today: [],
      week: [],
      month: [],
      previousMonths: [],
    };

    // Filtrar en cada categoría
    Object.keys(categorizedChats).forEach((category) => {
      if (categorizedChats[category].length > 0) {
        const filteredChats = categorizedChats[category]?.filter((chat) =>
          chat.title.toLowerCase().includes(searchText),
        );
        filteredCategories[category] = filteredChats;
      }
    });
    setFilteredChats(filteredCategories);
  };

  return (
    <div className="p-2 flex items-center border-b-[1px] ">
      <input
        type="text"
        placeholder={t("Buscar")}
        onChange={handleInputChange}
        className="w-full rounded outline-none"
      />
      <IoIosSearch size={16} className="ml-2 text-gray-500" />
    </div>
  );
};

export default SearchInput;
