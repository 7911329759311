import { useRef, useEffect, useLayoutEffect } from "react";
import useChatStore from "../../../store/messageStore";
import useTemplateStore from "../../../store/templateStore";
import Loading from "./Loading";
import LoadingChat from "./loadingChat";
import { t } from "i18next";
import "highlight.js/styles/atom-one-dark.css";
import Message from "./Message";

const ChatArea = () => {
  const chatContainerRef = useRef(null);
  const lastMessageRef = useRef(null);
  const {
    chatType,
    setChatId,
    messagesHistory,
    setMessagesHistory,
    isSending,
    loading,
    setLoading,
    fetchMessages,
    newMessage,
    chatId,
    messages,
    setMessages,
  } = useChatStore();
  const { setSelectedAgent } = useTemplateStore();

  useEffect(() => {
    if (chatType) {
      setMessagesHistory(null);
      setChatId(null);
    }
  }, [chatType, setMessagesHistory, setChatId]);

  useEffect(() => {
    if (chatId && chatId !== null && newMessage === false) {
      setMessages([]);
      fetchMessages(chatId).then((response) => {
        if (response) {
          setSelectedAgent(response.template_id || undefined);
        }
      });
    } else {
      setMessagesHistory(null);
      setLoading(false);
    }
  }, [
    chatId,
    newMessage,
    fetchMessages,
    setMessages,
    setSelectedAgent,
    setMessagesHistory,
    setLoading,
  ]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [messages, messagesHistory]);

  return (
    <div ref={chatContainerRef} className="chats p-6 gap-6 flex flex-col ">
      {loading ? (
        <Loading text={t("Cargando mensajes") + "..."} />
      ) : (
        <>
          {messagesHistory?.history.map((message, index) => (
            <div
              key={index}
              className={`grid grid-cols-12 gap-y-2 items-center `}
              style={{
                justifyContent: message.role === "user" ? "end" : "initial",
              }}
            >
              {message.role === "assistant" ? (
                <div className="col-start-1 col-end-11">
                  <Message key={index} message={message} index={index} />
                </div>
              ) : (
                <div className="col-start-2 col-end-12">
                  <Message key={index} message={message} index={index} />
                </div>
              )}
            </div>
          ))}
          {messages.map((message, index) => (
            <Message key={index} message={message} index={index} />
          ))}

          {isSending && (
            <div className="flex gap-6 items-center">
              <img
                src="/assets/assistant.png"
                alt="assistant macaque"
                className="shrink-0 self-baseline h-11 w-11 rounded-full"
              />
              <LoadingChat text="" />
            </div>
          )}
        </>
      )}
      <div ref={lastMessageRef} />
    </div>
  );
};

export default ChatArea;
