import OptionCardLayout from "./OptionCardLayout";

const InstrumentsCard = ({ className }) => {
  return (
    <OptionCardLayout className={className}>
      <h1>InstrumentsCard</h1>
    </OptionCardLayout>
  );
};
export default InstrumentsCard;
