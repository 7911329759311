import { Outlet } from "react-router-dom";
import Sidebar from "../../components/dashboard/Sidebar";
import useSessionStore from "../../store/sessionStore";
import SessionExpiredModal from "../../components/alerts/SessionExpired";
import { useProductStore } from "../../store/generatorStore";
import { useEffect } from "react";
import authService from "../../services/authService";

const DashboardLayout = () => {
  const { resumeTask } = useProductStore();
  const isModalOpen = useSessionStore((state) => state.isModalOpen);

  useEffect(() => {
    const isLoggedIn = authService.isLoggedIn();
    const userInfo = authService.getUserInfo();

    if (isLoggedIn) {
      let expiration_time_session = new Date(
        userInfo.expiration_time,
      ).getTime();
      if (expiration_time_session < new Date().getTime()) {
        authService.handleUnauthorized();
      }
    }
    resumeTask();
  }, []);
  return (
    <div className="flex h-full min-h-full bg-tertiary">
      <Sidebar />
      <div className="flex justify-center max-h-full w-full ml-60 overflow-y-auto p-8">
        <div className="w-full h-full">
          <Outlet />
        </div>
      </div>
      <SessionExpiredModal open={isModalOpen} />
    </div>
  );
};

export default DashboardLayout;
