import React, { useState } from "react";
import {
  BsFiletypeCsv,
  BsFiletypeDocx,
  BsFiletypePdf,
  BsFiletypeTxt,
} from "react-icons/bs";
import { RiDownloadLine } from "react-icons/ri";
import { Dialog } from "@radix-ui/themes";
import exportService from "../../services/exportService";
import { LuImport } from "react-icons/lu";
import { t } from "i18next";

const ExportButton = ({
  text,
  fileName,
  transcribes = null,
  disabled = false,
}) => {
  const [selectedFormats, setSelectedFormats] = useState({
    pdf: true,
    docx: false,
    txt: false,
    csv: false,
    srt: false,
    vtt: false,
  });

  const handleFormatChange = (format) => {
    setSelectedFormats((prev) => ({
      ...prev,
      [format]: !prev[format],
    }));
  };

  const handleDownload = async () => {
    if (Object.values(selectedFormats).some((format) => format)) {
      !transcribes
        ? await exportService.exportToZIP(text, fileName, selectedFormats)
        : await exportService.exportTranscribeToZIP(
            transcribes,
            selectedFormats
          );
    }
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <button
          className={`px-4 py-2 flex items-center gap-1 font-semibold  rounded ${
            disabled
              ? "opacity-70 bg-gray-300 text-gray-500"
              : "bg-secondary text-primary"
          }`}
          disabled={disabled}
        >
          <LuImport className="font-bold" />
          {t("Exportar")}
        </button>
      </Dialog.Trigger>

      <Dialog.Content
        size="2"
        className="py-8 px-12 rounded-lg bg-white shadow-lg"
        aria-label="Opciones de Exportación"
      >
        <Dialog.Title className="text-xl font-semibold text-gray-500 mb-4">
          {t("Exportar Texto Traducido")}
        </Dialog.Title>

        <Dialog.Description className="text-sm text-gray-500 mb-4">
          {t("Selecciona uno o más formatos de exportación")}.
        </Dialog.Description>

        {/* Opciones de formatos */}
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="export-pdf"
              checked={selectedFormats.pdf}
              onChange={() => handleFormatChange("pdf")}
              className="accent-secondary"
            />
            <label htmlFor="export-pdf" className="flex items-center gap-2">
              <BsFiletypePdf size={24} />
              {t("Exportar como PDF")}
            </label>
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="export-docx"
              checked={selectedFormats.docx}
              onChange={() => handleFormatChange("docx")}
              className="accent-secondary "
            />
            <label htmlFor="export-docx" className="flex items-center gap-2">
              <BsFiletypeDocx size={24} />
              {t("Exportar como DOCX")}
            </label>
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="export-txt"
              checked={selectedFormats.txt}
              onChange={() => handleFormatChange("txt")}
              className="accent-secondary"
            />
            <label htmlFor="export-txt" className="flex items-center gap-2">
              <BsFiletypeTxt size={24} />
              {t("Exportar como TXT")}
            </label>
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="export-csv"
              checked={selectedFormats.csv}
              onChange={() => handleFormatChange("csv")}
              className="accent-secondary"
            />
            <label htmlFor="export-csv" className="flex items-center gap-2">
              <BsFiletypeCsv size={24} />
              {t("Exportar como CSV")}
            </label>
          </div>
        </div>

        {/* Botón de descarga */}
        <div className="flex justify-center mt-6 w-full">
          <button
            className="w-full px-6 py-2 bg-secondary text-primary font-semibold rounded flex items-center justify-center gap-2"
            onClick={handleDownload}
          >
            <RiDownloadLine size={20} />
            {t("Descargar")}
          </button>
        </div>

        <Dialog.Close>
          <button className="absolute top-4 right-4 text-gray-400 hover:text-gray-700">
            ✕
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ExportButton;
