import axios from "axios";
import BASE_URL from "../config/api";
import authService from "./authService";
import { useProductStore } from "../store/generatorStore";
import { toast } from "react-toastify";
import { t } from "i18next";

const removeFileExtension = (fileName) => {
  return fileName.replace(/\.[^/.]+$/, "");
};

export const generateVideo = async (document, device, srcLang, tgtLang) => {
  const token = authService.getToken();
  const { setTaskConverter, setResult } = useProductStore.getState();

  if (!document) {
    throw new Error("No document provided");
  }
  const formData = new FormData();
  formData.append("file", document);

  const response = await axios.post(
    `${BASE_URL}/v1/novastudio/video-transcription?device=${device}&src_lang=${srcLang}&tgt_lang=${tgtLang}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const fileNameWithoutExtension = removeFileExtension(document.name);

  const { task_id, status } = response.data.data;
  setResult("Processing");
  setTaskConverter({
    taskId: task_id,
    name: fileNameWithoutExtension,
    status: status,
  });

  return await pollTaskStatus(task_id);
};
export const pollTaskStatus = async (taskId) => {
  const { setTaskConverter, taskConverter, setResult, setIsPolling } =
    useProductStore.getState();

  const checkStatus = async () => {
    const token = authService.getToken();
    if (!token) return;

    const response = await axios.get(
      `${BASE_URL}/v1/novastudio/task-status?task_id=${taskId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { status, result } = response.data.data;

    if (status === "completed") {
      setTaskConverter({ taskId, name: taskConverter.name, status });
      setResult(result);
      toast.success(t("Procesamiento de video terminado"), {
        autoClose: 5000,
      });
    } else if (status === "processing") {
      // THis is for stop the initial polling con app.js
      setIsPolling(false);
      setTimeout(checkStatus, 5000);
    } else {
      setTaskConverter({ taskId, name: taskConverter.name, status });
    }
    return undefined;
  };

  return await checkStatus();
};

export const downloadGeneratedFile = async (id) => {
  const { taskConverter } = useProductStore.getState();
  const token = authService.getToken();
  try {
    const response = await axios.get(
      `${BASE_URL}/v1/novastudio/video-transcription/download/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${taskConverter.name}.srt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error al descargar el archivo:", error);
  }
};
