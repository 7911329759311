import { FaArrowsSpin } from "react-icons/fa6";

const ButtonPrimary = ({
  onSubmit,
  count,
  maxCount,
  isLoading,
  title,
  disabled,
  className,
}) => {
  return (
    <button
      onClick={onSubmit}
      disabled={count > maxCount || count === 0 || isLoading || disabled}
      className={
        `  py-2 px-4 rounded flex items-center font-semibold ${
          count > maxCount || count === 0 || isLoading || disabled
            ? "opacity-70 bg-gray-300 text-gray-500"
            : "bg-secondary text-primary"
        } ` + className
      }
    >
      {!isLoading ? (
        title
      ) : (
        <>
          {title} <FaArrowsSpin size={20} className="animate-spin ml-1" />
        </>
      )}
    </button>
  );
};

export default ButtonPrimary;
