import { t } from "i18next";
import { FaArrowsSpin } from "react-icons/fa6";
import { FiEdit2 } from "react-icons/fi";
import ExportButton from "../../ui/ExportButton";
import stripHtmlTags from "../../../utils/stripHtmlTags";
import { MdOutlineContentCopy } from "react-icons/md";
import copyClipboard from "../../../utils/copyClipboard";
import stripHtmlToExport from "../../../utils/stripHtmlToExport";
import ButtonPrimary from "../../ui/ButtonPrimary";

const ActionProofreader = ({
  count,
  maxCount,
  handleProofreader,
  content,
  isLoading,
  textToCopy,
}) => {
  const isDisabled = () => {
    return count === 0 || count > maxCount || isLoading;
  };
  return (
    <div className="flex w-full items-center justify-between px-6">
      <span
        className={`text-sm flex items-center ${
          count > maxCount ? "text-red-500 font-semibold" : "text-gray-500"
        }`}
      >
        {count} / {maxCount} {t("Caracteres")}
        <FiEdit2 className="ml-2" />
      </span>

      <div className="flex gap-4 items-center">
        <ButtonPrimary
          count={count}
          disabled={isDisabled()}
          isLoading={isLoading}
          maxCount={maxCount}
          onSubmit={handleProofreader}
          title={t("Corrector")}
        />

        <ExportButton
          disabled={stripHtmlTags(content).length === 0}
          text={stripHtmlToExport(textToCopy)}
          fileName={`BanAIna ${new Date().getTime()}`}
        />
        <MdOutlineContentCopy
          size={24}
          onClick={() => copyClipboard(textToCopy)}
          className={`${
            textToCopy ? "cursor-pointer opacity-100" : "opacity-50"
          } `}
        />
      </div>
    </div>
  );
};

export default ActionProofreader;
