import HeaderParaphraser from "../../components/dashboard/paraphraser/HeaderParaphraser";
import Title from "./Title";
import { t } from "i18next";
import TextAreasParaphraser from "../../components/dashboard/paraphraser/TextAreasParaphraser";
import { useCallback, useState } from "react";
import { maxCountParaphraser } from "../../config/maxCountWords";
import cutParagraph from "../../utils/cutParagraph";
import paraphraseService from "../../services/paraphraseService";
import modesParaphrasers from "../../config/modesParaphraser";

function Paraphraser() {
  const [fromText, setFromText] = useState("");
  const [textParaphrased, setTextParaphrased] = useState("");
  const [isParaphrasing, setIsParaphrasing] = useState(false);
  const [currentLang, setCurrentLang] = useState("Spanish");
  const [currentMode, setCurrentMode] = useState(modesParaphrasers[0]);

  const handleChangeLanguage = (value) => {
    setCurrentLang(value);
  };
  const handleModeChange = (value) => {
    setCurrentMode(value);
  };
  const handleParaphraser = async (textUploaded = null) => {
    setIsParaphrasing(true);
    let response = null;
    if (textUploaded) {
      response = await paraphraseService.paraphrase(
        textUploaded,
        currentLang,
        currentMode
      );
    } else {
      response = await paraphraseService.paraphrase(
        fromText,
        currentLang,
        currentMode
      );
    }

    setTextParaphrased(response);
    setIsParaphrasing(false);
  };

  const handleTextChange = useCallback((e) => {
    if (e.target.value.trim().length === 0) {
      setTextParaphrased("");
    }
    setFromText(cutParagraph(e.target.value, maxCountParaphraser));
  }, []);

  return (
    <div className="flex flex-col h-full">
      <Title title={t("Parafraseador")} />
      <div className="flex flex-col w-full h-full mx-auto bg-white rounded-2xl shadow-md ">
        <HeaderParaphraser
          currentLang={currentLang}
          currentMode={currentMode}
          handleChangeLanguage={handleChangeLanguage}
          handleModeChange={handleModeChange}
        />
        <div className="flex-1">
          <TextAreasParaphraser
            fromText={fromText}
            handleParaphraser={handleParaphraser}
            handleTextChange={handleTextChange}
            isParaphrasing={isParaphrasing}
            maxCount={maxCountParaphraser}
            textParaphrased={textParaphrased}
          />
        </div>
      </div>
    </div>
  );
}

export default Paraphraser;
