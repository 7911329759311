import { t } from "i18next";
import { useEffect, useRef } from "react";
import { IoIosSearch } from "react-icons/io";

const InputSearch = ({ searchTerm, handleSearchChange }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <div className="p-2 flex items-center border-b border-gray-300">
      <input
        ref={inputRef}
        type="text"
        placeholder={t("Buscar")}
        value={searchTerm}
        onChange={handleSearchChange}
        className="w-full p-1 rounded outline-none"
      />
      <IoIosSearch size={16} className="ml-2 text-gray-500" />
    </div>
  );
};

export default InputSearch;
