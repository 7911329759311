const resourcesLangs = {
  en: {
    translation: {
      "Anterior cambio": "Previous change",
      "Aceptar cambio actual": "Accept current change",
      "Aceptar todos los cambios": "Accept all changes",
      "Ignorar todos los cambios": "Ignore all changes",
      "Siguiente cambio": "Next change",
      Hoy: "Today",
      "7 días anteriores": "7 days ago",
      "30 días anteriores": "30 days ago",
      "Meses anteriores": "Months ago",
      Enero: "January",
      Febrero: "February",
      Marzo: "March",
      Abril: "April",
      Mayo: "May",
      Junio: "June",
      Julio: "July",
      Agosto: "August",
      Septiembre: "September",
      Octubre: "October",
      Noviembre: "November",
      Diciembre: "December",
      Copiar: "Copy",
      "Generando código": "Generating code",
      "Genera tu código fácilmente": "Generate your code easily",
      "Especifique qué tipo de función o fragmento de código desea generar":
        "Specify what type of function or code snippet you would like to generate",
      "Proporcionar instrucciones": "Provide instructions",
      "Lenguaje de Programación": "Programming Language",
      "Desarrollar código en varios lenguajes de programación a partir de un texto":
        "Develop code in various programming languages based on a text",
      "Generar código con IA": "Generate code with AI",
      "¡Chat eliminado!": "Chat deleted!",
      "Cargando mensajes": "Loading messages",
      "Ingrese nuevo nombre": "Enter new name",
      "Asistente IA": "AI Assistant",
      "Nueva conversación": "New conversation",
      "Cambiar el nombre del título del chat": "Change chat title name",
      "Este campo es requerido": "This field is required",
      "Confirmar eliminación de chat": "Confirm chat deletion",
      "Eliminará permanentemente este historial de chat":
        "This will permanently delete this chat history",
      Enviar: "Send",
      "Escribe tu mensaje aquí...": "Type your message here...",
      Confirmar: "Confirm",
      Cancelar: "Cancel",
      Asistente: "Assistant",
      "Generando video. Se le informará por una notificación cuando termine":
        "Generating video. You will be notified when it is finished",
      Generando: "Generating",
      Acciones: "Actions",
      "No se ha generado ningún video": "No video has been generated",
      "Procesando video": "Processing video",
      "Lenguaje a traducir": "Language to translate",
      "Lenguaje original": "Original language",
      "Subir video": "Upload video",
      "Generador de subtitulos": "Subtitle generator",
      Subtítulos: "Subtitles",
      Código: "Code",
      "Generando imagen...": "Generating image...",
      Música: "Music",
      "Generador de código": "Code generator",
      "Genera código automáticamente en varios lenguajes de programación, acelerando el desarrollo y optimizando tareas repetitivas":
        "Automatically generates code in various programming languages, speeding up development and optimizing repetitive tasks",
      "Generador de música": "Music generator",
      "Crea pistas musicales originales a partir de entradas personalizadas, perfectas para proyectos creativos y multimedia":
        "Create original music tracks based on customized input, perfect for creative and multimedia projects",
      "Convierte ideas en episodios de podcasts, generando contenido de audio atractivo y de alta calidad":
        "Turn ideas into podcast episodes, creating engaging and high-quality audio content",
      "Optimiza el contenido para motores de búsqueda, mejorando la visibilidad y el posicionamiento en línea":
        "Optimize the content for search engines, improving visibility and online ranking",
      "¡Imagen copiada al portapapeles!": "Image copied to clipboard!",
      Resolution: "Resolution",
      "Fecha de creación": "Creation date",
      "Ver menos": "See less",
      "Ver más": "See more",
      "Aspect ratio": "Aspect ratio",
      "mejora tu mensaje inicial para aumentar la variedad y riqueza de las imágenes":
        "Enhance your initial message to increase the variety and richness of the imagery",
      "Magic Prompt": "Magic Prompt",
      Anime: "Anime",
      Diseño: "Design",
      Realista: "Realistic",
      "Describe lo que quieres ver": "Describe what you want to see",
      General: "General",
      Académico: "Academic",
      Negocio: "Business",
      Creativo: "Creative",
      Web: "Web",
      "Tipo de documento": "Document type",
      Parafrasear: "Paraphrase",
      Corrector: "Proofreader",
      Fluido: "Fluent",
      Modos: "Modes",
      "Profesional - formal": "Professional",
      "Profesional - amigable": "Friendly",
      Chat: "Agents",
      Corporativo: "Corporate",
      Parafraseador: "Paraphraser",
      "Video a texto": "Video to text",
      "Imagen a texto": "Image to text",
      Imagen: "Image",
      Video: "Video",
      "Trabajando en ello": "Working on it",
      "¡Bienvenido!": "Welcome!",
      "Ha ocurrido un error": "An error has occurred",
      "Error eliminando algunos archivos": "Error deleting some files",
      "Eliminados exitosamente": "Successfully deleted",
      "¡Copiado!": "Copied!",
      "¡Transcripción realizada!": "Transcription completed!",
      "Error tratando de enviar el audio": "Error trying to send the audio",
      "Ocurrió un error. Por favor, intenta de nuevo":
        "An error occurred. Please try again",
      "El token ha expirado": "The token has expired",
      "¡Opps! Algo ha salido mal": "Oops! Something went wrong",
      "Error durante la traducción": "Error during translation",
      "Incorrect username or password": "Incorrect credentials",
      "La contraseña es obligatoria": "Password is required",
      "Las contraseñas no coinciden": "Passwords do not match",
      "El nombre de usuario es obligatorio": "Username is required",
      "Nombre de usuario": "Username",
      Usuario: "User",
      Contraseña: "Password",
      Recuerdame: "Remember me",
      "Olvidó su contraseña": "Forgot your password?",
      "Eres nuevo": "New here?",
      "Crea una cuenta": "Create an account",
      Traductor: "Translator",
      Caracteres: "Characters",
      Traducir: "Translate",
      Exportar: "Export",
      "Exportar Texto Traducido": "Export Text",
      "Selecciona uno o más formatos de exportación":
        "Select one or more export formats",
      "Exportar como PDF": "Export as PDF",
      "Exportar como DOCX": "Export as DOCX",
      "Exportar como TXT": "Export as TXT",
      "Exportar como CSV": "Export as CSV",
      Descargar: "Download",
      "Idioma del audio": "Audio language",
      "Pausar grabación": "Pause recording",
      "Seguir grabación": "Resume recording",
      Transcribir: "Transcribe",
      Buscar: "Search",
      Grabando: "Recording",
      Grabar: "Record",
      Pausa: "Pause",
      Español: "Spanish",
      Inicio: "Home",
      Audio: "Audio",
      Archivos: "Files",
      "Subir Audio": "Upload Audio",
      Subir: "Upload",
      Nombre: "Name",
      Fecha: "Date",
      Subido: "Uploaded",
      Duración: "Duration",
      "Audio a texto": "Audio to text",
      Shortcuts: "Shortcuts",
      Recientes: "Recent",
      "Sin categoria": "Uncategorized",
      Carpetas: "Folders",
      "Nueva carpeta": "New folder",
      Texto: "Text",
      welcome: "Welcome",
      Login: "Login",
      dashboard: "Dashboard",
      "Selecciona multiples archivos para descargar":
        "Select multiple files to download",
      Resumidor: "Summarizer",
      Resumir: "Summarize",
      Corto: "Short",
      Largo: "Long",
      issue: "issue",
      Spelling: "Spelling",
      Plagio: "Plagiarism",
      Escanear: "Scan",
      "Detector de IA": "AI Detector",
      Detectar: "Detect",
      "El cambio excede el límite de palabras":
        "The change exceeds the word limit",
      "del texto es probablemente generado por IA":
        "The text is likely generated by AI",
      Ortográfico: "Spelling",
      Claridad: "Clarity",
      Idioma: "Language",
      "Se corrige el error ortográfico para mejorar la calidad del texto.":
        "The spelling error is corrected to improve the quality of the text.",
      Light: "Light",
      Standard: "Standard",
      Intensive: "Intensive",
      Concise: "Concise",
      "Removes all grammar, spelling, and punctuation errors":
        "Removes all grammar, spelling, and punctuation errors",
      "Improves vocabulary and removes all grammar, spelling, and punctuation errors":
        "Improves vocabulary and removes all grammar, spelling, and punctuation errors",
      "Elevates flow, improves vocabulary, and removes all grammar, spelling, and punctuation errors":
        "Elevates flow, improves vocabulary, and removes all grammar, spelling, and punctuation errors",
      "Condenses text, improves clarity, and removes all grammar, spelling, and punctuation errors":
        "Condenses text, improves clarity, and removes all grammar, spelling, and punctuation errors",
      "No se detecta ningún texto": "No text detected",
      'Ingrese su texto y haga clic en "Corrector" para ver la revisión':
        'Enter your text and click on "Poorfreader" to see the review',
      "Tu texto es claro y está libre de errores":
        "Your text is clear and free of errors",
      "Crear imágenes": "Create images",
      "Texto a imagen": "Text to image",
      "Asistente de IA para comunicación interna y soporte corporativo eficiente y personalizado":
        "AI Assistant for efficient and personalized internal communication and corporate support",
      "Transcribe audios a texto automáticamente, facilitando la accesibilidad y el análisis de contenido hablado":
        "Transcribe audio to text automatically, facilitating accessibility and the analysis of spoken content",
      "Genera imágenes solo con mediante el uso de texto":
        "Generate images solely through the use of text",
      "Mejora la gramática y estilo de tus textos con un corrector ortográfico impulsado por IA":
        "Improve the grammar and style of your texts with an AI-powered spell checker",
      "Identifica textos generados por inteligencia artificial para garantizar la autenticidad del contenido":
        "Identify texts generated by artificial intelligence to ensure the authenticity of the content",
      "Reformula textos manteniendo el significado original, ideal para evitar plagio y mejorar redacción":
        "Rephrase texts while maintaining the original meaning, ideal for avoiding plagiarism and improving writing",
      "Traduce textos entre múltiples idiomas de manera precisa utilizando inteligencia artificial avanzada":
        "Translate texts between multiple languages accurately using advanced artificial intelligence",
      "Extrae texto de videos automáticamente, ideal para subtitulado y análisis de contenido audiovisual":
        "Extract text from videos automatically, ideal for subtitling and analyzing audiovisual content",
      "Verifica la originalidad de tus textos detectando posibles casos de plagio en múltiples fuentes":
        "Check the originality of your texts by detecting possible cases of plagiarism across multiple sources",
      "Condensa textos largos en resúmenes claros y concisos sin perder la información clave":
        "Condense long texts into clear and concise summaries without losing key information",
      Afrikaans: "Afrikaans",
      Albanian: "Albanian",
      Amharic: "Amharic",
      Arabic: "Arabic",
      Armenian: "Armenian",
      Azerbaijani: "Azerbaijani",
      Basque: "Basque",
      Belarusian: "Belarusian",
      Bengali: "Bengali",
      Bosnian: "Bosnian",
      Bulgarian: "Bulgarian",
      Catalan: "Catalan",
      Cebuano: "Cebuano",
      Chichewa: "Chichewa",
      "Chinese (Simplified)": "Chinese (Simplified)",
      "Chinese (Traditional)": "Chinese (Traditional)",
      Corsican: "Corsican",
      Croatian: "Croatian",
      Czech: "Czech",
      Danish: "Danish",
      Dutch: "Dutch",
      English: "English",
      Esperanto: "Esperanto",
      Estonian: "Estonian",
      Filipino: "Filipino",
      Finnish: "Finnish",
      French: "French",
      Frisian: "Frisian",
      Galician: "Galician",
      Georgian: "Georgian",
      German: "German",
      Greek: "Greek",
      Gujarati: "Gujarati",
      "Haitian Creole": "Haitian Creole",
      Hausa: "Hausa",
      Hawaiian: "Hawaiian",
      Hindi: "Hindi",
      Hmong: "Hmong",
      Hungarian: "Hungarian",
      Icelandic: "Icelandic",
      Igbo: "Igbo",
      Indonesian: "Indonesian",
      Irish: "Irish",
      Italian: "Italian",
      Japanese: "Japanese",
      Javanese: "Javanese",
      Kannada: "Kannada",
      Kazakh: "Kazakh",
      Khmer: "Khmer",
      Kinyarwanda: "Kinyarwanda",
      Korean: "Korean",
      "Kurdish (Kurmanji)": "Kurdish (Kurmanji)",
      Kyrgyz: "Kyrgyz",
      Lao: "Lao",
      Latin: "Latin",
      Latvian: "Latvian",
      Lithuanian: "Lithuanian",
      Luxembourgish: "Luxembourgish",
      Macedonian: "Macedonian",
      Malagasy: "Malagasy",
      Malay: "Malay",
      Malayalam: "Malayalam",
      Maltese: "Maltese",
      Maori: "Maori",
      Marathi: "Marathi",
      Mongolian: "Mongolian",
      "Myanmar (Burmese)": "Myanmar (Burmese)",
      Nepali: "Nepali",
      Norwegian: "Norwegian",
      "Odia (Oriya)": "Odia (Oriya)",
      Pashto: "Pashto",
      Persian: "Persian",
      Polish: "Polish",
      Portuguese: "Portuguese",
      Punjabi: "Punjabi",
      Romanian: "Romanian",
      Russian: "Russian",
      Samoan: "Samoan",
      "Scots Gaelic": "Scots Gaelic",
      Serbian: "Serbian",
      Sesotho: "Sesotho",
      Shona: "Shona",
      Sindhi: "Sindhi",
      Sinhala: "Sinhala",
      Slovak: "Slovak",
      Slovenian: "Slovenian",
      Somali: "Somali",
      Spanish: "Spanish",
      Sundanese: "Sundanese",
      Swahili: "Swahili",
      Swedish: "Swedish",
      Tajik: "Tajik",
      Tamil: "Tamil",
      Tatar: "Tatar",
      Telugu: "Telugu",
      Thai: "Thai",
      Turkish: "Turkish",
      Turkmen: "Turkmen",
      Ukrainian: "Ukrainian",
      Urdu: "Urdu",
      Uyghur: "Uyghur",
      Uzbek: "Uzbek",
      Vietnamese: "Vietnamese",
      Welsh: "Welsh",
      Xhosa: "Xhosa",
      Yiddish: "Yiddish",
      Yoruba: "Yoruba",
      Zulu: "Zulu",
      Hebrew: "Hebrew",
      "Bullet points": "Bullet points",
      Markdown: "Markdown",
      Paragraph: "Paragraph",
      Title: "Title",
      Generar: "Generate",
    },
  },
  es: {
    translation: {
      "Anterior cambio": "Anterior cambio",
      "Aceptar cambio actual": "Aceptar cambio actual",
      "Aceptar todos los cambios": "Aceptar todos los cambios",
      "Ignorar todos los cambios": "Ignorar todos los cambios",
      "Siguiente cambio": "Siguiente cambio",
      Hoy: "Hoy",
      "7 días anteriores": "7 días anteriores",
      "30 días anteriores": "30 días anteriores",
      "Meses anteriores": "Meses anteriores",
      Enero: "Enero",
      Febrero: "Febrero",
      Marzo: "Marzo",
      Abril: "Abril",
      Mayo: "Mayo",
      Junio: "Junio",
      Julio: "Julio",
      Agosto: "Agosto",
      Septiembre: "Septiembre",
      Octubre: "Octubre",
      Noviembre: "Noviembre",
      Diciembre: "Diciembre",
      Copiar: "Copiar",
      "Generando código": "Generando código",
      "Genera tu código fácilmente": "Genera tu código fácilmente",
      "Especifique qué tipo de función o fragmento de código desea generar":
        "Especifique qué tipo de función o fragmento de código desea generar",
      "Proporcionar instrucciones": "Proporcionar instrucciones",
      "Lenguaje de Programación": "Lenguaje de Programación",
      "Desarrollar código en varios lenguajes de programación a partir de un texto":
        "Desarrollar código en varios lenguajes de programación a partir de un texto",
      "Generar código con IA": "Generar código con IA",
      "¡Chat eliminado!": "¡Chat eliminado!",
      "Cargando mensajes": "Cargando mensajes",
      "Ingrese nuevo nombre": "Ingrese nuevo nombre",
      "Asistente IA": "Asistente IA",
      "Nueva conversación": "Nueva conversación",
      "Cambiar el nombre del título del chat":
        "Cambiar el nombre del título del chat",
      "Este campo es requerido": "Este campo es requerido",
      "Confirmar eliminación de chat": "Confirmar eliminación de chat",
      "Eliminará permanentemente este historial de chat":
        "Eliminará permanentemente este historial de chat",
      Enviar: "Enviar",
      "Escribe tu mensaje aquí...": "Escribe tu mensaje aquí...",
      Confirmar: "Confirmar",
      Cancelar: "Cancelar",
      Asistente: "Asistente",
      "Generando video. Se le informará por una notificación cuando termine":
        "Generando video. Se le informará por una notificación cuando termine",
      Generando: "Generando",
      Acciones: "Acciones",
      "No se ha generado ningún video": "No se ha generado ningún video",
      "Procesando video": "Procesando video",
      "Lenguaje a traducir": "Lenguaje a traducir",
      "Lenguaje original": "Lenguaje original",
      "Subir video": "Subir video",
      "Generador de subtitulos": "Generador de subtitulos",
      Subtítulos: "Subtítulos",
      "Generando imagen...": "Generando imagen...",
      "Generador de código": "Generador de código",
      "Genera código automáticamente en varios lenguajes de programación, acelerando el desarrollo y optimizando tareas repetitivas":
        "Genera código automáticamente en varios lenguajes de programación, acelerando el desarrollo y optimizando tareas repetitivas",
      "Generador de música": "Generador de música",
      "Crea pistas musicales originales a partir de entradas personalizadas, perfectas para proyectos creativos y multimedia":
        "Crea pistas musicales originales a partir de entradas personalizadas, perfectas para proyectos creativos y multimedia",
      "Convierte ideas en episodios de podcasts, generando contenido de audio atractivo y de alta calidad":
        "Convierte ideas en episodios de podcasts, generando contenido de audio atractivo y de alta calidad",
      "Optimiza el contenido para motores de búsqueda, mejorando la visibilidad y el posicionamiento en línea":
        "Optimiza el contenido para motores de búsqueda, mejorando la visibilidad y el posicionamiento en línea",
      "¡Imagen copiada al portapapeles!": "¡Imagen copiada al portapapeles!",
      Resolution: "Resolución",
      "Fecha de creación": "Fecha de creación",
      "Ver menos": "Ver menos",
      "Ver más": "Ver más",
      "Aspect ratio": "Relación de aspecto",
      "mejora tu mensaje inicial para aumentar la variedad y riqueza de las imágenes":
        "mejora tu mensaje inicial para aumentar la variedad y riqueza de las imágenes",
      "Magic Prompt": "Prompt Mágico",
      Anime: "Anime",
      Diseño: "Diseño",
      Realista: "Realista",
      "Describe lo que quieres ver": "Describe lo que quieres ver",
      General: "General",
      Académico: "Académico",
      Negocio: "Negocio",
      Creativo: "Creativo",
      Web: "Web",
      "Tipo de documento": "Tipo de documento",
      Corrector: "Corrector",
      Fluido: "Fluido",
      Parafrasear: "Parafrasear",
      Modos: "Modos",
      "Profesional - formal": "Profesional",
      "Profesional - amigable": "Amigable",
      Chat: "Agentes",
      Corporativo: "Corporativo",
      Parafraseador: "Parafraseador",
      "Video a texto": "Video a texto",
      "Imagen a texto": "Imagen a texto",
      Imagen: "Imagen",
      "Trabajando en ello": "Trabajando en ello",
      "¡Bienvenido!": "¡Bienvenido!",
      "Ha ocurrido un error": "Ha ocurrido un error",
      "Error eliminando algunos archivos": "Error eliminando algunos archivos",
      "Eliminados exitosamente": "Eliminados exitosamente",
      "¡Copiado!": "¡Copiado!",
      "¡Transcripción realizada!": "¡Transcripción realizada!",
      "Error tratando de enviar el audio": "Error tratando de enviar el audio",
      "Ocurrió un error. Por favor, intenta de nuevo":
        "Ocurrió un error. Por favor, intenta de nuevo",
      "El token ha expirado": "El token ha expirado",
      "¡Opps! Algo ha salido mal": "¡Opps! Algo ha salido mal",
      "Error durante la traducción": "Error durante la traducción",
      "Incorrect username or password": "Credenciales incorrectas",
      "Nombre de usuario": "Nombre de usuario",
      Usuario: "Usuario",
      "Selecciona multiples archivos para descargar":
        "Selecciona multiples archivos para descargar",
      "La contraseña es obligatoria": "La contraseña es obligatoria",
      "Las contraseñas no coinciden": "Las contraseñas no coinciden",
      "El nombre de usuario es obligatorio":
        "El nombre de usuario es obligatorio",
      Contraseña: "Contraseña",
      Recuerdame: "Recuerdame",
      "Olvidó su contraseña": "¿Olvidó su contraseña?",
      "Eres nuevo": "¿Eres nuevo?",
      "Crea una cuenta": "Crea una cuenta",
      Traductor: "Traductor",
      Caracteres: "Caracteres",
      Traducir: "Traducir",
      Exportar: "Exportar",
      "Exportar Texto Traducido": "Exportar Texto",
      "Selecciona uno o más formatos de exportación":
        "Selecciona uno o más formatos de exportación",
      "Exportar como PDF": "Exportar como PDF",
      "Exportar como DOCX": "Exportar como DOCX",
      "Exportar como TXT": "Exportar como TXT",
      "Exportar como CSV": "Exportar como CSV",
      Descargar: "Descargar",
      "Idioma del audio": "Idioma del audio",
      "Pausar grabación": "Pausar grabación",
      "Seguir grabación": "Seguir grabación",
      Transcribir: "Transcribir",
      Buscar: "Buscar",
      Grabando: "Grabando",
      Grabar: "Grabar",
      Pausa: "Pausa",
      Español: "Español",
      Inicio: "Inicio",
      Audio: "Audio",
      Archivos: "Archivos",
      "Subir Audio": "Subir Audio",
      Subir: "Subir",
      Nombre: "Nombre",
      Fecha: "Fecha",
      Subido: "Subido",
      Duración: "Duración",
      "Audio a texto": "Audio a texto",
      Shortcuts: "Atajos",
      Recientes: "Recientes",
      "Sin categoria": "Sin categoria",
      Carpetas: "Carpetas",
      "Nueva carpeta": "Nueva carpeta",
      Texto: "Texto",
      welcome: "Bienvenido",
      Login: "Iniciar sesión",
      dashboard: "Tablero",
      Resumidor: "Resumidor",
      Resumir: "Resumir",
      Corto: "Corto",
      Largo: "Largo",
      issue: "problema",
      Spelling: "Ortografía",
      Plagio: "Plagio",
      Escanear: "Escanear",
      "Detector de IA": "Detector de IA",
      Detectar: "Detectar",
      "El cambio excede el límite de palabras":
        "El cambio excede el límite de palabras",
      "del texto es probablemente generado por IA":
        "Del texto es probablemente generado por IA",
      Ortográfico: "Ortográfico",
      Claridad: "Claridad",
      Idioma: "Idioma",
      "Se corrige el error ortográfico para mejorar la calidad del texto.":
        "Se corrige el error ortográfico para mejorar la calidad del texto.",
      Light: "Ligero",
      Standard: "Estándar",
      Intensive: "Intensivo",
      Concise: "Conciso",
      "Removes all grammar, spelling, and punctuation errors":
        "Elimina todos los errores gramaticales, ortográficos y de puntuación",
      "Improves vocabulary and removes all grammar, spelling, and punctuation errors":
        "Mejora el vocabulario y elimina todos los errores gramaticales, ortográficos y de puntuación",
      "Elevates flow, improves vocabulary, and removes all grammar, spelling, and punctuation errors":
        "Eleva la fluidez, mejora el vocabulario y elimina todos los errores gramaticales, ortográficos y de puntuación",
      "Condenses text, improves clarity, and removes all grammar, spelling, and punctuation errors":
        "Condensa el texto, mejora la claridad y elimina todos los errores gramaticales, ortográficos y de puntuación",
      "No se detecta ningún texto": "No se detecta ningún texto",
      'Ingrese su texto y haga clic en "Corrector" para ver la revisión':
        'Ingrese su texto y haga clic en "Corrector" para ver la revisión',
      "Tu texto es claro y está libre de errores":
        "Tu texto es claro y está libre de errores",
      "Crear imágenes": "Crear imágenes",
      "Texto a imagen": "Texto a imagen",
      "Asistente de IA para comunicación interna y soporte corporativo eficiente y personalizado":
        "Asistente de IA para comunicación interna y soporte corporativo eficiente y personalizado",
      "Transcribe audios a texto automáticamente, facilitando la accesibilidad y el análisis de contenido hablado":
        "Transcribe audios a texto automáticamente, facilitando la accesibilidad y el análisis de contenido hablado",
      "Genera imágenes solo con mediante el uso de texto":
        "Genera imágenes solo con mediante el uso de texto",
      "Mejora la gramática y estilo de tus textos con un corrector ortográfico impulsado por IA":
        "Mejora la gramática y estilo de tus textos con un corrector ortográfico impulsado por IA",
      "Identifica textos generados por inteligencia artificial para garantizar la autenticidad del contenido":
        "Identifica textos generados por inteligencia artificial para garantizar la autenticidad del contenido",
      "Reformula textos manteniendo el significado original, ideal para evitar plagio y mejorar redacción":
        "Reformula textos manteniendo el significado original, ideal para evitar plagio y mejorar redacción",
      "Traduce textos entre múltiples idiomas de manera precisa utilizando inteligencia artificial avanzada":
        "Traduce textos entre múltiples idiomas de manera precisa utilizando inteligencia artificial avanzada",
      "Extrae texto de videos automáticamente, ideal para subtitulado y análisis de contenido audiovisual":
        "Extrae texto de videos automáticamente, ideal para subtitulado y análisis de contenido audiovisual",
      "Verifica la originalidad de tus textos detectando posibles casos de plagio en múltiples fuentes":
        "Verifica la originalidad de tus textos detectando posibles casos de plagio en múltiples fuentes",
      "Condensa textos largos en resúmenes claros y concisos sin perder la información clave":
        "Condensa textos largos en resúmenes claros y concisos sin perder la información clave",
      Afrikaans: "Afrikáans",
      Albanian: "Albanés",
      Amharic: "Amárico",
      Arabic: "Árabe",
      Armenian: "Armenio",
      Azerbaijani: "Azerí",
      Basque: "Vasco",
      Belarusian: "Bielorruso",
      Bengali: "Bengalí",
      Bosnian: "Bosnio",
      Bulgarian: "Búlgaro",
      Catalan: "Catalán",
      Cebuano: "Cebuano",
      Chichewa: "Chichewa",
      "Chinese (Simplified)": "Chino (Simplificado)",
      "Chinese (Traditional)": "Chino (Tradicional)",
      Corsican: "Corso",
      Croatian: "Croata",
      Czech: "Checo",
      Danish: "Danés",
      Dutch: "Neerlandés",
      English: "Inglés",
      Esperanto: "Esperanto",
      Estonian: "Estonio",
      Filipino: "Filipino",
      Finnish: "Finés",
      French: "Francés",
      Frisian: "Frisón",
      Galician: "Gallego",
      Georgian: "Georgiano",
      German: "Alemán",
      Greek: "Griego",
      Gujarati: "Guyaratí",
      "Haitian Creole": "Criollo haitiano",
      Hausa: "Hausa",
      Hawaiian: "Hawaiano",
      Hindi: "Hindi",
      Hmong: "Hmong",
      Hungarian: "Húngaro",
      Icelandic: "Islandés",
      Igbo: "Igbo",
      Indonesian: "Indonesio",
      Irish: "Irlandés",
      Italian: "Italiano",
      Japanese: "Japonés",
      Javanese: "Javanés",
      Kannada: "Canarés",
      Kazakh: "Kazajo",
      Khmer: "Jemer",
      Kinyarwanda: "Kinyarwanda",
      Korean: "Coreano",
      "Kurdish (Kurmanji)": "Kurdo, (Kurmanji)",
      Kyrgyz: "Kirguís",
      Lao: "Laosiano",
      Latin: "Latín",
      Latvian: "Letón",
      Lithuanian: "Lituano",
      Luxembourgish: "Luxemburgués",
      Macedonian: "Macedonio",
      Malagasy: "Malgache",
      Malay: "Malayo",
      Malayalam: "Malabar",
      Maltese: "Maltés",
      Maori: "Maorí",
      Marathi: "Maratí",
      Mongolian: "Mongol",
      "Myanmar (Burmese)": "Birmano",
      Nepali: "Nepalí",
      Norwegian: "Noruego",
      "Odia (Oriya)": "Odia (Oriya)",
      Pashto: "Pastún",
      Persian: "Persa",
      Polish: "Polaco",
      Portuguese: "Portugués",
      Punjabi: "Panyabí",
      Romanian: "Rumano",
      Russian: "Ruso",
      Samoan: "Samoano",
      "Scots Gaelic": "Gaélico escocés",
      Serbian: "Serbio",
      Sesotho: "Sesotho",
      Shona: "Shona",
      Sindhi: "Sindhi",
      Sinhala: "Cingalés",
      Slovak: "Eslovaco",
      Slovenian: "Esloveno",
      Somali: "Somalí",
      Spanish: "Español",
      Sundanese: "Sudanés",
      Swahili: "Suajili",
      Swedish: "Sueco",
      Tajik: "Tayiko",
      Tamil: "Tamil",
      Tatar: "Tártaro",
      Telugu: "Telugu",
      Thai: "Tailandés",
      Turkish: "Turco",
      Turkmen: "Turcomano",
      Ukrainian: "Ucraniano",
      Urdu: "Urdu",
      Uyghur: "Uigur",
      Uzbek: "Uzbeko",
      Vietnamese: "Vietnamita",
      Welsh: "Galés",
      Xhosa: "Xhosa",
      Yiddish: "Yidis",
      Yoruba: "Yoruba",
      Zulu: "Zulú",
      Hebrew: "Hebreo",
      "Bullet points": "Puntos de viñeta",
      Markdown: "Markdown",
      Paragraph: "Párrafo",
      Title: "Título",
      Generar: "Generar",
    },
  },
  ca: {
    translation: {
      "Anterior cambio": "Anterior canvi",
      "Aceptar cambio actual": "Acceptar canvi actual",
      "Aceptar todos los cambios": "Acceptar tots els canvis",
      "Ignorar todos los cambios": "Ignorar tots els canvis",
      "Siguiente cambio": "Següent canvi",
      Hoy: "Avui",
      "7 días anteriores": "7 dies anteriors",
      "30 días anteriores": "30 dies anteriors",
      "Meses anteriores": "Mesos anteriors",
      Enero: "Gener",
      Febrero: "Febrer",
      Marzo: "Març",
      Abril: "Abril",
      Mayo: "Maig",
      Junio: "Juny",
      Julio: "Juliol",
      Agosto: "Agost",
      Septiembre: "Setembre",
      Octubre: "Octubre",
      Noviembre: "Novembre",
      Diciembre: "Desembre",
      Copiar: "Copiar",
      "Generando código": "Generant codi",
      "Genera tu código fácilmente": "Genera el teu codi fàcilment",
      "Especifique qué tipo de función o fragmento de código desea generar":
        "Especifiqueu quin tipus de funció o fragment de codi desitja generar",
      "Proporcionar instrucciones": "Proporcionar instruccions",
      "Lenguaje de Programación": "Llenguatge de Programació",
      "Desarrollar código en varios lenguajes de programación a partir de un texto":
        "Desenvolupar codi en diversos llenguatges de programació a partir d'un text",
      "Generar código con IA": "Generar codi amb IA",
      "¡Chat eliminado!": "Xat eliminat!",
      "Cargando mensajes": "Carregant missatges",
      "Ingrese nuevo nombre": "Introdueix un nou nom",
      "Asistente IA": "Assistent IA",
      "Nueva conversación": "Nova conversa",
      "Cambiar el nombre del título del chat":
        "Canvia el nom del títol del xat",
      "Este campo es requerido": "Aquest camp és obligatori",
      "Confirmar eliminación de chat": "Confirma l'eliminació del xat",
      "Eliminará permanentemente este historial de chat":
        "Això eliminarà permanentment aquest historial de xat",
      Enviar: "Enviar",
      "Escribe tu mensaje aquí...": "Escriu el teu missatge aquí...",
      Confirmar: "Confirmar",
      Cancelar: "Cancel·lar",
      Asistente: "Assistent",
      "Generando video. Se le informará por una notificación cuando termine":
        "Generant vídeo. Se l'informarà mitjançant una notificació quan acabi",
      Generando: "Generant",
      Acciones: "Accions",
      "No se ha generado ningún video": "No s’ha generat cap vídeo",
      "Procesando video": "Processant vídeo",
      "Lenguaje a traducir": "Llenguatge a traduir",
      "Lenguaje original": "Llenguatge original",
      "Subir video": "Pujar vídeo",
      "Generador de subtitulos": "Generador de subtítols",
      Subtítulos: "Subtítols",
      "Generando imagen...": "Generant imatge...",
      Código: "Codi",
      "Generador de código": "Generador de codi",
      "Genera código automáticamente en varios lenguajes de programación, acelerando el desarrollo y optimizando tareas repetitivas":
        "Genera codi automàticament en diversos llenguatges de programació, accelerant el desenvolupament i optimitzant tasques repetitives",
      "Generador de música": "Generador de música",
      "Crea pistas musicales originales a partir de entradas personalizadas, perfectas para proyectos creativos y multimedia":
        "Crea pistes musicals originals a partir d'entrades personalitzades, perfectes per a projectes creatius i multimèdia",
      "Convierte ideas en episodios de podcasts, generando contenido de audio atractivo y de alta calidad":
        "Converteix idees en episodis de podcasts, generant contingut d'àudio atractiu i d'alta qualitat",
      "Optimiza el contenido para motores de búsqueda, mejorando la visibilidad y el posicionamiento en línea":
        "Optimitza el contingut per a motors de cerca, millorant la visibilitat i el posicionament en línia",
      "¡Imagen copiada al portapapeles!": "Imatge copiada al porta-retalls!",
      Resolution: "Resolució",
      "Fecha de creación": "Data de creació",
      "Ver menos": "Veure menys",
      "Ver más": "Veure més",
      "Aspect ratio": "Relació d'aspecte",
      "mejora tu mensaje inicial para aumentar la variedad y riqueza de las imágenes":
        "Millora el teu missatge inicial per augmentar la varietat i la riquesa de les imatges",
      "Magic Prompt": "Prompt Màgic",
      Anime: "Anime",
      Diseño: "Disseny",
      Realista: "Realista",
      "Describe lo que quieres ver": "Descriu el que vols veure",
      "Imagen a texto": "Imatge a text",
      General: "General",
      Académico: "Acadèmic",
      Negocio: "Negoci",
      Creativo: "Creatiu",
      Web: "Web",
      "Tipo de documento": "Tipus de document",
      Corrector: "Corrector",
      Parafrasear: "Parafrasejar",
      Fluido: "Fluid",
      Modos: "Modes",
      "Profesional - formal": "Professional",
      "Profesional - amigable": "Amigable",
      Chat: "Agents",
      Corporativo: "Corporatiu",
      Parafraseador: "Parafrasejador",
      "Video a texto": "Vídeo a text",
      Imagen: "Imatge",
      "Trabajando en ello": "Treballant-hi",
      Video: "Vídeo",
      "¡Bienvenido!": "Benvingut!",
      "Ha ocurrido un error": "S'ha produït un error",
      "Error eliminando algunos archivos": "Error en eliminar alguns fitxers",
      "Eliminados exitosamente": "Eliminats amb èxit",
      "¡Copiado!": "Copiat!",
      "¡Transcripción realizada!": "Transcripció realitzada!",
      "Error tratando de enviar el audio": "Error en intentar enviar l'àudio",
      "Ocurrió un error. Por favor, intenta de nuevo":
        "S'ha produït un error. Si us plau, intenta-ho de nou",
      "El token ha expirado": "El token ha expirat",
      "¡Opps! Algo ha salido mal": "Opps! Alguna cosa ha anat malament",
      "Error durante la traducción": "Error durant la traducció",
      "Incorrect username or password": "Credencials incorrectes",
      "La contraseña es obligatoria": "La contrasenya és obligatòria",
      "Las contraseñas no coinciden": "Les contrasenyes no coincideixen",
      "El nombre de usuario es obligatorio": "El nom d'usuari és obligatori",
      "Nombre de usuario": "Nom d'usuari",
      Usuario: "Usuari",
      Contraseña: "Contrasenya",
      Recuerdame: "Recorda'm",
      "Olvidó su contraseña": "Has oblidat la teva contrasenya?",
      "Eres nuevo": "Ets nou?",
      "Crea una cuenta": "Crea un compte",
      Traductor: "Traductor",
      Caracteres: "Caràcters",
      Traducir: "Traduir",
      Exportar: "Exportar",
      "Exportar Texto Traducido": "Exportar Text",
      "Selecciona uno o más formatos de exportación":
        "Selecciona un o més formats d'exportació",
      "Exportar como PDF": "Exportar com a PDF",
      "Exportar como DOCX": "Exportar com a DOCX",
      "Exportar como TXT": "Exportar com a TXT",
      "Exportar como CSV": "Exportar com a CSV",
      Descargar: "Descarregar",
      "Idioma del audio": "Idioma de l'àudio",
      "Pausar grabación": "Pausar gravació",
      "Seguir grabación": "Continuar gravació",
      Transcribir: "Transcriure",
      Buscar: "Cercar",
      Grabando: "Gravant",
      Grabar: "Gravar",
      Pausa: "Pausa",
      Español: "Espanyol",
      Inicio: "Inici",
      Audio: "Àudio",
      Archivos: "Arxius",
      "Subir Audio": "Pujar Àudio",
      Subir: "Pujar",
      Nombre: "Nom",
      Fecha: "Data",
      Subido: "Pujat",
      Duración: "Durada",
      "Audio a texto": "Àudio a text",
      Shortcuts: "Dreceres",
      Recientes: "Recents",
      "Sin categoria": "Sense categoria",
      Carpetas: "Carpetes",
      "Nueva carpeta": "Nova carpeta",
      Texto: "Text",
      welcome: "Benvingut",
      Login: "Iniciar sessió",
      dashboard: "Tauler",
      "Selecciona multiples archivos para descargar":
        "Selecciona múltiples arxius per descarregar",
      Resumidor: "Resumidor",
      Resumir: "Resumir",
      Corto: "Curt",
      Largo: "Llarg",
      issue: "qüestió",
      Spelling: "Ortografia",
      Plagio: "Plagi",
      Escanear: "Escanejar",
      "Detector de IA": "Detector d'IA",
      Detectar: "Detectar",
      "El cambio excede el límite de palabras":
        "El canvi excedeix el límit de paraules",
      "del texto es probablemente generado por IA":
        "Del text és probablement generat per IA",
      Ortográfico: "Ortogràfic",
      Claridad: "Claredat",
      Idioma: "Idioma",
      "Se corrige el error ortográfico para mejorar la calidad del texto.":
        "Es corregeix l'error ortogràfic per millorar la qualitat del text.",
      Light: "Lleugera",
      Standard: "Estàndard",
      Intensive: "Intensiu",
      Concise: "Concís",
      "Removes all grammar, spelling, and punctuation errors":
        "Elimina tota la gramàtica, l'ortografia, i errors de puntuació",
      "Improves vocabulary and removes all grammar, spelling, and punctuation errors":
        "Millora el vocabulari i elimina tots els errors gramaticals, ortogràfics i de puntuació",
      "Elevates flow, improves vocabulary, and removes all grammar, spelling, and punctuation errors":
        "Eleva el flux, millora el vocabulari i elimina tots els errors de gramàtica, ortografia i puntuació",
      "Condenses text, improves clarity, and removes all grammar, spelling, and punctuation errors":
        "Condensa el text, millora la claredat i elimina tots els errors gramaticals, ortogràfics i de puntuació",
      "No se detecta ningún texto": "No se detecta cap text",
      'Ingrese su texto y haga clic en "Corrector" para ver la revisión':
        'Introduïu el vostre text i feu clic a "Corrector" per veure la revisió',
      "Tu texto es claro y está libre de errores":
        "El teu text és clar i lliure d'errors",
      "Crear imágenes": "Crear imatges",
      "Texto a imagen": "Text a imatge",
      "Asistente de IA para comunicación interna y soporte corporativo eficiente y personalizado":
        "Asistent d'IA per a la comunicació interna i suport corporatiu eficient i personalitzat",
      "Transcribe audios a texto automáticamente, facilitando la accesibilidad y el análisis de contenido hablado":
        "Transcriu àudios a text automàticament, facilitant l'accessibilitat i l'anàlisi de contingut parlat",
      "Genera imágenes solo con mediante el uso de texto":
        "Genera imatges només mitjançant l'ús de text",
      "Mejora la gramática y estilo de tus textos con un corrector ortográfico impulsado por IA":
        "Millora la gramàtica i l'estil dels teus textos amb un corrector ortogràfic impulsat per IA",
      "Identifica textos generados por inteligencia artificial para garantizar la autenticidad del contenido":
        "Identifica textos generats per intel·ligència artificial per garantir l'autenticitat del contingut",
      "Reformula textos manteniendo el significado original, ideal para evitar plagio y mejorar redacción":
        "Reformula textos mantenint el significat original, ideal per evitar plagi i millorar la redacció",
      "Traduce textos entre múltiples idiomas de manera precisa utilizando inteligencia artificial avanzada":
        "Traduïx textos entre múltiples idiomes de manera precisa utilitzant intel·ligència artificial avançada",
      "Extrae texto de videos automáticamente, ideal para subtitulado y análisis de contenido audiovisual":
        "Extreu text de vídeos automàticament, ideal per a subtitulat i anàlisi de contingut audiovisual",
      "Verifica la originalidad de tus textos detectando posibles casos de plagio en múltiples fuentes":
        "Verifica l'originalitat dels teus texts detectant possibles casos de plagi en múltiples fonts",
      "Condensa textos largos en resúmenes claros y concisos sin perder la información clave":
        "Condensa textos llargs en resums clars i concisos sense perdre la informació clau",
      Afrikaans: "Afrikáans",
      Albanian: "Albanès",
      Amharic: "Amhàric",
      Arabic: "Àrab",
      Armenian: "Armeni",
      Azerbaijani: "Azerbaidjanès",
      Basque: "Basc",
      Belarusian: "Bielorús",
      Bengali: "Bengalí",
      Bosnian: "Bosnià",
      Bulgarian: "Búlgar",
      Catalan: "Català",
      Cebuano: "Cebuà",
      Chichewa: "Chichewa",
      "Chinese (Simplified)": "Xinès (Simplificat)",
      "Chinese (Traditional)": "Xinès (Tradicional)",
      Corsican: "Cors",
      Croatian: "Croat",
      Czech: "Txec",
      Danish: "Danès",
      Dutch: "Neerlandès",
      English: "Anglès",
      Esperanto: "Esperanto",
      Estonian: "Estonià",
      Filipino: "Filipí",
      Finnish: "Finès",
      French: "Francès",
      Frisian: "Frisó",
      Galician: "Gallec",
      Georgian: "Georgià",
      German: "Alemany",
      Greek: "Grec",
      Gujarati: "Gujarati",
      "Haitian Creole": "Crioll haitià",
      Hausa: "Hausa",
      Hawaiian: "Hawaïà",
      Hindi: "Hindi",
      Hmong: "Hmong",
      Hungarian: "Hongarès",
      Icelandic: "Islandès",
      Igbo: "Igbo",
      Indonesian: "Indonesi",
      Irish: "Irlandès",
      Italian: "Italià",
      Japanese: "Japonès",
      Javanese: "Javanès",
      Kannada: "Kannada",
      Kazakh: "Kazakh",
      Khmer: "Khmer",
      Kinyarwanda: "Kinyarwanda",
      Korean: "Coreà",
      "Kurdish (Kurmanji)": "Kurd (Kurmanji)",
      Kyrgyz: "Kirguís",
      Lao: "Laosià",
      Latin: "Llatí",
      Latvian: "Letó",
      Lithuanian: "Lituà",
      Luxembourgish: "Luxemburguès",
      Macedonian: "Macedoni",
      Malagasy: "Malgache",
      Malay: "Malai",
      Malayalam: "Malaiàlam",
      Maltese: "Maltès",
      Maori: "Maorí",
      Marathi: "Marathi",
      Mongolian: "Mongol",
      "Myanmar (Burmese)": "Birmà",
      Nepali: "Nepalès",
      Norwegian: "Noruec",
      "Odia (Oriya)": "Odia (Oriya)",
      Pashto: "Paxtó",
      Persian: "Persa",
      Polish: "Polonès",
      Portuguese: "Portuguès",
      Punjabi: "Panjabi",
      Romanian: "Romanès",
      Russian: "Rus",
      Samoan: "Samoà",
      "Scots Gaelic": "Gaèlic escocès",
      Serbian: "Serbi",
      Sesotho: "Sesotho",
      Shona: "Shona",
      Sindhi: "Sindhi",
      Sinhala: "Singalès",
      Slovak: "Eslovac",
      Slovenian: "Eslovè",
      Somali: "Somali",
      Spanish: "Espanyol",
      Sundanese: "Sundanès",
      Swahili: "Swahili",
      Swedish: "Suec",
      Tajik: "Tadjik",
      Tamil: "Tàmil",
      Tatar: "Tàtar",
      Telugu: "Telugu",
      Thai: "Tailandès",
      Turkish: "Turc",
      Turkmen: "Turcman",
      Ukrainian: "Ucraïnès",
      Urdu: "Urdú",
      Uyghur: "Uigur",
      Uzbek: "Uzbek",
      Vietnamese: "Vietnamita",
      Welsh: "Gal·lès",
      Xhosa: "Xhosa",
      Yiddish: "Jiddisch",
      Yoruba: "Ioruba",
      Zulu: "Zulú",
      Hebrew: "Hebreu",
      "Bullet points": "Punts de viñeta",
      Markdown: "Markdown",
      Paragraph: "Paràgraf",
      Title: "Títol",
      Generar: "Generar",
    },
  },
};

export default resourcesLangs;
