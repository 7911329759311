import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import transcribeService from "../../../services/transcribeService";
import {
  extractDateFromFilename,
  extractNameFromFilename,
} from "../../../utils/extractFileNames";
import CustomPlyr from "../../ui/CustomPlyr";

const AudioTranscibed = () => {
  const [currentTranscribe, setCurrentTranscribe] = useState(null);
  const { transcribeId } = useParams();
  const sourceAudio = `https://tools.macaqueconsulting.com/v1/recorder/get-audio/${transcribeId}`;

  const audioOptions = {
    displayDuration: false,
  };
  const getAllTranscribes = async () => {
    const data = await transcribeService.getAllTranscribes();

    const transcribe = data.filter((el) => el.id === Number(transcribeId))[0];
    setCurrentTranscribe(transcribe);
  };
  useEffect(() => {
    getAllTranscribes();
  }, []);
  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-4 mb-24">
      <div className="flex flex-col space-y-6 w-full h-full bg-white shadow-lg rounded-lg overflow-y-auto ">
        {currentTranscribe ? (
          <div className="flex flex-col justify-between p-6">
            <h1 className="text-3xl text-gray-500 font-bold ">
              {extractNameFromFilename(currentTranscribe.filename)}
            </h1>
            <p className="text-lg my-4 text-gray-500">
              {extractDateFromFilename(currentTranscribe.filename)}
            </p>
            <p>{currentTranscribe.transcription}</p>
          </div>
        ) : (
          <h1>Cargando...</h1>
        )}
      </div>
      {currentTranscribe && (
        <div className="fixed bg-white bottom-0 left-0 w-full z-40">
          <div className="max-w-screen-md pr-1 pl-1 mr-auto ml-auto pt-2">
            <span className="block text-center w-full text-base font-bold">
              {currentTranscribe.filename}
            </span>

            <CustomPlyr source={sourceAudio} options={audioOptions} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioTranscibed;
