import { t } from "i18next";
import { useState, useEffect, useRef } from "react";
import { BsCalendarDate } from "react-icons/bs";
import { MdOutlineContentCopy } from "react-icons/md";
import { SlSizeFullscreen } from "react-icons/sl";
import useLanguageStore from "../../../store/langStore";
import copyClipboard from "../../../utils/copyClipboard";

const CardInfoModal = ({ image }) => {
  const [showFullMagicPrompt, setShowFullMagicPrompt] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [isTextClamped, setIsTextClamped] = useState(false);
  const [isMagicPromptClamped, setIsMagicPromptClamped] = useState(false);

  const textRef = useRef(null);
  const magicPromptRef = useRef(null);
  const { language } = useLanguageStore();

  useEffect(() => {
    if (textRef.current) {
      setIsTextClamped(
        textRef.current.scrollHeight > textRef.current.clientHeight,
      );
    }
    if (magicPromptRef.current) {
      setIsMagicPromptClamped(
        magicPromptRef.current.scrollHeight >
          magicPromptRef.current.clientHeight,
      );
    }
  }, [image]);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const toggleShowFullMagicPrompt = () => {
    setShowFullMagicPrompt(!showFullMagicPrompt);
  };

  return (
    <div className="flex flex-col justify-between flex-1 min-w-[450px] max-w-[450px] shrink-0 bg-gray-200 rounded-xl py-4 px-6 text-gray-600 h-full overflow-auto relative">
      <div>
        <div className="border-b-[1px] border-gray-300 space-y-2 pb-4">
          <div className="flex justify-between">
            <p className="font-bold capitalize ">Prompt</p>
            <MdOutlineContentCopy
              size={24}
              onClick={() => copyClipboard(image.prompt)}
              className={`cursor-pointer `}
            />
          </div>
          <p
            ref={textRef}
            className={`${
              !showFullText ? "line-clamp-5" : ""
            } text-ellipsis overflow-hidden`}
          >
            {image.prompt}
          </p>
          {isTextClamped && (
            <div className="w-full flex justify-end">
              <button
                onClick={toggleShowFullText}
                className="text-gray-600 font-bold hover:underline mt-2"
              >
                {showFullText ? t("Ver menos") : t("Ver más")}
              </button>
            </div>
          )}
        </div>

        <div className="border-b-[1px] border-gray-300 space-y-2 py-4">
          <div className="flex justify-between">
            <p className="font-bold capitalize ">{t("Magic Prompt")}</p>
            <MdOutlineContentCopy
              size={24}
              onClick={() => copyClipboard(image.magicPrompt)}
              className={`cursor-pointer `}
            />
          </div>
          <p
            ref={magicPromptRef}
            className={`${
              !showFullMagicPrompt ? "line-clamp-5" : ""
            } text-ellipsis overflow-hidden`}
          >
            {image.magicPrompt}
          </p>
          {isMagicPromptClamped && (
            <div className="w-full flex justify-end">
              <button
                onClick={toggleShowFullMagicPrompt}
                className="text-gray-600 font-bold hover:underline mt-2"
              >
                {showFullMagicPrompt ? t("Ver menos") : t("Ver más")}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between gap-2 bottom-5 left-6 right-6 ">
        <div className="flex gap-2 items-center ">
          <SlSizeFullscreen />
          <p className="">
            {image.width} x {image.height}
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <BsCalendarDate />
          <p className="">
            {new Date(image.date * 1000).toLocaleDateString(language, {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardInfoModal;
