import { toast } from "react-toastify";
import authService from "./authService";
import { t } from "i18next";
import BASE_URL from "../config/api";

class SummarizerService {
  constructor() {
    this.apiurl = BASE_URL;
  }

  async summarize(text, lang, mode, doctype, length) {
    const apiUrl = this.apiurl + "/v1/novastudio/text-model-inference";
    const apiKey = authService.getToken();
    const lengthType = {
      1: "corto",
      2: "medio",
      3: "largo",
    };
    const requestBody = {
      input_text: `Texto: ${text} Idioma: ${lang} Modo: ${mode} Tipo de documento: ${doctype} longitud: ${lengthType[length]}`,
      template: "summarizer_template",
      temperature: 0.2,
      model: "gpt-4o-mini",
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 401) {
        toast.error(t("El token ha expirado"));
        authService.handleUnauthorized();
        return null;
      }

      const data = await response.json();

      return data.data.response || t("¡Opps! Algo ha salido mal");
    } catch (error) {
      toast.error(t("¡Opps! Algo ha salido mal"));
      return t("¡Opps! Algo ha salido mal");
    }
  }
}

const summarizerService = new SummarizerService();

export default summarizerService;
