import { create } from "zustand";

const useTranscribeStore = create((set) => ({
  text: "",
  loading: false,
  listTranscribes: [],
  selectedIds: new Set(),

  setSelectedIds: (newselectedIds) => set({ selectedIds: newselectedIds }),
  setText: (newText) => set({ text: newText }),
  setLoading: (isLoading) => set({ loading: isLoading }),
  setListtranscribes: (transcribes) => set({ listTranscribes: transcribes }),
}));

export default useTranscribeStore;
