import useMusicStore from "../../../store/musicStore";
import DurationCard from "./optionsCards/DurationCard";
import EnergyCard from "./optionsCards/EnergyCard";
import GenreCard from "./optionsCards/GenreCard";
import InstrumentsCard from "./optionsCards/InstrumentsCard";
import KeyCard from "./optionsCards/KeyCard";
import StructureCard from "./optionsCards/StructureCard";
import TempoCard from "./optionsCards/TempoCard";

const OptionsAvailables = () => {
  const { selectedOptions } = useMusicStore();
  return (
    <div className="selected h-full flex flex-col gap-2 relative py-0">
      <GenreCard />
      <DurationCard
        className={`${selectedOptions.includes("duracion") ? "flex scale-y-100 opacity-100 transition-all duration-300 transform origin-top" : "absolute h-0 scale-y-0 opacity-0 "} `}
      />
      <InstrumentsCard
        className={`${selectedOptions.includes("instrumentos") ? "flex scale-y-100 opacity-100 transition-all duration-300 transform origin-top" : "absolute h-0 scale-y-0 opacity-0 "} `}
      />
      <EnergyCard
        className={`${selectedOptions.includes("energia") ? "flex scale-y-100 opacity-100 transition-all duration-300 transform origin-top" : "absolute h-0 scale-y-0 opacity-0 "} `}
      />
      <StructureCard
        className={`${selectedOptions.includes("estructura") ? "flex scale-y-100 opacity-100 transition-all duration-300 transform origin-top" : "absolute h-0 scale-y-0 opacity-0 "} `}
      />
      <TempoCard
        className={`${selectedOptions.includes("tempo") ? "flex scale-y-100 opacity-100 transition-all duration-300 transform origin-top" : "absolute h-0 scale-y-0 opacity-0 "} `}
      />
      <KeyCard
        className={`${selectedOptions.includes("llave") ? "flex scale-y-100 opacity-100 transition-all duration-300 transform origin-top" : "absolute h-0 scale-y-0 opacity-0 "} `}
      />
    </div>
  );
};

export default OptionsAvailables;
