import { ScrollArea } from "@radix-ui/themes";
import CardHome from "../components/generic/CardHome";
import menuConfig from "../config/menuConfig";

const Dashboard = () => {
  return (
    <ScrollArea type="hover" scrollbars="vertical" style={{ height: "100%" }}>
      <section className="h-full text-gray-600 body-font py-4">
        <div className="container">
          <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-4 gap-6">
            {menuConfig.map((menuItem) => {
              if (menuItem.submenu) {
                return menuItem.submenu.map((submenuItem) => (
                  <CardHome
                    key={submenuItem.title}
                    title={submenuItem.title}
                    Icon={submenuItem.icon}
                    route={submenuItem.route}
                    category={menuItem.title}
                    description={submenuItem.description}
                  />
                ));
              }
              return null;
            })}
          </div>
        </div>
      </section>
    </ScrollArea>
  );
};

export default Dashboard;
