import React, { useEffect, useRef, useState } from "react";
import InputButton from "../../../components/dashboard/textToImage/InputButton";
import PromptContainer from "../../../components/dashboard/textToImage/PromptContainer";
import Gallery from "../../../components/dashboard/textToImage/Gallery";
import useImageStore from "../../../store/imageStore";

const TextToImage = () => {
  const [isOpenPrompt, setIsOpenPrompt] = useState(false);
  const [prompt, setPrompt] = useState("");

  const { images } = useImageStore();

  const containerRef = useRef(null);

  const handleOpenPrompt = (e) => {
    e.stopPropagation();
    setIsOpenPrompt(!isOpenPrompt);
  };
  const handlePrompt = (value) => {
    setPrompt(value);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpenPrompt(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} className="h-full relative flex flex-col">
      <InputButton
        onOpenPrompt={handleOpenPrompt}
        prompt={prompt}
        isOpenPrompt={isOpenPrompt}
      />
      {isOpenPrompt && (
        <PromptContainer
          onClose={handleOpenPrompt}
          prompt={prompt}
          onPrompt={handlePrompt}
        />
      )}
      <Gallery images={images} />
    </div>
  );
};

export default TextToImage;
