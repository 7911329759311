import { Separator } from "@radix-ui/themes";
import { t } from "i18next";
import MusicResultSide from "../../components/dashboard/musicGenerator/MusicResultSide";
import OptionsSide from "../../components/dashboard/musicGenerator/OptionsSide";
import LayoutDashboard from "./LayoutDashboard";

const MusicGenerator = () => {
  return (
    <LayoutDashboard title={t("Generador de música")}>
      <div className="p-6 flex h-full">
        <OptionsSide className={"w-[480px] h-full flex flex-col"} />
        <Separator orientation={"vertical"} size="4" mx={"3"} />
        <MusicResultSide className={"flex-grow h-full"} />
      </div>
    </LayoutDashboard>
  );
};

export default MusicGenerator;
