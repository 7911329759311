import { Dialog, Skeleton } from "@radix-ui/themes";
import { Button } from "@radix-ui/themes/dist/cjs/index.js";
import { t } from "i18next";
import { useEffect, useState } from "react";
import imageService from "../../../services/ImageService";
import { BsDownload } from "react-icons/bs";
import { MdOutlineContentCopy } from "react-icons/md";
import useImageStore from "../../../store/imageStore";
import copyImageToClipboard from "../../../utils/copyImage";
import { AiOutlineClose } from "react-icons/ai";

const ButtonGenerate = ({ className = "", onClick, config, prompt }) => {
  const [image, setImage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [progress, setProgress] = useState(0);

  const { addImage } = useImageStore();

  const handleClosePrompt = (e) => {
    e.stopPropagation();
  };

  const generateImage = async () => {
    setIsImageLoaded(false);
    setError("");
    setImage("");
    setIsLoading(true);
    try {
      const response = await imageService.generateImage(prompt, config);

      if (response) {
        setImage(response.data[0].url);
        addImage({
          url: response.data[0].url,
          prompt,
          magicPrompt: response.data[0].revised_prompt,
          date: response.created,
        });
      }
    } catch (error) {
      setError(t("Ha ocurrido un error"));
      console.log(error);
    } finally {
      setIsLoading(false);
      setProgress(100);
    }
  };
  const handleDownloadImage = async () => {
    if (!image) return;

    try {
      const response = await fetch(image);
      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = "generated-image.png";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  useEffect(() => {
    let progressInterval;
    if (isLoading) {
      setProgress(0);
      progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 99) {
            return prevProgress + 9;
          }
          return prevProgress;
        });
      }, 1000);
    }

    // Limpiar el intervalo al completar o al desmontar el componente
    return () => clearInterval(progressInterval);
  }, [isLoading]);

  return (
    <Dialog.Root>
      <Dialog.Trigger onClick={generateImage} disabled={isLoading}>
        <button
          disabled={isLoading || prompt.length === 0}
          className={
            className +
            `${
              !isLoading && prompt.length > 0
                ? " !bg-secondary text-primary font-semibold cursor-pointer"
                : " opacity-70 bg-gray-300 text-gray-500"
            }`
          }
          onClick={onClick}
        >
          {t("Generar")}
        </button>
      </Dialog.Trigger>
      <Dialog.Content
        onClick={handleClosePrompt}
        size="4"
        maxWidth={
          config.ratio === "1792x1024"
            ? "805px"
            : config.ratio === "1024x1024"
              ? "562px"
              : "350px"
        }
        maxHeight={config.ratio === "1792x1024" ? "550px" : "80vh"}
        className={`min-h-0 flex justify-center items-center relative md:h-[560px] `}
      >
        <Dialog.Close className="absolute top-2 right-1 z-20">
          <AiOutlineClose
            size={28}
            className="bg-red-500 opacity-50 hover:opacity-100 p-2 rounded transition-colors cursor-pointer "
          />
        </Dialog.Close>
        <div className={"flex justify-center h-full w-full"}>
          {isLoading ? (
            <div className="h-full flex flex-col justify-center items-center">
              <p>{t("Generando imagen...")}</p>
              <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                <div
                  className="bg-secondary h-4 rounded-full"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <p>{progress}%</p>
            </div>
          ) : error.length === 0 ? (
            <div
              className={
                "flex relative h-full " + (isImageLoaded ? "" : "w-full")
              }
            >
              <div className="flex relative flex-1 justify-center">
                <img
                  src={image}
                  alt={prompt}
                  className={` rounded-2xl  drop-shadow-lg transition-all duration-300 opacity-0 max-h-[550px] ${
                    isImageLoaded ? "opacity-100" : "w-full "
                  }   ${config.ratio === "1792x1024" ? " " : ""}`}
                  onLoad={() => setIsImageLoaded(true)}
                />
                <Skeleton
                  loading={!isImageLoaded}
                  className="flex flex-1 w-full h-full bg-gray-200 animate-pulse rounded-xl p-2 absolute"
                >
                  {" "}
                  {!isImageLoaded && (
                    <div className="flex-1 w-full h-[77vh] bg-gray-200 animate-pulse rounded-xl p-2">
                      Placeholder
                    </div>
                  )}
                </Skeleton>

                <div className="absolute bottom-4 w-full  flex space-x-4 mt-4 justify-center">
                  <button
                    onClick={() => copyImageToClipboard(image)}
                    className="flex justify-center items-center rounded text-gray-300 font-semibold cursor-pointer shadow-xl hover:bg-[rgba(0,0,0,0.9)]  transition-colors w-12 h-12 bg-[rgba(0,0,0,0.6)] border-2 border-white"
                  >
                    <MdOutlineContentCopy className="h-6 w-6" />
                  </button>
                  <button
                    size="icon"
                    onClick={handleDownloadImage}
                    className="flex justify-center items-center rounded text-gray-300 font-semibold cursor-pointer shadow-xl hover:bg-[rgba(0,0,0,0.9)]  transition-colors w-12 h-12 bg-[rgba(0,0,0,0.6)] border-2 border-white"
                  >
                    <BsDownload className="h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <p>{error}</p>
          )}
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ButtonGenerate;
