import React, { useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import ExportButton from "../../components/ui/ExportButton";
import cutParagraph from "../../utils/cutParagraph";
import Title from "./Title";
import { t } from "i18next";
import copyClipboard from "../../utils/copyClipboard";
import { maxCountSummarizer } from "../../config/maxCountWords";
import HeaderSummarizer from "../../components/dashboard/summarizer/HeaderSummarizer";
import documentType from "../../config/documentType";
import ActionSummarizer from "../../components/dashboard/summarizer/ActionSummarizer";
import modesSummarizer from "../../config/modesSummarizer";
import summarizerService from "../../services/summarizerService";

const Summarizer = () => {
  const [fromText, setFromText] = useState("");
  const [currentLang, setCurrentLang] = useState("Spanish");
  const [currentMode, setCurrentMode] = useState(modesSummarizer[0]);
  const [currentDocType, setCurrentDocumentType] = useState(documentType[0]);
  const [summarizedText, setSummarizedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countChar, setCountChar] = useState(0);
  const [currentLength, setCurrentLength] = useState(1);

  const handleDocChange = (value) => {
    setCurrentDocumentType(value);
  };

  const handleModeChange = (value) => {
    setCurrentMode(value);
  };

  const handleChangeLanguage = (value) => {
    setCurrentLang(value);
  };

  const handleTextChange = (e) => {
    setFromText(cutParagraph(e.target.value, maxCountSummarizer));
    setCountChar(cutParagraph(e.target.value, maxCountSummarizer).length);
  };

  const handleSummarizer = async () => {
    setIsLoading(true);
    const response = await summarizerService.summarize(
      fromText,
      currentLang,
      currentMode,
      currentDocType,
      currentLength,
    );

    setSummarizedText(response);
    setIsLoading(false);
  };

  const handleLength = (value) => {
    setCurrentLength(value);
  };

  return (
    <div className="flex flex-col h-full">
      <Title title={t("Resumidor")} />
      <div className="flex flex-col flex-1 w-full h-full overflow-y-auto mx-auto bg-white rounded-2xl shadow-md">
        <HeaderSummarizer
          currentDocType={currentDocType}
          currentLang={currentLang}
          currentMode={currentMode}
          handleChangeLanguage={handleChangeLanguage}
          handleDocChange={handleDocChange}
          handleModeChange={handleModeChange}
          currentLength={currentLength}
          handleLength={handleLength}
        />
        <div className="relative flex flex-1 h-full w-full">
          <div className="flex flex-col flex-1 bg-white rounded-tl-2xl rounded-bl-2xl">
            <textarea
              value={fromText}
              onChange={handleTextChange}
              className="flex-1 w-full max-w-full !break-words h-full outline-none p-6 text-gray-500 overflow-y-auto resize-none"
            />
            <div className="flex items-center justify-end gap-2 p-6">
              <ActionSummarizer
                count={countChar}
                handleSummarizer={handleSummarizer}
                isLoading={isLoading}
                maxCount={maxCountSummarizer}
              />
            </div>
          </div>

          <div className="flex flex-col flex-1 border-l-[1px]">
            <textarea
              className="flex-1 w-full max-w-full !break-words h-full outline-none p-6 text-gray-500 overflow-y-auto resize-none"
              value={summarizedText}
              readOnly
            />
            <div className="flex items-center justify-end gap-2 p-6">
              <div className="flex w-full items-center justify-end gap-2 px-6">
                <ExportButton
                  disabled={summarizedText && !isLoading ? false : true}
                  text={summarizedText}
                  fileName={`BanAIna ${new Date().getTime()}`}
                />
                <MdOutlineContentCopy
                  size={24}
                  onClick={() => copyClipboard(summarizedText)}
                  className={`${
                    summarizedText ? "cursor-pointer opacity-100" : "opacity-50"
                  } `}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summarizer;
