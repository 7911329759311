import { toast } from "react-toastify";
import authService from "./authService";
import { t } from "i18next";
import BASE_URL from "../config/api";
class GenerateCodeService {
  constructor() {
    this.apiurl = BASE_URL;
  }

  async generateCode(text, lang) {
    const language = lang !== "Ninguno" ? lang : "pseudocódigo";

    const apiUrl = this.apiurl + "/v1/novastudio/text-model-inference";
    const apiKey = authService.getToken();

    const requestBody = {
      input_text: `${text} \n Lenguaje: ${language}`,
      template: "code_generator_2",
      temperature: 0.2,
      model: "gpt-4o-mini",
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 401) {
        toast.error(t("El token ha expirado"));
        authService.handleUnauthorized();
        return null;
      }

      const data = await response.json();

      return data.data.response || t("¡Opps! Algo ha salido mal");
    } catch (error) {
      toast.error(t("¡Opps! Algo ha salido mal"));
      return t("¡Opps! Algo ha salido mal");
    }
  }
}

const generateCodeService = new GenerateCodeService();

export default generateCodeService;
