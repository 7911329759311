const removeStyledSpans = (htmlContent) => {
  const cleanContent = htmlContent
    .replaceAll(
      /<span class="border-b-2 border-red-500">([^<]*)<\/span>/g,
      "$1",
    )
    .replaceAll(
      /<span class="text-secondary bg-secondary-hover">([^<]*)<\/span>/g,
      "$1",
    );
  return cleanContent;
};

export default removeStyledSpans;
