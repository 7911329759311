import { t } from "i18next";
import { toast } from "react-toastify";

export class Validator {
  validateLogin(formData) {
    const errors = {};

    if (!formData.username) {
      errors.username = t("El nombre de usuario es obligatorio");
    }

    if (!formData.password) {
      errors.password = t("La contraseña es obligatoria");
    }

    const valid = Object.keys(errors).length === 0;
    if (!valid) toast.error(Object.values(errors)[0]);
    return { valid, errors };
  }

  validateRegister(formData) {
    const errors = {};

    if (!formData.username) {
      errors.username = t("El nombre de usuario es obligatorio");
    }

    if (!formData.password) {
      errors.password = t("La contraseña es obligatoria");
    }

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = t("Las contraseñas no coinciden");
    }

    const valid = Object.keys(errors).length === 0;
    if (!valid) toast.error(Object.values(errors)[0]);
    return { valid, errors };
  }
}
