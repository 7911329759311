import { t } from "i18next";

export const extractDateFromFilename = (filename) => {
  const dateMatch = filename.match(/\((.*?)\)/);
  return dateMatch ? dateMatch[1] : "Fecha desconocida";
};

export const extractNameFromFilename = (filename) => {
  return filename.split(" (")[0];
};

export const getTitle = (selectedShortcut) => {
  switch (selectedShortcut) {
    case "recentFiles":
      return t("Archivos recientes");
    case "uncategorized":
      return t("Sin Categoría");
    default:
      return t("Archivos");
  }
};
