import useAuthStore from "../store/authStore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSessionStore from "../store/sessionStore";
import { t } from "i18next";
import BASE_URL from "../config/api";

class AuthService {
  constructor() {
    this.authStore = useAuthStore;
    this.sessionStore = useSessionStore;
    this.apiurl = BASE_URL;
  }

  login = async (username, password) => {
    const data = {
      username,
      password,
    };

    try {
      const response = await fetch(`${this.apiurl}/v1/novastudio/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseParsed = await response.json();
      if (responseParsed.data.access_token) {
        this.authStore.getState().login(responseParsed.data.access_token);
        this.authStore.getState().setUserInfo({
          username: responseParsed.data.username,
          id: responseParsed.data.id,
          expiration_time: responseParsed.data.expiration_time,
        });
        toast.success(t("¡Bienvenido!"));
        return true;
      } else {
        toast.error(t(responseParsed.detail));
        return false;
      }
    } catch (error) {
      toast.error(t("Ocurrió un error. Por favor, intenta de nuevo"));
      return false;
    }
  };

  logout = () => {
    this.authStore.getState().logout();
  };

  register = async (username, password) => {
    try {
      // Simulación de solicitud API para registrar al usuario
      const response = await new Promise((resolve) => {
        setTimeout(() => resolve({ success: true }), 1000);
      });

      if (response.success) {
        toast.success("Registro exitoso!");
        return true;
      } else {
        toast.error("Error al registrar. Por favor, inténtalo de nuevo.");
        return false;
      }
    } catch (error) {
      toast.error("Ocurrió un error. Por favor, intenta de nuevo.");
      return false;
    }
  };

  isLoggedIn = () => {
    return this.authStore.getState().isLoggedIn;
  };

  getToken = () => {
    return this.authStore.getState().token;
  };
  getUserInfo = () => {
    return {
      username: this.authStore.getState().userInfo.username,
      id: this.authStore.getState().userInfo.id,
      expiration_time: this.authStore.getState().userInfo.expiration_time,
    };
  };
  refreshToken = () => {
    this.logout();
    this.sessionStore.getState().showModal();
  };
  handleUnauthorized = () => {
    this.sessionStore.getState().showModal();
    setTimeout(() => {
      this.authStore.getState().logout();
      window.location.href = "/login";
    }, 3000);
  };
}

const authService = new AuthService();
export default authService;
