const modesProofreader = {
  Light: "Removes all grammar, spelling, and punctuation errors",
  Standard:
    "Improves vocabulary and removes all grammar, spelling, and punctuation errors",
  Intensive:
    "Elevates flow, improves vocabulary, and removes all grammar, spelling, and punctuation errors",
  Concise:
    "Condenses text, improves clarity, and removes all grammar, spelling, and punctuation errors",
};

export default modesProofreader;
