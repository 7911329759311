import React, { useState, useEffect, useRef } from "react";
import ReusableModal from "./ReusableModal";
import { Button } from "@radix-ui/themes/dist/cjs/index.js";
import { t } from "i18next";
import { BsTrash } from "react-icons/bs";
import { useProductStore } from "../../store/generatorStore";
import ButtonPrimary from "../ui/ButtonPrimary";

const VideoUpload = () => {
  const [video, setVideo] = useState();
  const inputRef = useRef(null);
  const {
    isGenerating,
    setIsGenerating,
    videoProcessing,
    selectedOneLanguages,
    selectedTwoLanguages,
  } = useProductStore();

  useEffect(() => {
    if (isGenerating) {
      const timer = setTimeout(() => {
        setIsGenerating(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isGenerating, setIsGenerating]);

  const handleVideoChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setVideo(file);
    }
  };

  const handleRemoveVideo = () => {
    setVideo(null);
    inputRef.current.value = "";
    console.log(inputRef.current.value);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <ul>
        {!video ? (
          <p className="text-gray-600">{t("Subir video")}. </p>
        ) : (
          <div className="w-full flex justify-between items-center">
            <span className="overflow-hidden text-ellipsis text-[rgba(32,180,29,0.50)] text-xs font-medium leading-4">
              {video.name}
            </span>
            <button
              onClick={() => handleRemoveVideo()}
              className="px-2 py-1 text-red hover:bg-red-500 hover:text-white transition-colors rounded"
            >
              <BsTrash />
            </button>
          </div>
        )}
      </ul>

      <div className="flex gap-2 mt-6 w-full justify-center">
        <label className="flex-1 rounded py-2 px-4 text-primary bg-secondary flex justify-center cursor-pointer items-center font-semibold hover:bg-primary hover:text-secondary transition-colors">
          {t("Subir")}
          <input
            ref={inputRef}
            type="file"
            accept="video/mp4"
            onChange={handleVideoChange}
            className="hidden"
          />
        </label>
        <ButtonPrimary
          className={"flex-1 text-center flex justify-center"}
          disabled={!video}
          isLoading={isGenerating}
          onSubmit={() =>
            videoProcessing(
              video,
              "cpu",
              selectedOneLanguages,
              selectedTwoLanguages,
            )
          }
          title={t("Generar")}
        />
      </div>
      <ReusableModal
        isOpen={isGenerating}
        title={t("Generando") + " . . ."}
        message={t(
          "Generando video. Se le informará por una notificación cuando termine",
        )}
        onConfirm={() => setIsGenerating(false)}
        onCancel={() => setIsGenerating(false)}
        hideButtons={true}
      />
    </div>
  );
};

export default VideoUpload;
