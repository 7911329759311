// InputPrompt.jsx
import { t } from "i18next";
import { useEffect, useRef } from "react";
import { LuEraser } from "react-icons/lu";
import { maxCountPromptImage } from "../../../config/maxCountWords";
import { FiEdit2 } from "react-icons/fi";

const InputPrompt = ({ prompt, onPromptChange, onEraserClick }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      // Usar requestAnimationFrame para minimizar el impacto en el rendimiento
      window.requestAnimationFrame(() => {
        const textarea = textareaRef.current;
        const length = textarea.value.length;

        // Usar setTimeout para separar el acceso y modificación del DOM del reflow
        setTimeout(() => {
          textarea.setSelectionRange(length, length);
          textarea.focus();
        }, 0);
      });
    }
  }, []);
  return (
    <div className="flex flex-1 w-full text-base">
      <textarea
        ref={textareaRef}
        value={prompt}
        onChange={onPromptChange}
        className="outline-none resize-none w-full pb-6"
        rows={1}
        maxLength={maxCountPromptImage}
        placeholder={t("Describe lo que quieres ver")}
      />
      <LuEraser
        onClick={onEraserClick}
        className="cursor-pointer hover:bg-secondary hover:font-semibold p-1 rounded-md"
        size={28}
      />
    </div>
  );
};

export default InputPrompt;
