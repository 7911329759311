import { Checkbox, Table, Skeleton } from "@radix-ui/themes";
import { useEffect } from "react";
import transcribeService from "../../services/transcribeService";
import {
  extractDateFromFilename,
  extractNameFromFilename,
} from "../../utils/extractFileNames";
import useTranscribeStore from "../../store/transcribeStore";
import ShowTranscribe from "./audioTranscribe/ShowTranscribe";
import { NavLink, useNavigate } from "react-router-dom";
import { t } from "i18next";

const TableTranscribe = () => {
  const transcribes = useTranscribeStore((state) => state.listTranscribes);
  const loading = useTranscribeStore((state) => state.loading);
  const selectedIds = useTranscribeStore((state) => state.selectedIds);
  const setSelectedIds = useTranscribeStore((state) => state.setSelectedIds);

  const navigate = useNavigate();

  const handleCheckAll = (checked) => {
    const newSelectedIds = checked
      ? new Set(transcribes.map((transcribes) => transcribes.id))
      : new Set();
    setSelectedIds(newSelectedIds);
  };

  const handleCheckboxChange = (id, checked) => {
    const updatedSelectedIds = new Set(selectedIds);
    if (checked) {
      updatedSelectedIds.add(id);
    } else {
      updatedSelectedIds.delete(id);
    }
    setSelectedIds(updatedSelectedIds);
  };

  const handleNavigate = (id) => {
    navigate(`transcribe/${id}`);
  };
  useEffect(() => {
    transcribeService.getAllTranscribes();
  }, []);

  return (
    <Table.Root className="!w-full !h-full overflow-y-auto ">
      <Table.Header>
        <Table.Row className="text-gray-500">
          <Table.ColumnHeaderCell>
            <Checkbox
              size={"3"}
              color="yellow"
              checked={selectedIds.size === transcribes.length}
              onCheckedChange={handleCheckAll}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>{t("Nombre")}</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>{t("Fecha")}</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>{t("Subido")}</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>{t("Duración")}</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <Table.Row key={index}>
                <Table.RowHeaderCell>
                  <Skeleton variant="circle" loading={loading}>
                    <Checkbox size={"3"} defaultChecked color="yellow" />
                  </Skeleton>
                </Table.RowHeaderCell>
                <Table.RowHeaderCell>
                  <Skeleton variant="circle" loading={loading}>
                    Placeholder
                  </Skeleton>
                </Table.RowHeaderCell>
                <Table.Cell>
                  <Skeleton variant="circle" loading={loading}>
                    Placeholder
                  </Skeleton>
                </Table.Cell>
                <Table.Cell>
                  <Skeleton variant="circle" loading={loading}>
                    Placeholder
                  </Skeleton>
                </Table.Cell>
                <Table.Cell>
                  <Skeleton variant="circle" loading={loading}>
                    Placeholder
                  </Skeleton>
                </Table.Cell>
              </Table.Row>
            ))
          : transcribes.map((transcribes) => (
              <Table.Row
                key={transcribes.id}
                onClick={() => handleNavigate(transcribes.id)}
                className="hover:bg-secondary-hover cursor-pointer text-gray-500"
              >
                <Table.RowHeaderCell>
                  <Checkbox
                    size={"3"}
                    color="yellow"
                    checked={selectedIds.has(transcribes.id)}
                    onCheckedChange={(checked) =>
                      handleCheckboxChange(transcribes.id, checked)
                    }
                  />
                </Table.RowHeaderCell>
                <Table.RowHeaderCell>
                  <NavLink className="bg-transparent hover:underline text-gray-500 cursor-pointer">
                    {extractNameFromFilename(transcribes.filename)}
                  </NavLink>
                </Table.RowHeaderCell>
                <Table.Cell>
                  {extractDateFromFilename(transcribes.filename)}
                </Table.Cell>
                <Table.Cell>{transcribes.uploadedAt}</Table.Cell>
                <Table.Cell>{transcribes.duration}</Table.Cell>
              </Table.Row>
            ))}
      </Table.Body>
    </Table.Root>
  );
};

export default TableTranscribe;
