import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { GrLanguage } from "react-icons/gr";
import authService from "../services/authService";
import Dropdown from "./navbar/Dropdown";
import { FaUser } from "react-icons/fa";
import useLanguageStore from "../store/langStore";
import { t } from "i18next";
import useImageStore from "../store/imageStore";
import { useEffect, useState } from "react";
import TranslateService from "../services/translateService";

const Navbar = () => {
  const isLoggedIn = authService.isLoggedIn();
  const [translateService] = useState(new TranslateService());
  const { language, setLanguage } = useLanguageStore();
  const { resetStateImages } = useImageStore();

  const navigate = useNavigate();
  const handleLogout = () => {
    authService.logout();
    resetStateImages();
    // QUICK SOLUTION FOR CLEARING LOCAL DATA
    navigate("/login");
  };
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  const languagesOptions = [
    { label: t("Spanish"), onClick: () => handleLanguageChange("es") },
    { label: t("English"), onClick: () => handleLanguageChange("en") },
    { label: t("Catalan"), onClick: () => handleLanguageChange("ca") },
  ];

  useEffect(() => {
    translateService.loadLanguages();
  }, []);
  return (
    <nav className="h-16 shadow-lg flex  items-center sticky top-0 bg-white z-50 py-1 px-6">
      <div className="w-full">
        <div className="flex justify-between h-16 px-5 items-center">
          <div className="flex items-center space-x-8">
            <Logo className="h-12" />
          </div>
          <div className="flex space-x-8 items-center">
            <Dropdown
              triggerIcon={<GrLanguage />}
              triggerLabel={
                language === "es"
                  ? t("Spanish")
                  : language === "en"
                  ? t("English")
                  : t("Catalan")
              }
              menuItems={languagesOptions}
            />
            {isLoggedIn && (
              <Dropdown
                triggerIcon={<FaUser />}
                triggerLabel="User"
                menuItems={[{ label: "Logout", onClick: handleLogout }]}
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
