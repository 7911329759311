import { NavLink } from "react-router-dom";
import { AiOutlineBars } from "react-icons/ai";
import { TbCategory } from "react-icons/tb";
import { IoFolderOutline } from "react-icons/io5";
import { t } from "i18next";

const Shortcuts = () => {
  const handleNewFolderClick = () => {};

  return (
    <div className=" col-span-12 md:col-span-2 lg:col-span-1 flex-col space-y-4 text-gray-500">
      <div className="sticky top-0 p-4 bg-white shadow-lg rounded-lg ">
        <div className="mb-4">
          <h4 className="text-sm mb-2">{t("Shortcuts")}</h4>
          <div className="flex flex-col gap-2 text-sm">
            <NavLink
              end
              to={"/dashboard/transcribe-text"}
              className={({ isActive }) =>
                `flex items-center p-2 cursor-pointer rounded hover:bg-gray-200 ${
                  isActive ? "bg-gray-200" : ""
                }`
              }
            >
              <AiOutlineBars className="mr-2" /> {t("Recientes")}
            </NavLink>
            <NavLink
              end
              to={"/dashboard/transcribe-text/sin-categoria"}
              className={({ isActive }) =>
                `flex items-center p-2 cursor-pointer truncate hover:bg-gray-200 rounded ${
                  isActive ? "bg-gray-200" : ""
                }`
              }
            >
              <TbCategory className="mr-2" /> {t("Sin categoria")}
            </NavLink>
          </div>
        </div>

        <div>
          <h4 className="text-sm mb-2">{t("Carpetas")}</h4>
          <ul>
            <li
              onClick={handleNewFolderClick}
              className="flex items-center p-2 cursor-pointer hover:bg-gray-200"
            >
              <IoFolderOutline className="mr-2 text-lg" /> {t("Nueva carpeta")}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Shortcuts;
