import { jsPDF } from "jspdf";
import * as FileSaver from "file-saver";
import JSZip from "jszip";
import useTranscribeStore from "../store/transcribeStore";
//import * as XLSX from "xlsx";

class ExportService {
  constructor() {
    this.exportToPDF = this.exportToPDF.bind(this);
    this.exportToDOCX = this.exportToDOCX.bind(this);
    this.exportToTXT = this.exportToTXT.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
  }

  exportToPDF(text, fileName) {
    const marginLeft = 10;
    const marginTop = 10;
    const maxWidth = 190;
    const lineHeight = 10; // Altura de línea
    const pageHeight = 297; // Altura de la página A4 en mm
    const maxLineHeight = pageHeight - marginTop * 2; // Altura máxima antes de un salto de página

    const doc = new jsPDF();
    const lines = doc.splitTextToSize(text, maxWidth);
    let currentHeight = marginTop;

    lines.forEach((line, index) => {
      // Si el texto supera la altura de la página, añade una nueva página
      if (currentHeight + lineHeight > maxLineHeight) {
        doc.addPage();
        currentHeight = marginTop; // Reiniciar el contador de altura
      }

      doc.text(line, marginLeft, currentHeight);
      currentHeight += lineHeight; // Aumenta la altura para la siguiente línea
    });

    return doc.output("blob");
  }

  exportToDOCX(text, fileName) {
    const blob = new Blob([text], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    return blob;
  }

  exportToTXT(text, fileName) {
    const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
    return blob;
  }

  exportToCSV(text, fileName) {
    const blob = new Blob([text], { type: "text/csv;charset=utf-8" });
    return blob;
  }

  async exportToZIP(text, fileName, selectedFormats) {
    const zip = new JSZip();
    const zipFileName = `${fileName}.zip`;

    if (selectedFormats.pdf) {
      const pdfBlob = this.exportToPDF(text, fileName);
      zip.file(`${fileName}.pdf`, pdfBlob);
    }
    if (selectedFormats.docx) {
      const docxBlob = this.exportToDOCX(text, fileName);
      zip.file(`${fileName}.docx`, docxBlob);
    }
    if (selectedFormats.txt) {
      const txtBlob = this.exportToTXT(text, fileName);
      zip.file(`${fileName}.txt`, txtBlob);
    }
    if (selectedFormats.csv) {
      const csvBlob = this.exportToCSV(text, fileName);
      zip.file(`${fileName}.csv`, csvBlob);
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });
    FileSaver.saveAs(zipBlob, zipFileName);
  }

  async exportTranscribeToZIP(transcribes, selectedFormats) {
    const selectedtranscribes = useTranscribeStore
      .getState()
      .listTranscribes.filter((transcribes) => transcribes.has(transcribes.id));

    const zip = new JSZip();
    const zipFileName = `BanAIna Transcripciones.zip`;

    for (const transcribes of selectedtranscribes) {
      if (selectedFormats.pdf) {
        const pdfBlob = this.exportToPDF(transcribes.transcribes);
        zip.file(`${transcribes.filename}.pdf`, pdfBlob);
      }
      if (selectedFormats.docx) {
        const docxBlob = this.exportToDOCX(transcribes.transcribes);
        zip.file(`${transcribes.filename}.docx`, docxBlob);
      }
      if (selectedFormats.txt) {
        const txtBlob = this.exportToTXT(transcribes.transcribes);
        zip.file(`${transcribes.filename}.txt`, txtBlob);
      }
      if (selectedFormats.csv) {
        const csvBlob = this.exportToCSV(transcribes.transcribes);
        zip.file(`${transcribes.filename}.csv`, csvBlob);
      }
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });
    FileSaver.saveAs(zipBlob, zipFileName);
  }
}

const exportService = new ExportService();

export default exportService;
