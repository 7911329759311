import { useState } from "react";
import useChatStore from "../../../store/messageStore";
import { deleteChat, updateChatTitle } from "../../../services/chatsServices";
import { BsTrash } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { useForm } from "react-hook-form";
import ReusableModal from "../../videoProcessing/ReusableModal";
import { toast } from "react-toastify";
import { t } from "i18next";

const ChatCard = () => {
  const {
    setChatId,
    setNewMessage,
    fetchChats,
    filteredChats,
    selectedChatId,
    setSelectedChatId,
    categorizedChats,
  } = useChatStore();
  const [hoveredChatId, setHoveredChatId] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const handleClick = (chat_id) => {
    if (chat_id !== undefined) {
      setSelectedChatId(chat_id);
      setChatId(chat_id);
      setNewMessage(false);
    }
  };

  const handleOpenModal = (chat_id, action) => {
    setSelectedChatId(chat_id);
    setPendingAction(action);

    if (action === "edit") {
      const chatToEdit = filteredChats.find((chat) => chat.chat_id === chat_id);
      if (chatToEdit) {
        setValue("name", chatToEdit.title);
      }
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedChatId(null);
    setPendingAction(null);
    reset();
  };

  const onSubmit = async ({ name }) => {
    setIsPending(true);

    if (pendingAction === "delete" && selectedChatId) {
      try {
        await deleteChat(selectedChatId);
        fetchChats();
        setChatId(null);
        toast.success(t("¡Chat eliminado!"));
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    } else if (pendingAction === "edit" && selectedChatId && name) {
      fetchChats();
      try {
        await updateChatTitle(selectedChatId, name);
        fetchChats();
        setValue("name", "");
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    }
    setIsPending(false);
    handleCloseModal();
  };

  return (
    <>
      {filteredChats &&
        Object.keys(filteredChats).map((category) => (
          <div key={category}>
            <h2 className="text-sm font-semibold">
              {category === "today" &&
                filteredChats["today"].length > 0 &&
                t("Hoy")}
              {category === "week" &&
                filteredChats["week"].length > 0 &&
                t("7 días anteriores")}
              {category === "month" &&
                filteredChats["month"].length > 0 &&
                t("30 días anteriores")}
              {category === "previousMonths" &&
                filteredChats.previousMonths.length > 0 &&
                t("Meses anteriores")}
            </h2>
            {category === "previousMonths"
              ? Object.keys(filteredChats.previousMonths).map((monthKey) => (
                  <div key={monthKey}>
                    <h3 className="text-sm font-semibold">{t(monthKey)}</h3>
                    {filteredChats.previousMonths[monthKey].map((chat) => (
                      <div
                        key={chat.chat_id}
                        className={`relative cursor-pointer flex p-[8px_10px] items-center gap-2.5 self-stretch rounded-md bg-gray-200 border-2 border-transparent mb-2
                         ${selectedChatId === chat.chat_id ? " font-bold " : ""}`}
                        onClick={() => handleClick(chat.chat_id)}
                        onMouseEnter={() => setHoveredChatId(chat.chat_id)}
                        onMouseLeave={() => setHoveredChatId(null)}
                      >
                        <h3
                          className={`truncate max-w-52 text-[14px] normal-case leading-normal
                          ${selectedChatId === chat.chat_id ? " " : ""}`}
                        >
                          {chat.title}
                        </h3>

                        {hoveredChatId === chat.chat_id && (
                          <>
                            <button
                              className="absolute top-2 right-2 p-1 rounded"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenModal(chat.chat_id, "delete");
                              }}
                            >
                              <BsTrash />
                            </button>

                            <button
                              className="absolute top-2 right-8 p-1 rounded"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenModal(chat.chat_id, "edit");
                              }}
                            >
                              <CiEdit />
                            </button>
                          </>
                        )}
                        <div onClick={(e) => e.stopPropagation()}>
                          <ReusableModal
                            isLoading={isPending}
                            isOpen={isModalOpen}
                            title={
                              pendingAction === "edit"
                                ? t("Cambiar el nombre del título del chat")
                                : t("Confirmar eliminación de chat")
                            }
                            message={
                              pendingAction === "edit"
                                ? ""
                                : t(
                                    "Eliminará permanentemente este historial de chat",
                                  )
                            }
                            onConfirm={handleSubmit(onSubmit)}
                            onCancel={() => handleCloseModal()}
                            statusImg={
                              pendingAction === "edit" ? "" : "warning"
                            }
                          >
                            {pendingAction === "edit" && (
                              <form className="mb-4">
                                <input
                                  className="w-full p-[8px_10px] rounded-md bg-secondary-hover h-14 outline-none"
                                  id="name"
                                  type="text"
                                  {...register("name", {
                                    required: t("Este campo es requerido"),
                                  })}
                                  placeholder={t("Ingrese nuevo nombre")}
                                />
                                {errors.name && (
                                  <span className="text-red-500 text-xs italic mt-2">
                                    {t(errors.name.message)}
                                  </span>
                                )}
                              </form>
                            )}
                          </ReusableModal>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              : filteredChats[category].map((chat) => (
                  <div
                    key={chat.chat_id}
                    className={`relative cursor-pointer flex p-[8px_10px] items-center gap-2.5 self-stretch rounded-md bg-gray-200 border-2 border-transparent mb-2
                     ${selectedChatId === chat.chat_id ? " font-bold " : ""}`}
                    onClick={() => handleClick(chat.chat_id)}
                    onMouseEnter={() => setHoveredChatId(chat.chat_id)}
                    onMouseLeave={() => setHoveredChatId(null)}
                  >
                    <h3
                      className={`truncate max-w-52 text-[14px] normal-case leading-normal
                      ${selectedChatId === chat.chat_id ? " " : ""}`}
                    >
                      {chat.title}
                    </h3>

                    {hoveredChatId === chat.chat_id && (
                      <>
                        <button
                          className="absolute top-2 right-2 p-1 rounded"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenModal(chat.chat_id, "delete");
                          }}
                        >
                          <BsTrash />
                        </button>

                        <button
                          className="absolute top-2 right-8 p-1 rounded"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenModal(chat.chat_id, "edit");
                          }}
                        >
                          <CiEdit />
                        </button>
                      </>
                    )}
                    <div onClick={(e) => e.stopPropagation()}>
                      <ReusableModal
                        isLoading={isPending}
                        isOpen={isModalOpen}
                        title={
                          pendingAction === "edit"
                            ? t("Cambiar el nombre del título del chat")
                            : t("Confirmar eliminación de chat")
                        }
                        message={
                          pendingAction === "edit"
                            ? ""
                            : t(
                                "Eliminará permanentemente este historial de chat",
                              )
                        }
                        onConfirm={handleSubmit(onSubmit)}
                        onCancel={() => handleCloseModal()}
                        statusImg={pendingAction === "edit" ? "" : "warning"}
                      >
                        {pendingAction === "edit" && (
                          <form className="mb-4">
                            <input
                              className="w-full p-[8px_10px] rounded-md bg-secondary-hover h-14 outline-none"
                              id="name"
                              type="text"
                              {...register("name", {
                                required: t("Este campo es requerido"),
                              })}
                              placeholder={t("Ingrese nuevo nombre")}
                            />
                            {errors.name && (
                              <span className="text-red-500 text-xs italic mt-2">
                                {t(errors.name.message)}
                              </span>
                            )}
                          </form>
                        )}
                      </ReusableModal>
                    </div>
                  </div>
                ))}
          </div>
        ))}
    </>
  );
};

export default ChatCard;
