import { toast } from "react-toastify";
import authService from "./authService";
import useTranscribeStore from "../store/transcribeStore";
import { t } from "i18next";
import BASE_URL from "../config/api";

// services/transcribeService.js
class TranscribeService {
  constructor() {
    this.apiurl = BASE_URL;
    this.temporlURL = "https://tools.macaqueconsulting.com/v1/recorder";
  }

  async transcribeAudio(audioFile, filename) {
    const setText = useTranscribeStore.getState().setText;
    const setLoading = useTranscribeStore.getState().setLoading;
    const transcribes = useTranscribeStore.getState().listTranscribes;
    const setListtranscribes = useTranscribeStore.getState().setListtranscribes;

    setLoading(true);
    setText("");

    try {
      const formData = new FormData();
      const model_name = "whisper-1";
      const bearerToken = authService.getToken();

      formData.append("audio_file", audioFile, `${filename}.webm`);

      const response = await fetch(`${this.temporlURL}/upload-audio/`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Error in transcribing the audio");
      }

      //const data = await response.json();
      await this.getAllTranscribes();

      toast.success(t("¡Transcripción realizada!"));
    } catch (error) {
      toast.error(t("Error tratando de enviar el audio"));
      throw error;
    } finally {
      setLoading(false);
    }
  }
  async getAllTranscribes() {
    const setLoading = useTranscribeStore.getState().setLoading;
    const setSelectedIds = useTranscribeStore.getState().setSelectedIds;
    const setListtranscribes = useTranscribeStore.getState().setListtranscribes;
    try {
      setLoading(true);

      const response = await fetch(
        `https://tools.macaqueconsulting.com/v1/recorder/list-audio/`
      );

      if (!response.ok) {
        throw new Error("Error obteniendo los audios");
      }
      const data = await response.json();
      setListtranscribes(data);
      setSelectedIds(new Set());
      return data;
    } catch (error) {
      toast.error(t("Ha ocurrido un error"));
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async deletetranscribes(ids) {
    const setLoading = useTranscribeStore.getState().setLoading;

    setLoading(true);
    const allPromises = ids.map((id) => {
      return fetch(`${this.temporlURL}/delete-audio/${id}`, {
        method: "DELETE",
      });
    });
    Promise.all(allPromises)
      .then(async (res) => {
        toast.success(t("Eliminados exitosamente"));
        await this.getAllTranscribes();
      })
      .catch((err) => {
        toast.error(t("Error eliminando algunos archivos"));
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
}

const transcribeService = new TranscribeService();
export default transcribeService;
