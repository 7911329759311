import { t } from "i18next";

const InputButton = ({ onOpenPrompt, prompt }) => {
  return (
    <div
      onClick={onOpenPrompt}
      className="sticky top-0 z-10 flex items-center w-full text-gray-500"
    >
      <div className="bg-white border-[1px] border-gray-300 p-4 w-full h-full flex  rounded-l-xl">
        <textarea
          name="prompt"
          value={prompt}
          onChange={() => {}}
          className="outline-none resize-none w-full "
          rows={1}
          placeholder={t("Describe lo que quieres ver")}
        />
      </div>
      <button className="inline-flex items-center self-center h-full bg-secondary text-primary font-semibold py-3 px-10 border-[1px] border-secondary rounded-r-xl">
        {t("Generar")}
      </button>
    </div>
  );
};

export default InputButton;
