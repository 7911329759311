import InputPrompt from "./InputPrompt";
import ButtonIconsPanel from "./ButtonIconsPanel";
import ActionButtons from "./ActionButtons";
import ConfigOptions from "./ConfigOptions";
import ButtonGenerate from "./ButtonGenerate";
import { useState } from "react";

const PromptContainer = ({ onClose, prompt, onPrompt }) => {
  const [config, setConfig] = useState({
    ratio: "1024x1024",
    mode: "cualquiera",
  });
  const handlePromptChange = (e) => {
    onPrompt(e.target.value);
  };

  const handleEraser = () => {
    onPrompt("");
  };

  const handleConfig = (newValue) => {
    const { name, value } = newValue;
    setConfig({ ...config, [name]: value });
  };

  return (
    <div className="absolute z-10 top-0 left-0 flex items-center w-full h-[400px] text-gray-500 shadow-2xl">
      <div className="bg-white border-[1px] border-gray-300 p-4 w-full h-full flex rounded-l-xl">
        <div className="flex flex-col h-full w-full">
          <InputPrompt
            prompt={prompt}
            onPromptChange={handlePromptChange}
            onEraserClick={handleEraser}
          />
          <ButtonIconsPanel
            config={config}
            onClick={handleConfig}
            prompt={prompt}
          />
        </div>
      </div>
      <div className="max-w-[500px] bg-white border-[1px] border-gray-300  p-4 w-full h-full flex rounded-r-xl">
        <div className="flex-1 w-full h-full relative">
          <ActionButtons onClose={onClose} />
          <ConfigOptions onConfig={handleConfig} />
          <ButtonGenerate
            config={config}
            prompt={prompt}
            className="absolute bottom-0 right-0 py-2 px-4 rounded flex items-center font-semibold"
            onClick={() => console.log("")}
          />
        </div>
      </div>
    </div>
  );
};

export default PromptContainer;
