import { Badge } from "@radix-ui/themes";
import { t } from "i18next";
import { FaArrowRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const CardHome = ({ title, Icon, route, category, description }) => {
  return (
    <div className="relative mt-2 flex w-full flex-col rounded-2xl bg-white bg-clip-border text-primart shadow-md">
      <div className="p-4">
        <div className="w-full text-end">
          <Badge size={"3"} color="yellow" className="mb-2 text-end">
            {t(category)}
          </Badge>
        </div>
        <Icon className="h-12 w-12 mb-2" />
        <h5 className="mb-2 block text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
          {t(title)}
        </h5>
        <p className="block text-base font-light leading-relaxed text-inherit antialiased !line-clamp-2">
          {t(description)}
        </p>
      </div>
      <div className="p-4 pt-0">
        <NavLink className="!font-medium !transition-colors" to={route}>
          <button
            className="flex select-none items-center gap-2 rounded-lg py-2 px-4 text-center align-middle text-xs font-bold uppercase text-secondary transition-all hover:bg-secondary-hover disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
            data-ripple-dark="true"
          >
            {t("Ver más")}
            <FaArrowRight />
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default CardHome;
