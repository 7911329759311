import { IoMdCheckmark } from "react-icons/io";
import { LuPlus } from "react-icons/lu";
import musicOptions from "../../../config/musicGeneratorOptions";
import useMusicStore from "../../../store/musicStore";
import ButtonOptions from "./ButtonOptions";

const OptionsSideContainer = () => {
  const { selectedOptions, toggleOption } = useMusicStore();

  return (
    <div className="options h-full border-r-[1px] border-[#dbd9e0] min-h-0 overflow-y-auto">
      {musicOptions.map((option, index) => (
        <div key={index} className="flex flex-col items-center justify-center">
          <ButtonOptions
            Icon={
              option.value === "genero"
                ? option.icon
                : selectedOptions.includes(option.value)
                  ? IoMdCheckmark
                  : LuPlus
            }
            isSelected={selectedOptions.includes(option.value)}
            onClick={() => toggleOption(option.value)}
          />
          <p className="text-xs"> {option.label} </p>
        </div>
      ))}
    </div>
  );
};

export default OptionsSideContainer;
