import axios from "axios";
import BASE_URL from "../config/api";
import authService from "./authService";

//TODO: automatizar para que el header se envie en todas las peticiones y crear URL base
export const getChats = async () => {
  const token = authService.getToken();
  const user_id = authService.getUserInfo().id;
  try {
    const response = await axios.get(
      `${BASE_URL}/v1/novastudio/user/${user_id}/list-chats`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    //BACKEND IS RETURNING A ERROR WHEN USER HAS NOT CHAT
    if (error.response.data.detail.error === "No sessions available") {
      return { data: { chats_summary: [] } };
    }
    throw new Error("Failed to fetch chats");
  }
};

export const createChat = async (chatId, question, templateId, chatType) => {
  const token = authService.getToken();
  const body = {
    title: question,
    question: question,
  };
  if (chatId !== null) {
    body.chat_id = String(chatId);
  }
  if (templateId) {
    body.template_id = String(templateId);
  }

  let url = `${BASE_URL}/v1/novastudio/chat-agent`;
  if (chatType === "normal") {
    url = `${BASE_URL}/v1/novastudio/chatbot`;
  }

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = response.data.data;

    if (chatId === undefined || chatId === null) {
      return {
        answer: responseData.answer,
        chat_id: responseData.chat_id,
        title: responseData.title,
        new: true,
      };
    } else {
      return {
        answer: responseData.answer,
        title: responseData.title,
        chat_id: responseData.chat_id,
        new: false,
      };
    }
  } catch (error) {
    throw new Error("Failed to create chat");
  }
};

export const getChatMessages = async (chatId) => {
  const token = authService.getToken();
  try {
    const response = await axios.get(
      `${BASE_URL}/v1/novastudio/chat/${chatId}/history`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data.session_history;
  } catch (error) {
    throw new Error("Failed to fetch chat messages");
  }
};

export const deleteChat = async (chat_id) => {
  const token = authService.getToken();
  try {
    const response = await axios.delete(
      `${BASE_URL}/v1/novastudio/chat/${chat_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.detail || "Error deleting chat");
    }
    throw new Error("Network error");
  }
};

export const updateChatTitle = async (chat_id, new_title) => {
  const token = authService.getToken();

  try {
    const response = await axios.put(
      `${BASE_URL}/v1/novastudio/chat/${chat_id}/title?new_title=${encodeURIComponent(
        new_title
      )}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to update chat");
  }
};
