import { SegmentedControl, Separator } from "@radix-ui/themes";
import { t } from "i18next";
import useMusicStore from "../../../store/musicStore";

const InputTypeOptions = () => {
  const { inputType, setInputType } = useMusicStore();

  const handleInputType = (value) => {
    setInputType(value);
  };

  return (
    <div className="w-full">
      <SegmentedControl.Root
        defaultValue={inputType}
        className="w-full h-10"
        onValueChange={handleInputType}
      >
        <SegmentedControl.Item value="generator">
          {t("Generator")}
        </SegmentedControl.Item>
        <SegmentedControl.Item value="text-music">
          {t("Texto a música")}
        </SegmentedControl.Item>
      </SegmentedControl.Root>
      <Separator size="4" my={"3"} />
    </div>
  );
};

export default InputTypeOptions;
