import { useState } from "react";
import { t } from "i18next";
import { FaArrowsSpin } from "react-icons/fa6";
import { FiEdit2 } from "react-icons/fi";
import { MdFileUpload } from "react-icons/md";
// import mammoth from "mammoth";  // Descomentar si quieres soportar archivos .docx
import pdfToText from "react-pdftotext";
import { toast } from "react-toastify";
import ButtonPrimary from "../../ui/ButtonPrimary";

const ActionParaphrase = ({
  count,
  maxCount,
  handleParaphraser,
  isLoading,
}) => {
  const [fileError, setFileError] = useState("");
  const [textExtracted, setTextExtracted] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [countFile, setCountFile] = useState(0);

  const handleFileUpload = async (event) => {
    setIsUploading(true);
    setFileError("");
    const file = event.target.files[0];
    const fileType = file?.type;

    if (!file) {
      setFileError(t("No se ha seleccionado ningún archivo."));
      setIsUploading(false);
      return;
    }

    try {
      if (
        fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        // const arrayBuffer = await file.arrayBuffer();
        // const rawValue = await mammoth.extractRawText({ arrayBuffer });
        // setTextExtracted(rawValue.value.trim());
        // setCountFile(rawValue.value.trim().length);
        setFileError(t("Actualmente solo se admite la carga de archivos PDF."));
      } else if (fileType === "application/pdf") {
        const text = await pdfToText(file);
        const wordCount = text.trim().split(/\s+/).length;
        if (wordCount > 500) {
          toast.error(t("El archivo excede el límite de 500 palabras."));
          setIsUploading(false);
          return;
        }

        setTextExtracted(text.trim());
        setCountFile(text.length);
      } else {
        setFileError(t("Formato de archivo no soportado. Usa PDF."));
      }
    } catch (error) {
      console.error("Error procesando el archivo:", error);
      setFileError(
        t(
          "Error al procesar el archivo. Asegúrate de que sea un archivo válido.",
        ),
      );
    } finally {
      setIsUploading(false);
    }
  };

  const isDisabled = () => {
    return (
      (!textExtracted && (count === 0 || count > maxCount)) ||
      isLoading ||
      isUploading
    );
  };

  return (
    <div className="flex w-full items-center justify-between px-6">
      <span
        className={`text-sm flex items-center ${
          count > maxCount ? "text-red-500 font-semibold" : "text-gray-500"
        }`}
      >
        {count} / {maxCount} {t("Caracteres")}
        <FiEdit2 className="ml-2" />
      </span>

      <div className="flex gap-4 items-center">
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileUpload}
          className="hidden"
          id="fileUpload"
        />
        <label htmlFor="fileUpload" className="cursor-pointer">
          <MdFileUpload size={24} className="text-secondary" />
        </label>
        <ButtonPrimary
          count={count}
          disabled={isDisabled()}
          isLoading={isLoading}
          maxCount={maxCount}
          onSubmit={() => handleParaphraser(textExtracted)}
          title={t("Parafrasear")}
        />
      </div>

      {fileError && <p className="text-red-500">{fileError}</p>}
    </div>
  );
};

export default ActionParaphrase;
