import React, { useState, useEffect } from "react";

const LoadingChat = ({ text }) => {
  const [dots, setDots] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots % 3) + 1);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {text}
      {".".repeat(dots)}
    </div>
  );
};

export default LoadingChat;
