import { useEffect, useRef } from "react";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
const CustomPlyr = ({ source, options = null }) => {
  const player = useRef(null);

  useEffect(() => {
    new Plyr(player.current, {
      controls: [
        "play",
        "progress",
        "current-time",
        "volume",
        "mute",
        "settings",
      ],
      loadSprite: true,
    });
  }, []);
  return (
    <audio ref={player} controls playsInline>
      <source src={source} type="audio/mp3" />
    </audio>
  );
};

export default CustomPlyr;
