import { t } from "i18next";
import Title from "./Title";
import SelectPlainText from "../../components/ui/SelectPlainText";
import { useEffect, useState } from "react";
import generateCodeService from "../../services/generateCodeService";
import DOMPurify from "dompurify";
import { marked } from "marked";
import "highlight.js/styles/base16/colors.css";
import highlightAndAddCopyButton from "../../utils/highlightAndAddCopyButton";
import ButtonPrimary from "../../components/ui/ButtonPrimary";
import listOfLangs from "../../config/programmingLangs";

const CodeGenerator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [codeGenerated, setCodeGenerated] = useState(null);
  const [codeLanguage, setCodeLanguage] = useState("Python");
  const [textToSend, setTextToSend] = useState("");
  const [showContent, setShowContent] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    setShowContent(false);
    setCodeGenerated(null);
    const response = await generateCodeService.generateCode(
      textToSend,
      codeLanguage,
    );
    setIsLoading(false);
    setCodeGenerated(response);
  };
  useEffect(() => {
    const nodes = document.querySelectorAll("pre code");
    highlightAndAddCopyButton(nodes, codeLanguage);
    setShowContent(true);
  }, [codeGenerated]);

  return (
    <div className="flex flex-col h-full">
      <Title title={t("Generador de código")} />
      <div className="flex flex-col w-full h-full mx-auto min-h-0">
        <div className="flex flex-1 gap-4 min-h-0">
          <div className="flex gap-2 flex-col flex-1 bg-white rounded-xl shadow-md p-6">
            <span className="text-xs block my-2 font-bold">
              {t("Lenguaje de Programación")}
            </span>
            <SelectPlainText
              classNameSelector={"w-full shadow-md"}
              classNameBoxOptions={"w-full"}
              options={listOfLangs}
              defaultValue={codeLanguage}
              action={(val) => setCodeLanguage(val)}
            />
            <span className="text-xs block my-2 font-bold">
              {t("Proporcionar instrucciones")}
            </span>

            <textarea
              value={textToSend}
              onChange={(e) => setTextToSend(e.target.value)}
              className="h-full max-h-[60%] overflow-auto outline-none border-[1px] border-transparent bg-gray-200 rounded-xl text-xs py-3 px-4"
              placeholder={t(
                "Especifique qué tipo de función o fragmento de código desea generar",
              )}
            />
            <ButtonPrimary
              disabled={isLoading || textToSend.length === 0}
              isLoading={isLoading}
              onSubmit={handleClick}
              title={t("Generar")}
              className=" flex justify-center items-center"
            />
          </div>

          <div className="flex justify-center items-center flex-[3] bg-white rounded-xl shadow-md p-6 min-w-0">
            {isLoading ? (
              <p> {t("Generando código")}... </p>
            ) : !codeGenerated ? (
              <p> {t("Genera tu código fácilmente")} </p>
            ) : (
              <div
                className={
                  "w-full h-full overflow-y-auto " +
                  (showContent ? "opacity-100" : "opacity-0")
                }
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(marked.parse(codeGenerated)),
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeGenerator;
