import { t } from "i18next";
import { FaArrowsSpin } from "react-icons/fa6";
import { FiEdit2 } from "react-icons/fi";
import ButtonPrimary from "../../ui/ButtonPrimary";

const ActionSummarizer = ({ count, maxCount, handleSummarizer, isLoading }) => {
  const isDisabled = () => {
    return count === 0 || count > maxCount || isLoading;
  };

  return (
    <div className="flex w-full items-center justify-between px-6">
      <span
        className={`text-sm flex items-center ${
          count > maxCount ? "text-red-500 font-semibold" : "text-gray-500"
        }`}
      >
        {count} / {maxCount} {t("Caracteres")}
        <FiEdit2 className="ml-2" />
      </span>

      <div className="flex gap-4 items-center">
        <ButtonPrimary
          count={count}
          disabled={isDisabled()}
          isLoading={isLoading}
          maxCount={maxCount}
          onSubmit={handleSummarizer}
          title={t("Resumir")}
        />
      </div>
    </div>
  );
};

export default ActionSummarizer;
