import { create } from "zustand";
import {
  createCategory,
  deleteCategory,
  getCategories,
  getTemplateById,
  getTemplates,
  getTemplatesByCategory,
  updateCategory,
} from "../services/templatesServices";

const useTemplateStore = create((set) => ({
  templateId: null,
  setTemplateId: (templateId) => set({ templateId }),
  templatesData: [],
  fetchTemplates: async (usage_type) => {
    try {
      const response = await getTemplates(usage_type);
      console.log("useTemplateStore: ", response);

      set({ templatesData: response });
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  },
  categoryData: [],
  fetchCategories: async () => {
    try {
      const response = await getCategories();
      set({ categoryData: response });
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  },
  templatesByCategoryData: [],
  fetchTemplatesByCategory: async (categoryId) => {
    try {
      const response = await getTemplatesByCategory(categoryId);
      set({ templatesByCategoryData: response });
    } catch (error) {
      console.error("Error fetching templates by category:", error);
    }
  },
  template: {
    template_name: "",
    description: "",
    category_name: "",
    messages: [],
    usage_type: "",
  },
  fetchSingleTemplate: async (templateId) => {
    try {
      const response = await getTemplateById(templateId);
      set({ template: response });
    } catch (error) {
      console.error("Error fetching template:", error);
    }
  },
  clearTemplate: () =>
    set({
      template: {
        template_name: "",
        description: "",
        category_name: "",
        usage_type: "",
        messages: [],
      },
    }),
  createCategory: async (categoryName, categoryType) => {
    return await createCategory(categoryName, categoryType);
  },
  deleteCategory: async (categoryId) => {
    try {
      await deleteCategory(categoryId);
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  },
  updateCategory: async (categoryId, categoryName, categoryType) => {
    return await updateCategory(categoryId, categoryName, categoryType);
  },
  selectedAgent: undefined,
  setSelectedAgent: (agent) => set({ selectedAgent: agent }),
}));

export default useTemplateStore;
