import { Navigate } from "react-router-dom";
import authService from "../services/authService";

export const PublicRoutes = ({ children }) => {
  const isLoggedIn = authService.isLoggedIn();

  if (isLoggedIn) {
    return <Navigate to={"/dashboard"} />;
  }
  return children;
};
