import { t } from "i18next";
import ButtonPrimary from "../../ui/ButtonPrimary";

const TextMusic = () => {
  return (
    <div className="">
      <div className="bg-gray-200 rounded p-2 mb-5">
        <textarea
          placeholder={t("Crea una cancion usando texto...")}
          className="w-full h-40 resize-none outline-none bg-gray-200"
        />
      </div>
      <div className={"button flex w-full justify-center items-center "}>
        <ButtonPrimary
          className={"w-full flex justify-center"}
          disabled={false}
          title={t("Generar")}
        />
      </div>
    </div>
  );
};

export default TextMusic;
