import { create } from "zustand";
import { generateVideo, pollTaskStatus } from "../services/generateServices";
import { generateProducts } from "../services/templatesServices";

const loadTaskConverterFromLocalStorage = () => {
  const storedTaskConverter = localStorage.getItem("taskConverter");
  return storedTaskConverter
    ? JSON.parse(storedTaskConverter)
    : { taskId: null, name: null, status: "Idle" };
};

export const useProductStore = create((set) => ({
  products: [],
  isPolling: false,
  setIsPolling: (value) => {
    set({ isPolling: value });
  },
  videoProducts: [],
  videoProcessing: async (file, device, srcLang, tgtLang) => {
    set({ isGenerating: true });
    try {
      const response = await generateVideo(file, device, srcLang, tgtLang);
      set({ videoProducts: response });
    } catch (error) {
      console.error("Error al generar videos:", error);
    }
  },
  isGenerating: false,
  setIsGenerating: (value) => set({ isGenerating: value }),
  addProducts: async (files) => {
    set({ isGenerating: true });
    try {
      const response = await generateProducts(2, files);
      set({ products: response });
    } catch (error) {
      console.error("Error al generar productos:", error);
    } finally {
      set({ isGenerating: false });
    }
  },
  clearProducts: () => set({ products: [] }),
  selectedOneLanguages: "Spanish",
  selectedTwoLanguages: "English",
  setSelectedOneLanguages: (value) => set({ selectedOneLanguages: value }),
  setSelectedTwoLanguages: (value) => set({ selectedTwoLanguages: value }),
  taskConverter: loadTaskConverterFromLocalStorage(),
  setTaskConverter: (taskConverter) => {
    set({ taskConverter });
    localStorage.setItem("taskConverter", JSON.stringify(taskConverter));
  },
  result: null,
  setResult: (result) => set({ result }),
  resumeTask: async () => {
    const { taskConverter, isPolling, setIsPolling } =
      useProductStore.getState();

    if (taskConverter && taskConverter.status === "Processing" && !isPolling) {
      setIsPolling(true);
      await pollTaskStatus(taskConverter.taskId);
      setIsPolling(false);
    }
  },
}));
