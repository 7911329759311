import { useCallback, useState } from "react";
import HeaderProofreader from "../../components/dashboard/proofreader/HeaderProofreader";
import Title from "./Title";
import TextAreaProofreader from "../../components/dashboard/proofreader/TextAreaProofreader";
import cutParagraph from "../../utils/cutParagraph";
import { maxCountProofreader } from "../../config/maxCountWords";
import { t } from "i18next";
import documentType from "../../config/documentType";
import proofreaderService from "../../services/proofreaderService";
import stripHtmlTags from "../../utils/stripHtmlTags";
import { toast } from "react-toastify";
import useLanguageStore from "../../store/langStore";
import modesProofreader from "../../config/modesProofreader";
import removeStyledSpans from "../../utils/removeSpans";

const Proofreader = () => {
  const [fromText, setFromText] = useState("");
  const [currentLang, setCurrentLang] = useState("Spanish");
  const [currentMode, setCurrentMode] = useState(
    Object.keys(modesProofreader)[0],
  );
  const [isProofreading, setIsProofreading] = useState(false);
  const [proofread, setProofread] = useState(null);
  const [currentDocType, setCurrentDocumentType] = useState(documentType[0]);
  const [content, setContent] = useState("");
  const { language } = useLanguageStore();

  const handleChangeLanguage = (value) => {
    setCurrentLang(value);
  };
  const handleModeChange = (value) => {
    setCurrentMode(value);
  };

  const handleDocChange = (value) => {
    setCurrentDocumentType(value);
  };

  const handleRemoveCandidate = (index) => {
    const newProofread = {
      ...proofread,
      result: {
        ...proofread.result,
        revised_words: proofread.result.revised_words.filter(
          (_, i) => i !== index,
        ),
      },
    };
    setProofread(newProofread);
  };

  const handleProofreader = async () => {
    let newContentHtml = fromText;
    // RESET THE STYLE content
    newContentHtml = removeStyledSpans(newContentHtml);
    setFromText(newContentHtml);

    setProofread(null);
    setIsProofreading(true);
    const plainTextContent = stripHtmlTags(fromText);
    setContent(plainTextContent);

    let newContent = plainTextContent;

    const response = await proofreaderService.proofreader(
      fromText,
      currentLang,
      currentMode,
      language === "es"
        ? t("Spanish")
        : language === "en"
          ? t("English")
          : t("Catalan"),
    );
    const responseParsed = JSON.parse(response);

    responseParsed.result.revised_words.forEach((word) => {
      newContentHtml = newContentHtml.replaceAll(
        word.word,
        `<span class="border-b-2 border-red-500">${word.word}</span>`,
      );
      const clearText = newContent
        .replaceAll(/\u00A0/g, " ")
        .replace(/\s+/g, " ");
      newContent = clearText.replaceAll(
        word.word,
        `<span class="border-b-2 border-secondary">${word.word}</span>`,
      );
    });

    setFromText(newContentHtml);
    setContent(newContent);
    setProofread(responseParsed);
    setIsProofreading(false);
  };

  const handleTextChange = useCallback((e) => {
    const contentHtml = e.currentTarget.innerHTML;
    if (contentHtml.trim().length === 0) {
      setProofread("");
    }
    const plainTextContent = stripHtmlTags(contentHtml);
    setFromText(contentHtml);
    setContent(cutParagraph(plainTextContent, maxCountProofreader));
  }, []);

  const handleAcceptChange = (originalWord, revisedWord) => {
    let newText = fromText.replaceAll(
      `<span class="border-b-2 border-red-500">${originalWord}</span>`,
      `${revisedWord}`, // Words already styled
    );

    const plainTextContent = stripHtmlTags(newText);

    if (plainTextContent.length > maxCountProofreader) {
      toast.error(t("El cambio excede el límite de palabras"));
      return false;
    }
    setContent(newText);
    setFromText(newText);
    return true;
  };

  const handleIgnoreAll = () => {
    const newProofread = {
      ...proofread,
      result: {
        ...proofread.result,
        revised_words: [],
      },
    };
    setProofread(newProofread);
  };

  const handleAcceptAllChange = () => {
    let results = proofread.result.revised_words;
    let newText = fromText;
    results.forEach((result) => {
      newText = newText.replaceAll(
        `<span class="border-b-2 border-red-500">${result.word}</span>`,
        `${result.revised}`, // Words already styled
      );
    });

    const newProofread = {
      ...proofread,
      result: {
        ...proofread.result,
        revised_words: [],
      },
    };
    setProofread(newProofread);
    setContent(newText);
    setFromText(newText);
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text/plain");
    const plainTextContent = stripHtmlTags(pastedText);
    const formattedText = pastedText.replace(/\n/g, "<br>");

    setFromText((prevText) => prevText + formattedText);
    setContent(cutParagraph(plainTextContent, maxCountProofreader));
  };
  return (
    <div className="flex flex-col h-full">
      <Title title={t("Corrector")} />
      <div className="flex flex-col flex-1 w-full h-[100%-45px] overflow-y-auto mx-auto bg-white rounded-2xl shadow-md ">
        <HeaderProofreader
          handleDocChange={handleDocChange}
          handleChangeLanguage={handleChangeLanguage}
          handleModeChange={handleModeChange}
          currentLang={currentLang}
          currentMode={currentMode}
          currentDocType={currentDocType}
        />
        <div className="flex-1 overflow-y-auto">
          <TextAreaProofreader
            handlePaste={handlePaste}
            handleIgnoreAll={handleIgnoreAll}
            handleAcceptAllChange={handleAcceptAllChange}
            handleTextChange={handleTextChange}
            handleProofreader={handleProofreader}
            handleRemoveCandidate={handleRemoveCandidate}
            handleAcceptChange={handleAcceptChange}
            content={content}
            fromText={fromText}
            isProofreading={isProofreading}
            maxCount={maxCountProofreader}
            proofread={proofread}
          />
        </div>
      </div>
    </div>
  );
};

export default Proofreader;
