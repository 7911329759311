import React, { useState } from "react";
import transcribeService from "../../services/transcribeService";
import useTranscribeStore from "../../store/transcribeStore";
import ListAudios from "../../components/dashboard/audioTranscribe/ListAudios";
import Shortcuts from "../../components/dashboard/audioTranscribe/Shortcuts";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useParams,
  Outlet,
} from "react-router-dom";
import Title from "./Title";
import { t } from "i18next";

const AudioTranscriber = () => {
  return (
    <div className="h-full w-full flex">
      <div className="flex-1">
        <div>
          <Title title={t("Audio a texto")} />
          <div className=" w-full grid grid-cols-12 md:grid-cols-8 lg:grid-cols-5 gap-6 justify-center">
            <Shortcuts />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioTranscriber;
