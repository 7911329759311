export const maxCountParaphraser = 25000;

export const maxCountTranslator = 25000;

export const maxCountProofreader = 25000;

export const maxCountSummarizer = 25000;

export const maxCountPlagiarism = 25000;

export const maxCountIADetector = 25000;

export const maxCountPromptImage = 1500;
