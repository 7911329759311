import { Slider } from "@radix-ui/themes";
import React, { useRef, useState } from "react";
import {
  FaPlay,
  FaPause,
  FaDownload,
  FaHeart,
  FaEllipsisH,
} from "react-icons/fa";

const MusicResultSide = ({ className }) => {
  const [songs, setSongs] = useState([
    {
      title: "Pista 1",
      duration: "0:31",
      audioSrc: "/assets/example2.mp3",
      isPlaying: false,
      currentTime: 0,
    },
    {
      title: "Pista 2",
      duration: "0:30",
      audioSrc: "/assets/example.mp3",
      isPlaying: false,
      currentTime: 0,
    },
    {
      title: "Pista 3",
      duration: "0:30",
      audioSrc: "/assets/example3.mp3",
      isPlaying: false,
      currentTime: 0,
    },
  ]);

  const audioRef = useRef(null);
  const [currentSongIndex, setCurrentSongIndex] = useState(null);

  const handlePlayPause = (index) => {
    const selectedSong = songs[index];

    if (currentSongIndex !== null && currentSongIndex !== index) {
      updateSongState(currentSongIndex, { isPlaying: false });
    }
    if (currentSongIndex !== index) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setCurrentSongIndex(index);
      audioRef.current = new Audio(selectedSong.audioSrc);
      audioRef.current.currentTime = selectedSong.currentTime;
    }

    if (selectedSong.isPlaying) {
      audioRef.current.pause();
      updateSongState(index, { isPlaying: false });
    } else {
      audioRef.current.play();
      updateSongState(index, { isPlaying: true });

      audioRef.current.ontimeupdate = () => {
        updateSongState(index, { currentTime: audioRef.current.currentTime });
      };

      audioRef.current.onended = () => {
        updateSongState(index, { isPlaying: false, currentTime: 0 });
        setCurrentSongIndex(null);
      };
    }
  };

  const getDuration = () => {
    return audioRef.current ? audioRef.current.duration : 1;
  };

  const handleSliderChange = (index, value) => {
    if (audioRef.current && currentSongIndex === index) {
      audioRef.current.currentTime = value[0];
      updateSongState(index, { currentTime: value[0] });
    }
  };

  const updateSongState = (index, newState) => {
    setSongs((prevSongs) =>
      prevSongs.map((song, i) =>
        i === index ? { ...song, ...newState } : song,
      ),
    );
  };

  return (
    <div className="w-full mx-auto">
      <h2 className="text-2xl font-semibold mb-4">Lo Fi Beats</h2>
      <div className="p-4 rounded-lg ">
        {songs.map((song, index) => (
          <div key={index} className="mb-4 last:mb-0">
            <div className="flex justify-between items-center mb-2 gap-2">
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => handlePlayPause(index)}
                  className="text-primary"
                >
                  {song.isPlaying ? <FaPause /> : <FaPlay />}
                </button>
                <div>
                  <p className="text-primary">{song.title}</p>
                  <p className="text-gray-500 text-sm">{song.duration}</p>
                </div>
              </div>
              <Slider
                variant="classic"
                size={"1"}
                className="w-full"
                min={0}
                max={getDuration()}
                step={0.1}
                value={[song.currentTime]}
                onValueChange={(value) => handleSliderChange(index, value)}
                aria-label="Song Progress"
                color="yellow"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Slider.Track className="bg-gray-700 h-1 rounded">
                  <Slider.Range className="bg-secondary h-1 rounded" />
                </Slider.Track>
                <Slider.Thumb className="block w-3 h-3 bg-secondary rounded-full shadow-md" />
              </Slider>
              <div className="flex items-center space-x-3">
                <button className="text-gray-400 hover:text-primary">
                  <FaDownload />
                </button>
                <button className="text-gray-400 hover:text-red-500">
                  <FaHeart />
                </button>
                <button className="text-gray-400 hover:text-primary">
                  <FaEllipsisH />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MusicResultSide;
