import React from "react";
import { Navigate } from "react-router-dom";
import authService from "../services/authService";

export const ProtectedRoute = ({ children }) => {
  const isLoggedIn = authService.isLoggedIn();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return children;
};
