import { toast } from "react-toastify";
import useTranslateStore from "../store/translateStore";
import authService from "./authService";
import { t } from "i18next";
import BASE_URL from "../config/api";

class TranslateService {
  constructor() {
    this.store = useTranslateStore.getState();
    this.apiUrl = BASE_URL;
  }

  setFromLanguage(language) {
    this.store.from = language;
  }
  setToLanguage(language) {
    this.store.to = language;
  }
  setLangList(listLangs) {
    this.store.listLangs = listLangs;
  }
  getFromLanguage() {
    return this.store.from;
  }

  getToLanguage() {
    return this.store.to;
  }
  getFilteredLanguages(isSource = true) {
    const currentLanguage = isSource
      ? this.getToLanguage()
      : this.getFromLanguage();
    return this.getAvailableLanguages().filter(
      (lang) => lang.name !== currentLanguage
    );
  }

  getAvailableLanguages() {
    return this.store.listLangs;
  }

  async loadLanguages() {
    if (this.store.listLangs.length !== 0) return;
    try {
      const response = await fetch("/languages.json");
      const data = await response.json();
      console.log("LANGS LOADED");

      this.store.setListLang(data.languages);
    } catch (error) {
      console.error("Error cargando los idiomas:", error);
    }
  }

  async translate(text) {
    const apiUrl = this.apiUrl + "/v1/novastudio/text-model-inference";
    const apiKey = authService.getToken();

    const requestBody = {
      input_text: `idioma a traducir: ${this.getToLanguage()}. Texto a traducir: ${text}`,
      template: "translator",
      temperature: 0.2,
      model: "gpt-4o-mini",
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 401) {
        toast.error(t("El token ha expirado"));
        authService.handleUnauthorized();
        return null;
      }
      const data = await response.json();

      return data.data.response || t("¡Opps! Algo ha salido mal");
    } catch (error) {
      console.log(error);

      toast.error(t("¡Opps! Algo ha salido mal"));
      return error;
    }
  }
}

export default TranslateService;
