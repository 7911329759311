const ButtonIcon = ({
  Icon,
  text,
  onClick = null,
  className = "selection:",
}) => {
  return (
    <button
      onClick={onClick}
      className={
        "flex items-center gap-1 border-[1px] border-gray-300 rounded py-1 px-2 hover:bg-secondary hover:text-primary transition-all  outline-none " +
        className
      }
    >
      <Icon />
      {text}
    </button>
  );
};

export default ButtonIcon;
