import OptionCardLayout from "./OptionCardLayout";

const StructureCard = ({ className }) => {
  return (
    <OptionCardLayout className={className}>
      <h1>StructureCard</h1>
    </OptionCardLayout>
  );
};
export default StructureCard;
