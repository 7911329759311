// src/AppRoutes.js
import { Route, Routes } from "react-router-dom";

import { t } from "i18next";
import Home from "../pages/Home";
import { PublicRoutes } from "./PublicRoutes";
import Login from "../pages/Login";
import { ProtectedRoute } from "./ProtectedRoutes";
import DashboardLayout from "../pages/layout/DashboardLayout";
import Dashboard from "../pages/Dashboard";
import Transcribe from "../pages/dashboard/Transcribe";
import SpellCheck from "../pages/dashboard/SpellCheck";
import AudioTranscriber from "../pages/dashboard/AudioTranscriber";
import ListAudios from "../components/dashboard/audioTranscribe/ListAudios";
import Paraphraser from "../pages/dashboard/Paraphraser";
import AudioTranscibed from "../components/dashboard/audioTranscribe/AudioTranscribed";
import Translator from "../pages/dashboard/TranslateText";
import Proofreader from "../pages/dashboard/Proofreader";
import Summarizer from "../pages/dashboard/Summarizer";
import Plagiarism from "../pages/dashboard/Plagiarism";
import IADetector from "../pages/dashboard/IADetector";
import TextToImage from "../pages/dashboard/ImagesPages/TextToImage";
import VideoProcessing from "../pages/dashboard/VideoProcessing";
import ChatsPage from "../pages/dashboard/ChatsPage";
import CodeGenerator from "../pages/dashboard/CodeGenerator";
import MusicGenerator from "../pages/dashboard/MusicGenerator";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/login"
        element={
          <PublicRoutes>
            <Login />
          </PublicRoutes>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="translate-text" element={<Translator />} />
        <Route path="transcribe" element={<Transcribe />} />
        <Route path="transcribe-text" element={<AudioTranscriber />}>
          <Route path="" element={<ListAudios />} />
          {/* TODO: Replace <SpellCheck /> with the real component */}
          <Route path="sin-categoria" element={<SpellCheck />} />
          <Route
            path="transcribe/:transcribeId"
            element={<AudioTranscibed />}
          />
        </Route>
        <Route path="paraphraser-text" element={<Paraphraser />} />
        <Route path="proofreader-text" element={<Proofreader />} />
        <Route path="summarizer-text" element={<Summarizer />} />
        <Route path="plagiarism-text" element={<Plagiarism />} />
        <Route path="iadetector-text" element={<IADetector />} />
        <Route path="generate-image" element={<TextToImage />} />
        <Route path="subtitle-video" element={<VideoProcessing />} />
        <Route path="generator-code" element={<CodeGenerator />} />
        <Route path="generator-music" element={<MusicGenerator />} />
        <Route
          path="assistant-chat"
          element={<ChatsPage chatType="normal" />}
        />
        <Route
          path="*"
          element={<h1 className="text-gray-500">{t("Trabajando en ello")}</h1>}
        />
      </Route>
      <Route path="/*" element={<h1>Opps! Ruta no encontrada</h1>} />
    </Routes>
  );
};

export default AppRoutes;
