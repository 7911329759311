import React, { useEffect, useRef, useState } from "react";
import { FaArrowsSpin, FaRegCirclePause } from "react-icons/fa6";
import WaveSurfer from "wavesurfer.js";
import RecordState from "./RecordState";
import TranslateService from "../../services/translateService";
import { Select } from "@radix-ui/themes";
import { FaRegPlayCircle } from "react-icons/fa";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.js";
import useTranslateStore from "../../store/translateStore";
import transcribeService from "../../services/transcribeService";
import useTranscribeStore from "../../store/transcribeStore";
import { t } from "i18next";

const FrequencyVisualizer = ({ closeModal }) => {
  const [translateService] = useState(new TranslateService());
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState("00:00");
  const [audioBlob, setAudioBlob] = useState(null);
  const [currentLang, setCurrentLang] = useState("Spanish");
  const [isTranscribeing, setIsTranscribeing] = useState(false);

  const wavesurferRef = useRef(null);
  const micRef = useRef();
  const recordRef = useRef(null);

  const createWaveSurfer = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy();
    }

    wavesurferRef.current = WaveSurfer.create({
      container: micRef.current,
      waveColor: "#F2CA52",
    });

    recordRef.current = wavesurferRef.current.registerPlugin(
      RecordPlugin.create({
        scrollingWaveform: false,
        renderRecordedAudio: false,
        mimeType: "audio/webm",
      })
    );

    recordRef.current.on("record-end", handleRecordEnd);
    recordRef.current.on("record-progress", handleRecordProgress);
  };

  const handleRecordEnd = async (blob) => {
    setIsTranscribeing(true);
    setAudioBlob(blob);

    setRecording(false);
    setPaused(false);
    try {
      const textTranscribeed = await transcribeService.transcribeAudio(
        blob,
        `Traduccion BanAIna ${new Date().getTime()}`
      );
      useTranscribeStore.getState().text = textTranscribeed;
      //Closing modal After transcribe
      closeModal();
    } catch (error) {
      console.error("Error en la transcripción:", error);
    } finally {
      setIsTranscribeing(false);
    }
  };

  const handleRecordProgress = (time) => {
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    const formattedTime = `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
    setProgress(formattedTime);
  };

  const handlePauseClick = () => {
    if (paused) {
      recordRef.current.resumeRecording();
      setPaused(false);
    } else {
      recordRef.current.pauseRecording();
      setPaused(true);
    }
  };

  const handleStopRecording = async () => {
    await recordRef.current.stopRecording();
  };

  const handleChangeLanguage = (value) => {
    setCurrentLang(value);
  };

  useEffect(() => {
    const loadLanguages = async () => {
      await translateService.loadLanguages();
    };
    loadLanguages();
    createWaveSurfer();
    const deviceId = 1;
    recordRef.current.startRecording({ deviceId }).then(() => {
      setRecording(true);
      setPaused(false);
      setTimeout(() => {
        recordRef.current.stopRecording();
      }, 300000); // 5 minutos
    });

    return () => {
      recordRef.current.destroy();
      wavesurferRef.current.destroy();
    };
  }, []);

  return (
    <div className="flex flex-col gap-4 items-center p-4">
      <div
        ref={micRef}
        id="mic"
        className="relative w-full h-48 rounded-lg bg-tertiary"
      >
        <p className="absolute bottom-0 right-1 text-sm ">{progress}</p>
        <div className="absolute bottom-0 left-1 text-sm flex gap-1 items-center">
          <RecordState isRecording={recording} isPaused={paused} />
        </div>
      </div>
      <div className="w-full">
        <p>{t("Idioma del audio")}</p>
        <Select.Root
          size="3"
          defaultValue={currentLang}
          onValueChange={handleChangeLanguage}
        >
          <Select.Trigger className="w-full" />
          <Select.Content>
            {useTranslateStore.getState().listLangs.map((lang) => (
              <Select.Item value={lang.name} key={lang.code}>
                {lang.name}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </div>
      <div className="flex flex-col w-full gap-4 mb-4">
        {/*audioBlob && (
          <audio controls>
            <source src={audioBlob} type="audio/mp3" />
          </audio>
        )*/}
        <button
          onClick={handleStopRecording}
          className="flex items-center justify-center gap-2 px-4 py-3 bg-primary text-secondary rounded uppercase font-semibold"
        >
          {t("Transcribir")}
          {isTranscribeing && (
            <FaArrowsSpin size={20} className="animate-spin" />
          )}
        </button>

        <button
          onClick={handlePauseClick}
          className="px-4 py-2 bg-transparent text-gray-500 opacity-90"
        >
          {paused ? (
            <div className="flex gap-1 items-center justify-center">
              <FaRegPlayCircle />
              <span>{t("Seguir grabación")}</span>
            </div>
          ) : (
            <div className="flex gap-1 items-center justify-center">
              <FaRegCirclePause /> {t("Pausar grabación")}
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default FrequencyVisualizer;
