import OptionCardLayout from "./OptionCardLayout";

const EnergyCard = ({ className }) => {
  return (
    <OptionCardLayout className={className}>
      <h1>EnergyCard</h1>
    </OptionCardLayout>
  );
};
export default EnergyCard;
