const listOfLangs = [
  "Assembly",
  "Bash",
  "C",
  "C++",
  "C#",
  "Clojure",
  "COBOL",
  "Dart",
  "Elixir",
  "Erlang",
  "F#",
  "Fortran",
  "Go",
  "Groovy",
  "Haskell",
  "HTML",
  "Java",
  "JavaScript",
  "Julia",
  "Kotlin",
  "Lisp",
  "Lua",
  "MATLAB",
  "Objective-C",
  "Pascal",
  "Perl",
  "PHP",
  "Python",
  "R",
  "Ruby",
  "Rust",
  "Scala",
  "Shell",
  "Solidity",
  "Swift",
  "TypeScript",
  "VB.NET",
  "VHDL",
  "Visual Basic",
  "XML",
  "Ninguno",
];

export default listOfLangs;
