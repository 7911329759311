import { create } from "zustand";

const useSessionStore = create((set) => ({
  isModalOpen: false,

  showModal: () => set({ isModalOpen: true }),

  closeModal: () => set({ isModalOpen: false }),
}));

export default useSessionStore;
