import { GoStack } from "react-icons/go";
import ExportButton from "../ui/ExportButton";
import { FaRegTrashAlt } from "react-icons/fa";
import useTranscribeStore from "../../store/transcribeStore";
import transcribeService from "../../services/transcribeService";
import { FaArrowsSpin } from "react-icons/fa6";
import { t } from "i18next";

const FooterTableTranscribe = () => {
  const selectedIds = useTranscribeStore((state) => state.selectedIds);
  const loading = useTranscribeStore((state) => state.loading);

  return (
    <div className="text-gray-500 border-t z-1 sticky bottom-0 w-full items-center justify-between pt-4 pb-4 pr-6 pl-6 flex">
      <div>
        <div className="flex items-center gap-2 text-lg font-bold">
          <GoStack size={24} />
          <span>{t("Multiple archivos")}</span>
        </div>
        <div>
          <span>{t("Selecciona multiples archivos para descargar")}</span>
        </div>
      </div>
      <div className="flex items gap-4">
        <ExportButton
          disabled={false}
          fileName={"BanAIna"}
          text={true}
          transcribes={selectedIds}
        />
        <button
          onClick={() => {
            transcribeService.deletetranscribes(selectedIds.values());
          }}
          disabled={loading}
          className="bg-red-500 text-white font-semibold p-4 rounded"
        >
          {loading ? (
            <FaArrowsSpin size={24} className="animate-spin" />
          ) : (
            <FaRegTrashAlt size={24} />
          )}
        </button>
      </div>
    </div>
  );
};

export default FooterTableTranscribe;
