import { downloadGeneratedFile } from "../../services/generateServices";
import { useProductStore } from "../../store/generatorStore";
import { t } from "i18next";
import "../../assets/css/tableStyle.css";

const VideoStatusTable = () => {
  const { taskConverter } = useProductStore((state) => ({
    result: state.result,
    taskConverter: state.taskConverter,
  }));

  const handleDownload = () => {
    if (taskConverter.taskId) {
      downloadGeneratedFile(taskConverter.taskId);
    }
  };

  return (
    <div className="p-6 w-full">
      <table className="shadow-md">
        <thead>
          <tr>
            <th className="text-center">{t("Nombre")}</th>
            <th className="text-center">{t("Acciones")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center align-middle">{taskConverter.name}</td>
            <td className="text-center ">
              {taskConverter.status === "Processing" ? (
                <p className="my-0 mx-auto">{t("Procesando video")}...</p>
              ) : (
                <button
                  onClick={handleDownload}
                  className="my-0 mx-auto py-2 px-4 rounded flex items-center font-medium text-sm bg-secondary text-primary hover:bg-primary hover:text-secondary transition-colors"
                >
                  {t("Descargar")}
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default VideoStatusTable;
