import axios from "axios";
import BASE_URL from "../config/api";
import authService from "./authService";

export const createTemplate = async (templateData) => {
  const token = authService.getToken();
  const { name, description, category, usage, systemPrompt, userPrompt } =
    templateData;
  //TODO: ajustar este bloque para que tire de adataror
  const body = {
    template_name: name,
    messages: [
      {
        content: systemPrompt,
        role: "system",
      },
    ],
    category_name: category,
    usage_type: usage,
    description: description,
  };

  if (userPrompt?.length >= 1) {
    body.messages.push({
      content: userPrompt,
      role: "user",
    });
  }

  try {
    const response = await axios.post(
      `${BASE_URL}/v1/novastudio/add-new-template`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else {
        throw new Error(error.message || "An unknown error occurred");
      }
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const generateProducts = async (templateId, documents) => {
  const token = authService.getToken();
  const formData = new FormData();
  formData.append("template_id", templateId.toString());
  documents.forEach((document) => {
    formData.append("files", document);
  });

  const response = await axios.post(
    `${BASE_URL}/v1/novastudio/generate-product-sheets?template_id=${templateId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data.data.results;
};

export const getTemplates = async (usage_type = "") => {
  const token = authService.getToken();
  const url = usage_type
    ? `${BASE_URL}/v1/novastudio/list-all-templates?usage_type=${encodeURIComponent(
        usage_type
      )}`
    : `${BASE_URL}/v1/novastudio/list-all-templates`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("getTemplates: 0", response);

    return response.data.data.templates;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else {
        throw new Error(error.message || "An unknown error occurred");
      }
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const updateTemplate = async (id, templateData) => {
  const token = authService.getToken();
  const { name, description, category, usage, systemPrompt, userPrompt } =
    templateData;

  const body = {
    template_name: name,
    messages: [
      {
        content: systemPrompt,
        role: "system",
      },
    ],
    category_name: category,
    usage_type: usage,
    description: description,
  };

  if (userPrompt?.length >= 1) {
    body.messages.push({
      content: userPrompt,
      role: "user",
    });
  }

  try {
    const response = await axios.put(
      `${BASE_URL}/v1/novastudio/edit-template/${id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data.template;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else {
        throw new Error(error.message || "An unknown error occurred");
      }
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const getTemplateById = async (id) => {
  const token = authService.getToken();
  try {
    const response = await axios.get(
      `${BASE_URL}/v1/novastudio/template/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data.template;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else {
        throw new Error(error.message || "An unknown error occurred");
      }
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const deleteTemplate = async (id) => {
  const token = authService.getToken();
  try {
    const response = await axios.delete(
      `${BASE_URL}/v1/novastudio/delete-template/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else {
        throw new Error(error.message || "An unknown error occurred");
      }
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const getCategories = async () => {
  const token = authService.getToken();
  try {
    const response = await axios.get(
      `${BASE_URL}/v1/novastudio/categories/list-all-categories`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data.categories;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else {
        throw new Error(error.message || "An unknown error occurred");
      }
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const getTemplatesByCategory = async (categoryId) => {
  const token = authService.getToken();
  try {
    const response = await axios.get(
      `${BASE_URL}/v1/novastudio/templates-by-category/${categoryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data.templates;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else {
        throw new Error(error.message || "An unknown error occurred");
      }
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

export const searchCategory = async (searchData) => {
  const token = authService.getToken();
  try {
    const response = await axios.get(
      `${BASE_URL}/v1/novastudio/search-categories?search_term=${searchData}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        console.error(error.response.data.detail);
      } else {
        console.error(error.message || "An unknown error occurred");
      }
    } else {
      console.error("An unknown error occurred");
    }
    return [];
  }
};

export const createCategory = async (categoryName, categoryType) => {
  const token = authService.getToken();
  try {
    const response = await axios.post(
      `${BASE_URL}/v1/novastudio/categories`,
      {
        category_type: categoryType,
        categoryname: categoryName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        console.error(error.response.data.detail);
      } else {
        console.error(error.message || "An unknown error occurred");
      }
    } else {
      console.error("An unknown error occurred");
    }
    return [];
  }
};

export const deleteCategory = async (categoryId) => {
  const token = authService.getToken();
  try {
    const response = await axios.delete(
      `${BASE_URL}/v1/novastudio/categories/${categoryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        console.error(error.response.data.detail);
      } else {
        console.error(error.message || "An unknown error occurred");
      }
    } else {
      console.error("An unknown error occurred");
    }
    return [];
  }
};

export const updateCategory = async (
  categoryId,
  categoryName,
  categoryType
) => {
  const token = authService.getToken();
  try {
    const response = await axios.put(
      `${BASE_URL}/v1/novastudio/categories/${categoryId}`,
      {
        categoryname: categoryName,
        category_type: categoryType,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data && error.response.data.detail) {
        console.error(error.response.data.detail);
      } else {
        console.error(error.message || "An unknown error occurred");
      }
    } else {
      console.error("An unknown error occurred");
    }
    return [];
  }
};
