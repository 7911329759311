import { create } from "zustand";
import { persist } from "zustand/middleware";

const useTranslateStore = create(
  persist(
    (set) => ({
      from: "Spanish",
      to: "English",
      listLangs: [],
      setFromLanguage: (lang) => set((state) => ({ from: lang })),
      setToLanguage: (lang) => set((state) => ({ to: lang })),
      setListLang: (listL) => set((state) => ({ listLangs: listL })),
    }),
    {
      name: "translate-storage",
    }
  )
);

export default useTranslateStore;
