// components/SessionExpiredModal.js
import { AlertDialog, Button, Flex } from "@radix-ui/themes";

const SessionExpiredModal = ({ open }) => {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>Sesión expirada</AlertDialog.Title>
        <AlertDialog.Description size="2">
          Serás redirigido a la pantalla de login.
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Action>
            <Button variant="solid" color="yellow">
              Ok
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

export default SessionExpiredModal;
