export default function stripHtmlToExport(html) {
  // Reemplazar las etiquetas <br> por saltos de línea
  let text = html.replace(/<br\s*\/?>/gi, "\n");

  // Reemplazar las etiquetas de bloque por saltos de línea
  text = text.replace(/<\/(p|div|h[1-6]|li|ol|ul|blockquote)>/gi, "\n");

  // Eliminar cualquier otra etiqueta HTML
  text = text.replace(/<[^>]+>/g, "");

  // Limpiar múltiples saltos de línea consecutivos
  text = text.replace(/\n\s*\n/g, "\n\n");

  return text.trim();
}
