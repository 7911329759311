import InputTypeOptions from "./InputTypeOptions";
import GeneratorMusic from "./GeneratorMusic";
import "../../../assets/css/musicGenerator.css";
import useMusicStore from "../../../store/musicStore";
import TextMusic from "./TextMusic";

const OptionsSide = ({ className }) => {
  const { inputType } = useMusicStore();
  return (
    <div className={className}>
      <InputTypeOptions />
      {inputType === "generator" && <GeneratorMusic />}
      {inputType === "text-music" && <TextMusic />}
    </div>
  );
};

export default OptionsSide;
