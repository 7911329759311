import { isToday, isAfter, subDays, format } from "date-fns";

const categorizeChats = (chats) => {
  const today = [];
  const week = [];
  const month = [];
  const previousMonths = {};

  const sevenDaysAgo = subDays(new Date(), 7);
  const thirtyDaysAgo = subDays(new Date(), 30);

  chats.forEach((chat) => {
    const updatedAt = new Date(chat.updated_at);

    if (isToday(updatedAt)) {
      today.push(chat);
    } else if (isAfter(updatedAt, sevenDaysAgo)) {
      week.push(chat);
    } else if (isAfter(updatedAt, thirtyDaysAgo)) {
      month.push(chat);
    } else {
      const chatMonth = format(updatedAt, "MMMM yyyy"); // Ej: "Septiembre 2024"

      if (!previousMonths[chatMonth]) {
        previousMonths[chatMonth] = [];
      }
      previousMonths[chatMonth].push(chat);
    }
  });

  return { today, week, month, previousMonths };
};

export default categorizeChats;
