import OptionCardLayout from "./OptionCardLayout";

const DurationCard = ({ className }) => {
  return (
    <OptionCardLayout className={className}>
      <h1>DurationCard</h1>
    </OptionCardLayout>
  );
};
export default DurationCard;
