import { MdOutlineContentCopy } from "react-icons/md";
import ExportButton from "../../ui/ExportButton";
import copyClipboard from "../../../utils/copyClipboard";
import ActionParaphrase from "./ActionParaphrase";

const TextAreasParaphraser = ({
  fromText,
  handleTextChange,
  maxCount,
  handleParaphraser,
  isParaphrasing,
  textParaphrased,
}) => {
  return (
    <div className="relative flex h-full w-full">
      <div className="flex flex-col flex-1">
        <textarea
          value={fromText}
          onChange={handleTextChange}
          className="flex-1 w-full max-w-full !break-words h-full outline-none resize-none p-6 text-gray-500 overflow-y-auto"
        />
        <div className="flex items-center justify-end gap-2 p-6">
          <ActionParaphrase
            count={fromText.length}
            maxCount={maxCount}
            handleParaphraser={handleParaphraser}
            isLoading={isParaphrasing}
          />
        </div>
      </div>

      <div className="flex flex-col flex-1 border-l-[1px]">
        <textarea
          className="flex-1 w-full max-w-full !break-words h-full outline-none resize-none p-6 text-gray-500 overflow-y-auto"
          value={textParaphrased}
          readOnly
        />
        <div className="flex items-center justify-end gap-2 p-6">
          <div className="flex w-full items-center justify-end gap-2 px-6">
            <ExportButton
              disabled={textParaphrased && !isParaphrasing ? false : true}
              text={textParaphrased}
              fileName={`BanAIna ${new Date().getTime()}`}
            />
            <MdOutlineContentCopy
              size={24}
              onClick={() => copyClipboard(textParaphrased)}
              className={`${
                textParaphrased ? "cursor-pointer opacity-100" : "opacity-50"
              } `}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextAreasParaphraser;
