import { ToastContainer } from "react-toastify";
import { Theme } from "@radix-ui/themes";
import Navbar from "./components/Navbar";
import { useTranslation } from "react-i18next";
import useLanguageStore from "./store/langStore";
import { useEffect } from "react";
import AppRoutes from "./routes/Routes";
import { useProductStore } from "./store/generatorStore";

const App = () => {
  const { i18n } = useTranslation();
  const { language } = useLanguageStore();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);
  return (
    <Theme className="flex flex-col max-h-screen h-screen bg-tertiary selection:text-primary selection:bg-secondary ">
      <Navbar />
      <div className="h-[calc(100vh-64px)]">
        <AppRoutes />
      </div>
      <ToastContainer autoClose={750} hideProgressBar={true} />
    </Theme>
  );
};

export default App;
