const Logo = ({ className = "" }) => {
  return (
    <a href="https://banaina.com">
      <img
        src="/assets/logo-banaina/logo-horizontal.png"
        className={" " + className}
        alt="Logo BanAIna"
      />
    </a>
  );
};

export default Logo;
