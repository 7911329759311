import { create } from "zustand";
import { getChatMessages, getChats } from "../services/chatsServices";
import categorizeChats from "../utils/categorizeChats";

const useChatStore = create((set, get) => ({
  isSending: false,
  newMessage: false,
  filteredChats: [],
  isGettingHistory: false,
  chatId: null,
  messages: [],
  setIsGettingHistory: (value) => set({ isGettingHistory: value }),
  setIsSending: (isSending) => set({ isSending }),
  setNewMessage: (newMessage) => set({ newMessage }),
  setFilteredChats: (filteredChats) => set({ filteredChats }),
  setChatId: (chatId) => set({ chatId }),
  setMessages: (messages) =>
    set((state) => ({
      messages:
        typeof messages === "function" ? messages(state.messages) : messages,
    })),
  fetchChats: async () => {
    try {
      const chats = await getChats();
      const categorizedChats = categorizeChats(chats.data.chats_summary);

      set({
        filteredChats: categorizedChats,
        filterData: chats.data.chats_summary,
        categorizedChats,
      });
    } catch (error) {
      console.log(error);
      console.error("Error:", error);
    }
  },
  categorizedChats: [],
  setCategorizedChats: (newValue) => set({ categorizedChats: newValue }),
  filterData: [],
  setFilterData: (filterData) => set({ filterData }),
  messagesHistory: null,
  loading: false,
  setLoading: (loading) => set({ loading }),
  setMessagesHistory: (messagesHistory) => set({ messagesHistory }),
  fetchMessages: async (chatId) => {
    set({ loading: true });
    try {
      const response = await getChatMessages(chatId);
      set({ messagesHistory: response });
      return response;
    } catch (error) {
      console.error("Error:", error);
    } finally {
      set({ loading: false });
    }
  },
  chatType: undefined,
  setChatType: (type) => set({ chatType: type }),
  selectedChatId: null,
  setSelectedChatId: (selectedChatId) => set({ selectedChatId }),
}));

export default useChatStore;
