// ButtonIconsPanel.jsx
import React from "react";
import { BsStars, BsEmojiKiss } from "react-icons/bs";
import { FaTh } from "react-icons/fa";
import { TbHexagon3D, TbTriangleSquareCircleFilled } from "react-icons/tb";
import { LiaPhotoVideoSolid } from "react-icons/lia";
import ButtonIcon from "../../../components/dashboard/textToImage/ButtonIcon";
import { maxCountPromptImage } from "../../../config/maxCountWords";
import { t } from "i18next";

const ButtonIconsPanel = ({ onClick, config, prompt }) => {
  const handleOptions = (value) => {
    onClick({ name: "mode", value });
  };
  return (
    <div className="flex gap-2 flex-wrap justify-between">
      <div className="flex gap-2 flex-wrap">
        <ButtonIcon
          onClick={() => handleOptions("cualquiera")}
          Icon={BsStars}
          text={t("Auto")}
          className={
            config.mode === "cualquiera" ? "bg-secondary text-primary" : ""
          }
        />
        <ButtonIcon
          onClick={() => handleOptions("general")}
          Icon={FaTh}
          text={t("General")}
          className={
            config.mode === "general" ? "bg-secondary text-primary" : ""
          }
        />
        <ButtonIcon
          onClick={() => handleOptions("realista")}
          Icon={LiaPhotoVideoSolid}
          text={t("Realista")}
          className={
            config.mode === "realista" ? "bg-secondary text-primary" : ""
          }
        />
        <ButtonIcon
          onClick={() => handleOptions("diseño")}
          Icon={TbTriangleSquareCircleFilled}
          text={t("Diseño")}
          className={
            config.mode === "diseño" ? "bg-secondary text-primary" : ""
          }
        />
        <ButtonIcon
          onClick={() => handleOptions("3d")}
          Icon={TbHexagon3D}
          text={t("3D")}
          className={config.mode === "3d" ? "bg-secondary text-primary" : ""}
        />
        <ButtonIcon
          onClick={() => handleOptions("anime")}
          Icon={BsEmojiKiss}
          text={t("Anime")}
          className={config.mode === "anime" ? "bg-secondary text-primary" : ""}
        />
      </div>
      <span
        className={`text-sm flex items-center  ${
          prompt.length > maxCountPromptImage
            ? "text-red-500 font-semibold"
            : "text-gray-500"
        }`}
      >
        {prompt.length} / {maxCountPromptImage} {t("Caracteres")}
      </span>
    </div>
  );
};

export default ButtonIconsPanel;
