import { t } from "i18next";
import { useState, useEffect, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import InputSearch from "./InputSearch";

const SelectUI = ({ options, action, defaultValue = null }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const containerRef = useRef(null);

  const handleSelect = (value) => {
    setSelectedValue(value);
    action(value);
    setSearchTerm("");
    setIsOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const results = options.filter((option) =>
      t(option.name).toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredOptions(results);
  }, [searchTerm, options, defaultValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={containerRef}>
      <div
        className="flex items-center justify-between w-full p-2 rounded cursor-pointer text-gray-500 text-sm"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{t(defaultValue) || "Selecciona algo"}</span>
        <span className="text-gray-500">
          <FaChevronUp
            className={`transform transition-transform duration-300 ${
              isOpen ? "rotate-0" : "rotate-180"
            }`}
          />
        </span>
      </div>
      {isOpen && (
        <div className="absolute left-0 w-full mt-2 border border-gray-300 bg-white shadow-lg rounded text-gray-500">
          <InputSearch
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
          />
          <ul className="max-h-60 w-full overflow-y-auto grid sm:grid-cols-3 gap-4">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((el) => (
                <li
                  key={el.code}
                  className="p-2 hover:text-secondary cursor-pointer rounded"
                  onClick={() => handleSelect(el.name)}
                >
                  {t(el.name)}
                </li>
              ))
            ) : (
              <li className="p-2 text-gray-500">No hay opciones</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectUI;
