import { Text } from "@radix-ui/themes/dist/cjs/components/callout.js";
import {
  HoverCard,
  SegmentedControl,
  Strong,
} from "@radix-ui/themes/dist/cjs/index.js";
import { LuRectangleHorizontal, LuRectangleVertical } from "react-icons/lu";
import { FaRegSquare } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";

import { t } from "i18next";
import { Popover } from "@radix-ui/themes";

const ConfigOptions = ({ onConfig }) => {
  const handleConfig = (name, value) => {
    onConfig({
      name,
      value,
    });
  };
  return (
    <div className="mt-6">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center w-full">
          <Popover.Root>
            <Popover.Trigger className="min-w-32 cursor-pointer hover:underline">
              <Text className="flex items-center gap-1">
                {t("Magic Prompt")} <IoAlertCircleOutline />
              </Text>
            </Popover.Trigger>
            <Popover.Content size="2" maxWidth="280px">
              <Text as="div" size="2" trim="both">
                <Strong>{t("Magic Prompt")} </Strong>
                {t(
                  "mejora tu mensaje inicial para aumentar la variedad y riqueza de las imágenes"
                )}
              </Text>
            </Popover.Content>
          </Popover.Root>
          <SegmentedControl.Root
            defaultValue="auto"
            className="w-full max-w-60"
          >
            <SegmentedControl.Item value="auto">
              {t("Auto")}
            </SegmentedControl.Item>
            <SegmentedControl.Item value="on">{t("On")}</SegmentedControl.Item>
            <SegmentedControl.Item value="off">
              {t("Off")}
            </SegmentedControl.Item>
          </SegmentedControl.Root>
        </div>

        <div className="flex justify-between items-center">
          <span className="min-w-32">{t("Aspect ratio")}</span>
          <SegmentedControl.Root
            onValueChange={(val) => handleConfig("ratio", val)}
            defaultValue="1024x1024"
            className="w-full max-w-60"
          >
            <SegmentedControl.Item value="1024x1792">
              <div className="flex gap-1 w-full items-center">
                <LuRectangleVertical />
                <span>9:16</span>
              </div>
            </SegmentedControl.Item>
            <SegmentedControl.Item value="1024x1024">
              <div className="flex gap-1 w-full items-center">
                <FaRegSquare />
                <span>1:1</span>
              </div>
            </SegmentedControl.Item>
            <SegmentedControl.Item value="1792x1024">
              <div className="flex gap-1 w-full items-center">
                <LuRectangleHorizontal />
                <span>16:9</span>
              </div>
            </SegmentedControl.Item>
          </SegmentedControl.Root>
        </div>
      </div>
    </div>
  );
};

export default ConfigOptions;
