import { Navigate } from "react-router-dom";

const Home = () => {
  const user = null;
  return user ? (
    <h1 className="text-xl text-red-500">Home</h1>
  ) : (
    <Navigate to={"/login"} />
  );
};

export default Home;
