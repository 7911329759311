const cutParagraph = (text, limit) => {
  let newText = text;
  if (text.length > limit) {
    newText = text.slice(0, limit);
  }

  return newText;
};

export default cutParagraph;
