import React, { useState } from "react";
import cutParagraph from "../../utils/cutParagraph";
import Title from "./Title";
import { t } from "i18next";
import { maxCountSummarizer } from "../../config/maxCountWords";
import ActionPlagiarism from "../../components/dashboard/plagiarism/ActionPlagiarism";
import { IoAlertCircleSharp } from "react-icons/io5";
import plagiarismService from "../../services/plagiarismService";
import { HoverCard, Text } from "@radix-ui/themes";
import { FaCheckCircle } from "react-icons/fa";

const Plagiarism = () => {
  const [fromText, setFromText] = useState("");
  const [scanedText, setScanedText] = useState([]);
  const [showMessageInfo, setShowMessageInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countChar, setCountChar] = useState(0);

  const handleTextChange = (e) => {
    setFromText(cutParagraph(e.target.value, maxCountSummarizer));
    setCountChar(cutParagraph(e.target.value, maxCountSummarizer).length);
    setScanedText([]);
    setShowMessageInfo(false);
  };

  const handleSummarizer = async () => {
    setIsLoading(true);
    setScanedText([]);
    setShowMessageInfo(false);
    try {
      const response = await plagiarismService.scanForPlagiarism(fromText);

      const responseParsed = JSON.parse(response);

      setScanedText(responseParsed);
      setShowMessageInfo(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <Title title={t("Plagio")} />
      <div className="flex flex-col w-full h-full mx-auto bg-white rounded-2xl shadow-md">
        <div className="flex flex-1">
          <div className="flex-[2] flex flex-col bg-white rounded-tl-2xl rounded-bl-2xl">
            <textarea
              value={fromText}
              onChange={handleTextChange}
              className="flex-1 w-full max-w-full !break-words h-full outline-none resize-none p-6 text-gray-500 overflow-y-auto rounded-tl-2xl"
            />
            <div className="flex items-center justify-end gap-2 p-6">
              <ActionPlagiarism
                count={countChar}
                handleSummarizer={handleSummarizer}
                isLoading={isLoading}
                maxCount={maxCountSummarizer}
              />
            </div>
          </div>

          <div className="flex-1 bg-white rounded-tr-2xl rounded-br-2xl border-l-2 ">
            {showMessageInfo && (
              <div className="p-6">
                {scanedText.length > 0 ? (
                  <>
                    <div className="flex items-center mb-6">
                      <IoAlertCircleSharp className="h-16 w-16 text-red-500 flex-1" />
                      <p className="text-lg flex-[2]">
                        <span className="font-bold">
                          Encontramos plagios significativos
                        </span>{" "}
                        en tu texto, detectamos {scanedText.length} plagios.
                      </p>
                    </div>
                    <div className="flex flex-wrap w-full justify-between">
                      {scanedText.map((el) => (
                        <div
                          key={el.sentence}
                          className="flex items-center justify-between gap-2 w-[46%] py-2 border-b-[1px] border-gray-300"
                        >
                          <span className="truncate w-24">{el.sentence}</span>
                          <HoverCard.Root>
                            <HoverCard.Trigger>
                              <span className="flex shrink-0 items-center justify-center bg-red-500 text-white font-semibold rounded-full w-6 h-6 cursor-pointer">
                                {el.count}
                              </span>
                            </HoverCard.Trigger>
                            <HoverCard.Content size="1" maxWidth="240px">
                              <Text as="div" size="1" trim="both">
                                {el.reason}
                              </Text>
                            </HoverCard.Content>
                          </HoverCard.Root>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="flex items-center mb-6">
                    <FaCheckCircle className="h-16 w-16 text-green-500 flex-1" />
                    <p className="text-lg flex-[2]">
                      <span className="font-bold">
                        No Encontramos plagio alguno
                      </span>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plagiarism;
