import { LuFiles } from "react-icons/lu";
import useTranscribeStore from "../../../store/transcribeStore";
import { Dialog } from "@radix-ui/themes";
import { FaCloudUploadAlt, FaMicrophoneAlt } from "react-icons/fa";
import FrequencyVisualizer from "../../ui/FrequencyVisualizer";
import TableTranscribe from "../TableTranscribe";
import FooterTableTranscribe from "../FooterTableTranscribe";
import { useState } from "react";
import transcribeService from "../../../services/transcribeService";
import { t } from "i18next";

const ListAudios = () => {
  const [audioFile, setAudioFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedIds = useTranscribeStore((state) => state.selectedIds);
  const handleAudioUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAudioFile(file);
      transcribeAudio(file);
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const transcribeAudio = async (audio) => {
    const textTranscribeed = await transcribeService.transcribeAudio(
      audio,
      `Traduccion BanAIna ${new Date().getTime()}`
    );
    useTranscribeStore.getState().text = textTranscribeed;
  };

  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col space-y-6 w-full bg-white shadow-lg rounded-lg overflow-y-auto">
      <div className="flex justify-between p-6">
        <h2 className="text-2xl font-bold text-gray-500 mb-6">
          <LuFiles className="inline-block mr-2" />
          {t("Archivos")}
        </h2>

        <div className="flex justify-between gap-2 items-center mb-6">
          <Dialog.Root open={isModalOpen} onOpenChange={setIsModalOpen}>
            <Dialog.Trigger className="p-1 cursor-pointer hover:bg-gray-200 rounded">
              <span>
                <FaMicrophoneAlt size={24} className="text-gray-500" />
              </span>
            </Dialog.Trigger>
            <Dialog.Content>
              <Dialog.Title>
                <div className="flex justify-center gap-2 text-gray-500">
                  <FaMicrophoneAlt size={24} />
                  {t("Grabar")}
                </div>
              </Dialog.Title>
              <FrequencyVisualizer closeModal={handleCloseModal} />
            </Dialog.Content>
          </Dialog.Root>

          <div className="flex items-center">
            <input
              id="file-upload"
              type="file"
              accept="audio/*"
              onChange={handleAudioUpload}
              className="hidden"
            />
            <label
              htmlFor="file-upload"
              className="bg-secondary flex items-center gap-1 text-primary font-semibold py-2 px-4 rounded-md cursor-pointer"
            >
              <FaCloudUploadAlt />
              {t("Subir Audio")}
            </label>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto relative h-full max-h-[calc(100vh-308px)]">
        <TableTranscribe />
      </div>
      {selectedIds.size > 0 && <FooterTableTranscribe />}
    </div>
  );
};

export default ListAudios;
