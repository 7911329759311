import React, { useState, useEffect } from "react";
import TranslateService from "../../services/translateService";
import SelectUI from "../../components/ui/Select";
import { Skeleton } from "@radix-ui/themes";
import { GoArrowSwitch } from "react-icons/go";
import CharacterCounter from "../../components/dashboard/CountWords";
import { MdOutlineContentCopy } from "react-icons/md";
import ExportButton from "../../components/ui/ExportButton";
import cutParagraph from "../../utils/cutParagraph";
import Title from "./Title";
import { t } from "i18next";
import copyClipboard from "../../utils/copyClipboard";
import { maxCountTranslator } from "../../config/maxCountWords";

const Translator = () => {
  const [translateService] = useState(new TranslateService());
  const [translatedText, setTranslatedText] = useState("");
  const [sourceLang, setSourceLang] = useState("");
  const [targetLang, setTargetLang] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isTranslating, setIsTranslating] = useState(false);
  const [countChar, setCountChar] = useState(0);
  const [fromText, setFromText] = useState("");

  const handleTranslate = async () => {
    if (countChar > maxCountTranslator) return;
    setIsTranslating(true);
    const translated = await translateService.translate(fromText);

    setTranslatedText(translated);
    setIsTranslating(false);
  };

  const handleChangeLanguage = (value, isSourceLang) => {
    if (value === (isSourceLang ? targetLang : sourceLang)) return;
    if (isSourceLang) {
      setSourceLang(value);
      translateService.setFromLanguage(value);
      setTranslatedText("");
      setFromText("");
    } else {
      setTargetLang(value);
      translateService.setToLanguage(value);
      if (fromText.trim()) handleTranslate();
    }
  };

  const handleTextChange = (e) => {
    setTranslatedText("");

    setFromText(cutParagraph(e.target.value, maxCountTranslator));
    setCountChar(fromText.length);
  };

  const handleSwitchLang = () => {
    if (isTranslating) return;

    const fromLang = sourceLang;
    const curentFromText = fromText;

    setSourceLang(targetLang);
    translateService.setFromLanguage(targetLang);
    setTargetLang(fromLang);
    translateService.setToLanguage(fromLang);

    if (!translatedText) return;

    setTranslatedText(curentFromText);
    setFromText(translatedText);
  };

  const loadLanguages = async () => {
    await translateService.loadLanguages();
    setSourceLang(translateService.getFromLanguage());
    setTargetLang(translateService.getToLanguage());
    setIsLoading(false);
  };
  useEffect(() => {
    loadLanguages();
  }, []);

  if (isLoading) {
    return (
      <div className="w-full h-full mx-auto p-6">
        <Skeleton className="w-3/4 h-8 mb-4" />
        <div className="flex gap-2 h-full">
          <div className="flex-1 bg-white shadow-md rounded-2xl">
            <div className="relative flex w-full items-center gap-2 px-6 py-2 border-b-[1px]">
              <Skeleton className="w-1/2 h-10" />
              <Skeleton className="w-8 h-8" />
            </div>

            <Skeleton className="w-full h-52 outline-none resize-none p-6" />
            <Skeleton className="w-full h-12" />
          </div>

          <div className="flex-1 bg-white shadow-md rounded-2xl">
            <div className="relative flex w-full items-center gap-2 px-6 py-2 border-b-[1px]">
              <Skeleton className="w-1/2 h-10" />
              <Skeleton className="w-8 h-8" />
            </div>

            <Skeleton className="w-full h-52 outline-none resize-none p-6" />
            <Skeleton className="w-full h-12" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <Title title={t("Traductor")} />
      <div className="relative flex gap-2 h-full">
        <div className="flex flex-col flex-1 bg-white shadow-md rounded-tl-2xl rounded-bl-2xl">
          <div className=" flex w-full items-center gap-2 p-4 border-b-[1px]">
            <SelectUI
              options={translateService.getFilteredLanguages(true)}
              action={(val) => handleChangeLanguage(val, true)}
              defaultValue={sourceLang}
            />
            <GoArrowSwitch
              onClick={handleSwitchLang}
              size={24}
              className="text-gray-500 cursor-pointer"
            />
          </div>

          <textarea
            value={fromText}
            onChange={handleTextChange}
            className="flex-1 w-full max-w-full !break-words h-full outline-none p-6 text-gray-500 overflow-y-auto resize-none"
          />
          <div className="flex items-center justify-end gap-2 p-6">
            <CharacterCounter
              count={fromText.length}
              maxCount={maxCountTranslator}
              onTranslate={handleTranslate}
              isLoading={isTranslating}
            />
          </div>
        </div>

        <div className="flex flex-col flex-1 bg-white shadow-md rounded-tr-2xl rounded-br-2xl">
          <div className="flex w-full p-4 border-b-[1px]">
            <SelectUI
              options={translateService.getFilteredLanguages(false)}
              action={(val) => handleChangeLanguage(val, false)}
              defaultValue={targetLang}
            />
          </div>
          <textarea
            className="flex-1 w-full max-w-full !break-words h-full outline-none p-6 text-gray-500 overflow-y-auto resize-none"
            value={translatedText}
            readOnly
          />
          <div className="flex items-center justify-end gap-2 p-6">
            <div className="flex w-full items-center justify-end gap-2 px-6">
              <ExportButton
                disabled={translatedText && !isTranslating ? false : true}
                text={translatedText}
                fileName={`BanAIna ${new Date().getTime()}`}
              />
              <MdOutlineContentCopy
                size={24}
                onClick={() => copyClipboard(translatedText)}
                className={` ${
                  translatedText ? "cursor-pointer opacity-100" : "opacity-50"
                } `}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Translator;
