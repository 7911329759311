import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { GoChevronDown } from "react-icons/go";
import { useTranslation } from "react-i18next";
import menuConfig from "../../config/menuConfig";

const Sidebar = () => {
  const { t } = useTranslation();
  const [openSubmenus, setOpenSubmenus] = useState({});
  const location = useLocation();

  function toggleSubMenu(menu) {
    setOpenSubmenus((prev) => ({
      [menu]: !prev[menu],
    }));
  }
  useEffect(() => {
    const initialOpenSubmenus = {};
    menuConfig.forEach((menu) => {
      if (menu.submenu) {
        const isActiveSubmenu = menu.submenu.some((submenuItem) =>
          location.pathname.startsWith(`/dashboard/${submenuItem.route}`)
        );
        if (isActiveSubmenu) {
          initialOpenSubmenus[menu.title] = true;
        }
      }
    });
    setOpenSubmenus(initialOpenSubmenus);
  }, []);

  return (
    <div className="flex flex-col h-[calc(100%-64px)] w-16 hover:w-60 md:w-60 bg-white text-gray-800 shadow-lg transition-all duration-300 border-r border-gray-200 fixed top-16 left-0 z-20">
      <div
        className="flex flex-col h-full overflow-y-auto px-4"
        style={{ scrollbarGutter: "stable" }}
      >
        <ul className="flex flex-col py-4 space-y-2">
          {menuConfig.map((menu, index) => (
            <li key={index}>
              {!menu.submenu ? (
                <NavLink
                  end
                  to={menu.route}
                  className={({ isActive }) =>
                    `flex items-center gap-2 p-2 text-base font-normal text-gray-500 rounded hover:bg-gray-100 transition-colors duration-200 ${
                      isActive ? "bg-gray-200" : ""
                    }`
                  }
                >
                  <menu.icon className="w-6 h-6 text-gray-500" />
                  <span className=" flex-1 whitespace-nowrap">
                    {t(menu.title)}
                  </span>
                </NavLink>
              ) : (
                <>
                  <button
                    onClick={() => toggleSubMenu(menu.title)}
                    className="relative flex items-center gap-2 p-2 w-full text-base font-normal text-gray-500 rounded hover:bg-gray-100 transition-colors duration-200"
                  >
                    <menu.icon className="w-6 h-6 text-gray-500" />
                    <span className="whitespace-nowrap">{t(menu.title)}</span>
                    <GoChevronDown
                      className={`w-6 h-6 text-gray-500 transition-transform duration-300 absolute right-0 ${
                        openSubmenus[menu.title] ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  <div
                    className={`relative mt-1 ${
                      openSubmenus[menu.title] ? "max-h-[500px]" : "max-h-0"
                    } overflow-hidden transition-max-height duration-500 `}
                  >
                    <ul className="space-y-1 pl-6">
                      {menu.submenu.map((submenuItem, subIndex) => (
                        <li
                          key={subIndex}
                          className={`max-w-44 relative before:content-[''] before:absolute before:left-[-7px] before:-top-5 
                          before:bottom-[50%] before:w-[12px] before:h-full before:border-l before:border-b before:border-gray-300 before:rounded-bl-full  ${
                            subIndex !== menu.submenu.length - 1
                              ? "after:content-[''] after:absolute after:left-[-7px] after:top-0 after:bottom-[-12px] after:w-px after:bg-gray-300"
                              : ""
                          }`}
                        >
                          <NavLink
                            to={submenuItem.route}
                            className={({ isActive }) =>
                              ` flex gap-2 items-center p-2 text-base font-normal text-gray-500 rounded hover:bg-gray-200 transition-colors duration-200 ml-1 ${
                                isActive ? "bg-gray-200" : ""
                              }`
                            }
                          >
                            <submenuItem.icon className="w-4 h-4 text-gray-500" />
                            <span className="">{t(submenuItem.title)}</span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
