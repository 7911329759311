import { create } from "zustand";
import { persist } from "zustand/middleware";
import image1 from "../assets/image_1.png";
import image2 from "../assets/image_2.png";
import image3 from "../assets/image_3.png";
import image4 from "../assets/image_4.png";
import image5 from "../assets/image_5.png";

const initialState = [
  {
    url: image1,
    prompt: "Default prompt",
    magicPrompt: "Default prompt magic prompt",
    date: 1725465979,
  },
  {
    url: image2,
    prompt: "Default prompt",
    magicPrompt: "Default prompt magic prompt",
    date: 1725465979,
  },
  {
    url: image3,
    prompt: "Default prompt",
    magicPrompt: "Default prompt magic prompt",
    date: 1725465979,
  },
  {
    url: image4,
    prompt: "Default prompt",
    magicPrompt: "Default prompt magic prompt",
    date: 1725465979,
  },
  {
    url: image5,
    prompt: "Default prompt",
    magicPrompt: "Default prompt magic prompt",
    date: 1725465979,
  },
  {
    url: image1,
    prompt: "Default prompt",
    magicPrompt: "Default prompt magic prompt",
    date: 1725465979,
  },
  {
    url: image2,
    prompt: "Default prompt",
    magicPrompt: "Default prompt magic prompt",
    date: 1725465979,
  },
  {
    url: image3,
    prompt: "Default prompt",
    magicPrompt: "Default prompt magic prompt",
    date: 1725465979,
  },
  {
    url: image4,
    prompt: "Default prompt",
    magicPrompt: "Default prompt magic prompt",
    date: 1725465979,
  },
];
const useImageStore = create(
  persist(
    (set) => ({
      images: initialState,
      addImage: (newImage) =>
        set((state) => ({ images: [newImage, ...state.images] })),
      setImages: (newImages) => set({ images: newImages }),
      resetStateImages: () => set({ images: initialState }),
    }),
    {
      name: "images-storage",
    }
  )
);

export default useImageStore;
